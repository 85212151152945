import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { KBAConstants } from '../../../../../common/constants/Constants';
import { container } from '../../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../../core/startup/inversify/types';
import { IDialogBox } from '../../../../../core/utilities/ui/DialogBox';
import { KBATransactionResultType, SignatureType } from '../../../../../model/Common/Enums';
import { IKBAAnswer, IKBAAnswerChoice, IKBAAnswers, IKBATransactionResponse } from '../../../../../model/KBA/KBA';
import { Shimmer } from '../../../../common/Shimmer/Shimmer';
import { Body } from '../../../../common/StepLayout/Body';
import { StepLayout } from '../../../../common/StepLayout/StepLayout';
import { ISnackbar } from '../../../../../core/utilities/ui/Snackbar';

export type KBAQuestionsProps = {
	clientId: string;
	response: IKBATransactionResponse;
	loadKBAQuestions(failureCallback: () => void): void;
	validateKBAAnswers(
		data: IKBAAnswers,
		successCallback: (isNextQuestionSet?: boolean) => void,
		failureCallback: () => void,
	): void;
	clearParentState: () => void;
	refreshKBAStatus(successCallback?: () => void): void;
	onCompletion?(): void;
	updateDocumentSignatureSettingModel(signatureType: number, callback: any): void;
	onKBAFailure(): void;
	refreshTaxDocument: (clientGuid: string) => void;
};

export interface KBAQuestionsState {
	currentQuestionIndex: number;
	remainingQuestions: number;
	isChoiceSelected: boolean;
	errorText: string;
	kbaAnswer: IKBAAnswers;
	answer: IKBAAnswer;
	choice: IKBAAnswerChoice;
	retryCount: number;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

export class KBAQuestions extends React.Component<KBAQuestionsProps, KBAQuestionsState> {
	constructor(props: KBAQuestionsProps) {
		super(props);
		this.props.clearParentState();
		this.clearComponentState(true);
	}

	componentDidMount() {
		this.props.loadKBAQuestions(this.onKBAIdentityVerificationFailure);
	}

	static getDerivedStateFromProps(props: KBAQuestionsProps, state: KBAQuestionsState) {
		if (!props.response.questions || !props.response.questions.question) {
			return null;
		} else {
			return {
				remainingQuestions: state.remainingQuestions
					? state.remainingQuestions
					: props.response.questions.question.length,
			} as KBAQuestionsState;
		}
	}

	private clearComponentState = (isConstructor: boolean) => {
		if (isConstructor) {
			this.state = {
				currentQuestionIndex: 0,
				remainingQuestions: 0,
				isChoiceSelected: false,
				errorText: '',
				kbaAnswer: {
					transactionId: 0,
					tryCount: 0,
					questionSetId: 0,
					answers: [],
				},
				answer: {
					questionId: 0,
					choice: {
						choiceId: 0,
						choiceText: '',
					},
				},
				choice: {
					choiceId: 0,
					choiceText: '',
				},
				retryCount: 0,
			};
		} else {
			this.setState({
				currentQuestionIndex: 0,
				remainingQuestions: 0,
				isChoiceSelected: false,
				errorText: '',
				kbaAnswer: {
					transactionId: 0,
					tryCount: 0,
					questionSetId: 0,
					answers: [],
				},
				answer: {
					questionId: 0,
					choice: {
						choiceId: 0,
						choiceText: '',
					},
				},
				choice: {
					choiceId: 0,
					choiceText: '',
				},
			});
		}
	};

	private handleRadioChange = (e: any) => {
		this.setState({
			isChoiceSelected: true,
			errorText: '',
			choice: { choiceId: e.target.value, choiceText: e.target.label },
		});
	};

	private handleQuestionChange = () => {
		this.validateCurrentQuestion();
	};

	private validateCurrentQuestion = () => {
		if (!this.state.isChoiceSelected) {
			this.setState({ errorText: 'Please select a valid choice' });
		} else {
			const currIndex: number = this.state.currentQuestionIndex;
			const count = this.props.response.questions.question.length - 1;
			const remQuestions = this.state.remainingQuestions;
			const allChoices: IKBAAnswerChoice[] =
				this.props.response.questions.question[this.state.currentQuestionIndex].choices;
			const selectedChoice: IKBAAnswerChoice | undefined = allChoices.find((el) => {
				if (el.choiceId == this.state.choice.choiceId) {
					return el;
				}
			});
			const userChoice: IKBAAnswerChoice = selectedChoice ? selectedChoice : allChoices[0];
			const answer: IKBAAnswer = {
				questionId: this.props.response.questions.question[this.state.currentQuestionIndex].questionId,
				choice: userChoice,
			};

			let kbaAnswer = this.state.kbaAnswer;

			kbaAnswer.answers.push(answer);
			kbaAnswer.transactionId = this.props.response.transactionId;
			kbaAnswer.questionSetId = this.props.response.questions.questionSetId;

			if (currIndex < count) {
				const newIndex = currIndex + 1;
				this.setState({
					currentQuestionIndex: newIndex,
					remainingQuestions: remQuestions - 1,
					isChoiceSelected: false,
					choice: {
						choiceId: 0,
						choiceText: '',
					},
					kbaAnswer: {
						transactionId: this.props.response.transactionId,
						questionSetId: this.props.response.questions.questionSetId,
						tryCount: this.state.retryCount,
						answers: kbaAnswer.answers,
					},
				});
			}
		}
	};

	private handleSubmit = (e: any) => {
		this.validateCurrentQuestion();
		if (this.state.isChoiceSelected) {
			this.props.validateKBAAnswers(this.state.kbaAnswer, this.onSubmitSuccessful, this.onSubmitFailed);
		}
	};

	private onSubmitSuccessful = (isNextQuestionSet?: boolean) => {
		this.clearComponentState(false);
		if (!isNextQuestionSet) {
			this.props.refreshKBAStatus(() => {
				this.props.onCompletion && this.props.onCompletion();
			});
		}
	};

	confirmRedirectToManualSign = () => {
		dialogBox.alert(
			KBAConstants.KBARetryExceedMessageTitle,
			KBAConstants.KBARetryExceedMessage,
			'<i class="fa fa-exclamation-triangle orange" aria-hidden="true"></i>',
			() => {
				this.props.updateDocumentSignatureSettingModel(SignatureType.ManualSign, () => {
					this.onKBARetryExceed();
				});
			},
		);
	};
	onKBAIdentityVerificationFailure = () => {
		dialogBox.alert('', KBAConstants.KBAIdentificationFailure, '', () => {
			this.props.updateDocumentSignatureSettingModel(SignatureType.ManualSign, () => {
				this.onKBARetryExceed();
			});
		});
	};
	private onKBARetryExceed = () => {
		this.props.refreshTaxDocument(this.props.clientId);
		this.props.onKBAFailure();
	};

	private onSubmitFailed = () => {
		this.setState({ retryCount: this.state.retryCount + 1 });
		if (this.state.retryCount >= 3) {
			this.confirmRedirectToManualSign();
		} else {
			snackbar.show(KBAConstants.KBAFailureMessage);
			this.props.loadKBAQuestions(this.onKBAIdentityVerificationFailure);
			this.clearComponentState(false);
		}
	};

	private isErrorState = () => {
		return (
			this.props.response.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.error] ||
			this.props.response.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.failed]
		);
	};

	public render() {
		return (
			<StepLayout>
				<Body
					title={'Security Questions'}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					height={95}
					automationTestId={'11BB28B5-42F3-4571-B567-20700B1740B6'}>
					{this.state.errorText ? <div className='error-container'>{this.state.errorText}</div> : null}
					<div
						className='kba-questions content-paragraph'
						data-test-auto='816B863F-7F5E-4A93-8321-2F6EA081A301'>
						{this.props.response.questions?.question?.length > 0 ? (
							this.props.response.questions.question[this.state.currentQuestionIndex]?.question
						) : this.isErrorState() ? null : (
							<Shimmer height={40} />
						)}
					</div>
					<br />
					<div
						className='content-paragraph kba-questions-list'
						data-test-auto='22A75A11-9F78-4C90-9E2A-31271A2A8518'>
						<Form.Group as={Row}>
							<Col sm={10}>
								{this.props.response.questions?.question?.length > 0 ? (
									this.props.response.questions?.question[this.state.currentQuestionIndex]?.choices?.map(
										(choice, index) => {
											return (
												<Form.Check
													name='kbaChoiceGroup'
													type={'radio'}
													key={choice.choiceId}
													id={'kba-radio-' + (index + 1)}
													label={choice.choiceText}
													value={choice.choiceId}
													checked={choice.choiceId == this.state.choice.choiceId ? true : false}
													onChange={(e: any) => this.handleRadioChange(e)}
												/>
											);
										},
									)
								) : this.isErrorState() ? null : (
									<Shimmer
										lineCount={5}
										height={20}
									/>
								)}
							</Col>
						</Form.Group>
					</div>

					<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 download-footer steps-footer'>
						{this.state.remainingQuestions > 1 ? (
							<button
								disabled={!this.state.isChoiceSelected}
								onClick={this.handleQuestionChange}
								data-test-auto='73A4B994-8D10-40E8-A98B-C41FCFE78E44'
								className='btn btn-primary flrt'>
								Continue
							</button>
						) : (
							<button
								onClick={this.handleSubmit}
								disabled={!this.state.isChoiceSelected}
								data-test-auto='A2C1D707-9F35-44AA-9E07-D9E37C179DCD'
								className='btn btn-primary flrt'
								hidden={this.state.remainingQuestions < 1}>
								Continue
							</button>
						)}
					</div>
				</Body>
			</StepLayout>
		);
	}
}
