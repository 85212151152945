import * as React from 'react';
import HeaderComp from '../../HeaderComp';
import Skeleton from 'react-loading-skeleton';
import { Toaster } from '../../common/Notification/MessageBox';

export class OTPRequest extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			otpVal: '',
			continueState: false,
		};

		this.handleOTPChange = this.handleOTPChange.bind(this);
		this.requestCode = this.requestCode.bind(this);
		this.verifyOTP = this.verifyOTP.bind(this);
		this.handleOTPVerification = this.handleOTPVerification.bind(this);
	}

	componentDidMount() {
		this.props.requestHeaderDetails(this.props.match.params.id);
		this.props.pageValidity(this.props.match.params.id, this.handleError);
		this.props.requestCoverClientData(this.props.match.params.id, this.handleSuccess, this.handleError);
	}

	handleSuccess = () => {
		this.props.history.push(`/CoverPage/${this.props.match.params.id}`);
	};

	handleError = (errodCode) => {
		this.props.history.push('/Error/'+errodCode);
	};

	handleOTPChange(e: any) {
		if (e.target.value.length <= 6 && (!isNaN(e.target.value) || e.target.value.length === 0)) {
			if (e.target.value.length === 6) {
				this.setState({ otpVal: e.target.value, continueState: true });
			} else {
				this.setState({ otpVal: e.target.value, continueState: false });
			}
		}
	}

	handleOTPVerification(guid: string) {
		this.props.history.push(`/taxpayer/${guid}`);
	}

	requestCode() {
		this.props.generateMobileOTP(this.props.match.params.id);
	}

	verifyOTP() {
		if (this.state.continueState) {
			this.props.verifyOTP(this.state.otpVal, this.props.match.params.id, this.handleOTPVerification, this.handleError);
		}
	}

	render() {
		let phoneNo: string = this.props.OTPPageState.mobileNo;
		let lastNum: string = '';

		if (phoneNo.length !== 0) {
			lastNum = '{xxx}xxx-' + phoneNo.substr(6);
		}

		let continueClass = 'btn-primary continue disabled float-right';
		if (this.state.continueState) {
			continueClass = 'btn-primary continue float-right';
		}

		let headerData: any = this.props.headerInfoState;

		let headerBackColor = 'green';
		let headerTextColor = 'white';

		if (this.props.headerInfoState.brandingSettings) {
			headerBackColor = this.props.headerInfoState.brandingSettings.coverPageSetting.bgColorCode;
			headerTextColor = this.props.headerInfoState.brandingSettings.coverPageSetting.foreColorCode;
		}

		return (
			<>
				<Toaster />
				{this.props.OTPPageState.loading === true ? (
					<Skeleton
						circle={false}
						height={'100vh'}
						width={'100vw'}
					/>
				) : (
					<>
						<HeaderComp
							companyLogo={headerData.companyWhiteLogoPath}
							companyName={headerData.companyName}
							headerTextColor={headerTextColor}
							headerBackColor={headerBackColor}
						/>
						<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page'>
							<div
								className='col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page-container'
								style={{ height: '85%', minHeight: '450px' }}>
								<br />
								<div></div>
								<div
									className='toast'
									role='alert'
									aria-live='assertive'
									aria-atomic='true'
									data-delay='5000'
									style={{ maxWidth: 'inherit !important' }}></div>

								<span
									id='spanError'
									className='error-container'
									style={{ color: 'red' }}></span>
								<h5 data-test-auto='594BF58A-8747-40AB-B191-D6230C62AFE7'>Authentication</h5>
								<br />
								<h2
									data-test-auto='72B14A99-8268-48CC-9CFF-D9855EFE973B'
									style={{ fontSize: '14px', fontWeight: 'bold' }}>
									Mobile Access Code Required
								</h2>

								<div>
									<div
										data-test-auto='5877E1VF4W543DKW4MY8AYKW2H'
										className='margin-top-10'
										style={{ fontSize: '14px' }}>
										<p>
											{' '}
											Please select “Request Access Code” and we will send you a one-time expiring access code to your
											mobile device number:{lastNum}
										</p>
									</div>
									<div
										className='margin-top-10'
										data-test-auto='2DDA88CF-DDF5-44E1-90E9-7ABF94182032'
										style={{ fontSize: '14px' }}>
										<p> If the mobile device number is incorrect please contact your Tax Professional.</p>
									</div>

									<div className='margin-top-50'>
										<button
											data-test-auto='KX5TPC2L7MCMN6K4P58LR5AR9D'
											type='button'
											className=' btn-primary-accesscode continue'
											id='btnRequestAccessCode'
											style={{ backgroundColor: 'white' }}
											onClick={this.requestCode}>
											Request Access Code
										</button>
									</div>

									<div className='otp-page-container-input-div margin-top-50'>
										<span
											data-test-auto='F85BF82A-DF06-4D24-A1F6-3862A8312CB0'
											style={{ fontSize: '14px' }}>
											Enter access code here:
										</span>{' '}
										&nbsp;
										<br />
										<input
											data-test-auto='WL2PVLYGV7Y4V6M2KCECL0WT9D'
											id='otp'
											name='otp'
											type='text'
											value={this.state.otpVal}
											onChange={this.handleOTPChange}
											style={{ margin: '0 36px 0 4px' }}
											autoComplete='false'
										/>
										<br />
										<span
											className='access-code-expiry-info'
											data-test-auto='81F85D4C-7FD0-4841-9CC6-74AC230FBE27'
											style={{ fontSize: '14px' }}>
											(This code will expire in 20 minutes)
										</span>
									</div>
								</div>
							</div>
							<div
								className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-top-10'
								style={{ textAlign: 'center', marginTop: '15px', display: 'flex', justifyContent: 'center' }}>
								<button
									data-test-auto='XMCH754QXT44G3CPUY5G3700W8'
									type='submit'
									id='btnContinue'
									className={continueClass}
									style={{ height: '40px', width: '230px', fontSize: '24px' }}
									onClick={this.verifyOTP}>
									Continue
								</button>
							</div>
						</div>
					</>
				)}
			</>
		);
	}
}

export default OTPRequest;
