import * as React from 'react';
import TinyMCE from './Script/react-tinymce';
import '../../assets/plugins/tinymce.min.js';
var htmlencode = require('htmlencode');

interface TinyMCEComponentProps {
	messageBody: string;
	changeStateTinymceBody: (event: any) => void;
	readOnly: number;
}

export class TinyMCEComponent extends React.Component<TinyMCEComponentProps, {}> {
	constructor(props: TinyMCEComponentProps) {
		super(props);
	}

	public render() {
		return (
			<div>
				<TinyMCE
					content={htmlencode.htmlDecode(this.props.messageBody)}
					config={{
						plugins: 'textcolor autoresize',
						menu: {
							edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall' },
							format: { title: 'Format', items: 'bold italic underline | blockformats align ' },
						},
						toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright | forecolor backcolor',
						readonly: this.props.readOnly,
						block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6',
					}}
					onChange={this.props.changeStateTinymceBody.bind(this)}
				/>
			</div>
		);
	}
}
