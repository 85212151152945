import { ControlBaseProps, ControlData } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import * as Enumerable from 'linq';
import * as React from 'react';
import { FileList } from './FileList';
import { Viewer } from './Viewer';
import { ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { PageProperties } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { IHeaderInfoModel } from '../../../../../model/IHeaderInfoModel';
import { IDocument, Document } from '../../../../../model/Esign/Document';
import { IControl } from '../../../../../model/Esign/IControls';
import { PdfViewManager } from './EsignBase';

export interface PdfViewProps {
	documents: IDocument[];
	onSigningCompleted(): void;
	disableNextButton: (value: boolean) => void;
	onViewModeChange?(viewerMode: ViewerMode): void;
	headerInfo: IHeaderInfoModel;
	doNotAllowToEditSignatureName?: boolean;
}

export interface PdfViewState {
	documents: IDocument[];
	document: IDocument;
	startNavigationOnDocumentLoad: boolean;
	progress: number;
	selectedPageNo: number;
}

export class PdfView extends React.Component<PdfViewProps, PdfViewState> {
	private _viewer: any;
	private _requiredControlsDataFilled: boolean = false;

	constructor(props: any) {
		super(props);

		this.state = {
			documents: [],
			startNavigationOnDocumentLoad: false,
			document: Document.createNullObject(),
			progress: 0,
			selectedPageNo: 1,
		};
	}

	static getDerivedStateFromProps(nextProps: PdfViewProps, nextState: PdfViewState) {
		if (nextProps.documents.length > 0 && nextState.document) {
			let document: IDocument = Document.createNullObject();

			if (nextState.document.id > 0) {
				document = nextState.document;
			} else {
				document = nextProps.documents.filter(
					(x) => x.disabled == false && x.pages.some((y) => y.controls.length > 0),
				)[0];

				if (!document) {
					document = nextProps.documents.filter((x) => x.disabled == false)[0];
				}
			}

			return {
				documents: nextProps.documents,
				document: document,
			};
		} else return { documents: nextProps.documents, document: Document.createNullObject() };
	}

	componentDidMount() {}

	shouldComponentUpdate(nextProps: PdfViewProps, nextState: PdfViewState) {
		if (
			(nextProps.documents.length > 0 && this.props.documents.length !== nextProps.documents.length) ||
			this.props.headerInfo.clientName !== nextProps.headerInfo.clientName ||
			(this.state.document.id > 0 && this.state.document.id !== nextState.document.id) ||
			this.state.progress !== nextState.progress ||
			this.state.selectedPageNo != nextState.selectedPageNo
		) {
			return true;
		}

		return false;
	}

	handleFileClick = (document: IDocument, pageNo: number) => {
		this.setState({ document: document, startNavigationOnDocumentLoad: true, selectedPageNo: pageNo }, () => {
			const _self = this;
			setTimeout(
				function () {
					_self._viewer && _self._viewer._viewPanel.gotoPage(pageNo);
				}.bind(this),
				1000,
			);
		});
	};

	handleNavigationFinish = () => {
		this.setDocument();
	};

	public getDocumentsData = (): IDocument[] => {
		return this.state.documents;
	};

	public showValidationMessage = (document: IDocument, page: number, control: IControl) => {
		this.setState({ document: document }, () => {
			this._viewer.showValidationMessage(page, control);
		});
	};

	private setDocument = () => {
		if (this.state.document.id > 0) {
			const documents = this.state.documents.filter(
				(x) => x.disabled == false && x.pages.some((y) => y.controls.length > 0),
			);

			const nextDocumentIndex = documents.indexOf(this.state.document) + 1;

			const nextDocument: IDocument | undefined = Enumerable.from(documents).elementAtOrDefault(nextDocumentIndex);

			if (nextDocument == undefined) {
				return;
			}

			if (documents.length == nextDocumentIndex + 1) {
				this.setLastDocument(nextDocument);
			} else {
				this.setNextDocument(nextDocument);
			}
		} else {
			this.setFirstDocument();
		}
	};

	private setFirstDocument = () => {
		this.setState({ document: this.state.documents[0], selectedPageNo: 1 });
	};

	private setNextDocument = (nextDocument: IDocument) => {
		this.setState({
			document: nextDocument,
			startNavigationOnDocumentLoad: true,
			selectedPageNo: 1,
		});
	};

	private setLastDocument = (nextDocument: IDocument) => {
		this.setState({ document: nextDocument, startNavigationOnDocumentLoad: true, selectedPageNo: 1 });
	};

	private isLastDocument = (document: IDocument) => {
		const documents = this.state.documents.filter(
			(x) => x.disabled == false && x.pages.some((y) => y.controls.length > 0),
		);

		// set next document using index
		const nextDocumentIndex = documents.indexOf(document) + 1;

		const nextDocument: IDocument | undefined = Enumerable.from(documents).elementAtOrDefault(nextDocumentIndex);

		if (nextDocument == undefined) {
			return true;
		}

		return false;
	};

	private updateControlData = (controlData: ControlData, controlProps: ControlBaseProps) => {
		const { documents } = this.state;
		const completedPercentage = PdfViewManager.updateControlData(documents, controlData, controlProps);

		completedPercentage > 0 &&
			this.setState({ progress: completedPercentage }, () => {
				if (completedPercentage >= 100) {
					this._requiredControlsDataFilled = true;
					this.props.onSigningCompleted();
				} else if (this._requiredControlsDataFilled) {
					this.props.disableNextButton(true);
				}
			});
	};

	private onPageChanging = (pageProperties: PageProperties) => {
		this.setState({ selectedPageNo: pageProperties.page > 0 ? pageProperties.page : 1 });
	};

	public render() {
		const { documents, document } = this.state;

		const finishTarget: string = this.isLastDocument(document) ? 'awesome-multi-steps-btn-next' : '';

		return (
			<div className='pdf-preview-container'>
				<Viewer
					ref={(ref) => (this._viewer = ref)}
					pages={document.pages}
					onViewModeChange={this.props.onViewModeChange}
					url={document.url}
					progress={this.state.progress}
					fileList={
						<FileList
							selectedDocumentId={document ? document.id : 0}
							documents={documents}
							onFileClick={this.handleFileClick}
							selectedPageNo={this.state.selectedPageNo}
						/>
					}
					startNavigationOnDocumentLoad={this.state.startNavigationOnDocumentLoad}
					finishTarget={finishTarget}
					onNavigationFinish={this.handleNavigationFinish}
					onAddControlData={this.updateControlData}
					headerInfo={this.props.headerInfo}
					onPageChanging={this.onPageChanging}
					doNotAllowToEditSignatureName={this.props.doNotAllowToEditSignatureName}
				/>
			</div>
		);
	}
}
