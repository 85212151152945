import * as React from 'react';
import HeaderComp from '../HeaderComp';
import Skeleton from 'react-loading-skeleton';
import { TaxpayerAuthenticationType1 } from './SSNPageAction';
import { Toaster } from '../common/Notification/MessageBox';

export class SSNPage extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			SSNStr: '',
			continueState: false,
		};
		this.verifySSN = this.verifySSN.bind(this);
		this.handleSSNChange = this.handleSSNChange.bind(this);
		this.navigateToOTP = this.navigateToOTP.bind(this);
	}

	componentDidMount() {
		this.props.requestHeaderDetails(this.props.match.params.id);
		this.props.requestSSNData(this.props.match.params.id, this.handleError);
	}

	componentDidUpdate() {
		console.log('got data for SSN', this.props.SSNPageState);
	}

	handleError = (errodCode) => {
		this.props.history.push('/Error/'+errodCode);
	};

	navigateToOTP(target: string) {
		let clientId: string = this.props.match.params.id;
		if (target === 'OTP') {
			this.props.history.push(`/Authentication/OTP/${clientId}`);
		} else {
			this.props.history.push(`/Authentication/MobileOTP/${clientId}`);
		}
	}

	handleSSNChange(e: any) {
		if (e.target.value.length <= 4 && (!isNaN(e.target.value) || e.target.value.length === 0)) {
			if (e.target.value.length === 4) {
				this.setState({ SSNStr: e.target.value, continueState: true });
			} else {
				this.setState({ SSNStr: e.target.value, continueState: false });
			}
		}
	}

	verifySSN() {
		let clientId: string = this.props.match.params.id;
		if (this.state.continueState) {
			this.props.verifySSN(this.state.SSNStr, clientId, this.navigateToOTP, this.handleError);
		}
	}

	render() {
		let message: string = '';
		let hint: string = '';

		if (this.props.SSNPageState.taxpayerAuthentication !== 0) {
			if (this.props.SSNPageState.taxpayerAuthentication === TaxpayerAuthenticationType1.First4ofSSN) {
				message = 'Please enter the first four digits of your Social Security Number:';
				hint = '(We use the first four digits to verify your identity)';
			} else {
				message = 'Please enter the last four digits of your Social Security Number:';
				hint = '(We use the last four digits to verify your identity)';
			}
		}

		let verifyBtnClass = 'btn-primary continue disabled float-right';
		if (this.state.continueState === true) {
			verifyBtnClass = ' btn-primary continue float-right';
		}

		let headerData = this.props.headerInfoState;

		let headerBackColor = 'green';
		let headerTextColor = 'white';

		if (this.props.headerInfoState.brandingSettings) {
			headerBackColor = this.props.headerInfoState.brandingSettings.coverPageSetting.bgColorCode;
			headerTextColor = this.props.headerInfoState.brandingSettings.coverPageSetting.foreColorCode;
		}

		return (
			<>
				<Toaster />

				{this.props.SSNPageState.loading === true ? (
					<Skeleton
						circle={false}
						height={'100vh'}
						width={'100vw'}
					/>
				) : (
					<>
						<HeaderComp
							companyLogo={headerData.companyWhiteLogoPath}
							companyName={headerData.companyName}
							headerTextColor={headerTextColor}
							headerBackColor={headerBackColor}
						/>
						<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 ssn-page'>
							<div
								className='col-lg-12 col-md-12 col-sm-12 col-xs-12 ssn-page-container'
								style={{ height: '85%', minHeight: '450px' }}>
								<br />
								<h5 data-test-auto='7DF189E3-2E6F-47C8-B843-BEF8CE21D6A5'>Authentication</h5>
								<br />
								<strong data-test-auto='QRE5GQ404HLMZFQLVZ1R4JVTQR'>{this.props.SSNPageState.clientName}</strong>
								<div>
									<div className='ssn-page-container-label-div'>
										<span
											data-test-auto='8546U06DYUNML97VJTE8PD8240'
											style={{ display: 'block', marginTop: '12px', fontSize: '16px' }}>
											{message}
										</span>
										<br />
									</div>

									<div
										className='ssn-page-container-input-div margin-top-10'
										style={{ display: 'block' }}>
										<input
											id='SSNNumber'
											type='text'
											value={this.state.SSNStr}
											onChange={this.handleSSNChange}
											style={{ marginLeft: '15px' }}
										/>
										<br />
										<br />

										<span
											data-test-auto='T7U3LD6H19N4921E2ZWL5JDE3M'
											className='font-style-italic'
											style={{ fontSize: '16px', fontStyle: 'italic' }}>
											{hint}{' '}
										</span>
									</div>
								</div>
							</div>
							<div
								className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-top-15'
								style={{
									textAlign: 'center',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									height: '70px',
								}}
								onClick={this.verifySSN}>
								<button
									data-test-auto='U3FJ9K54A32MN9LEDNDGRRULZH'
									type='submit'
									className={verifyBtnClass}
									id='btnAuthenticate'
									style={{ height: '40px', width: '230px', fontSize: '24px' }}>
									Continue
								</button>
							</div>
						</div>
					</>
				)}
			</>
		);
	}
}

export default SSNPage;
