import * as React from 'react';

export enum CustomButtonPlacement {
	Left = 0,
	Center = 1,
	Right = 2,
}

export enum CustomButtonSize {
	SM = 0,
	MD = 1,
	LG = 2,
	XL = 3,
}

interface CustomButtonProps {
	content: any;
	icon: any;
	tooltip?: string;
	onClick?: () => void;
	automationTestId?: string;
	placement?: CustomButtonPlacement;
	size?: CustomButtonSize;
	disabled?: boolean;
	isToolRequired?: boolean;
	toolTipText?: string;
}

export const CustomButton: React.FunctionComponent<CustomButtonProps> = ({
	content,
	icon,
	tooltip,
	onClick,
	placement,
	size,
	automationTestId,
	disabled,
	isToolRequired,
	toolTipText,
}) => {
	let getPlacementClass = () => {
		switch (placement) {
			case CustomButtonPlacement.Left:
				return ' left ';
			case CustomButtonPlacement.Center:
				return ' center ';
			case CustomButtonPlacement.Right:
				return ' right ';
			default:
				return ' ';
		}
	};

	let getSizeClass = () => {
		switch (size) {
			case CustomButtonSize.XL:
				return ' xl ';
			case CustomButtonSize.LG:
				return ' lg ';
			case CustomButtonSize.MD:
				return ' md ';
			case CustomButtonSize.SM:
				return ' sm ';
			default:
				return ' lg ';
		}
	};
	const IconSvg = icon;
	return (
		<div
			className={'custom-button ' + getPlacementClass() + ' ' + getSizeClass() + ' ' + 'custom-button-container'}
			title={tooltip}
			data-test-auto={automationTestId}
			onClick={onClick}
			style={isToolRequired ? { position: 'relative' } : {}}>
			{isToolRequired && toolTipText ? (
				<>
					<i className='fas fa-info-circle updatedCustomButton'></i>
					<span className={'texttooltip'}>{toolTipText}</span>
				</>
			) : (
				''
			)}

			<div className={'custom-button-icon-container'}>
				<IconSvg />
			</div>

			<div className={'custom-button-text-container'}>{content}</div>
		</div>
	);
};
