import * as React from 'react';
import { ClientType } from '../../../../../model/Common/Enums';
import { StepLayout } from '../../../../common/StepLayout/StepLayout';
import { Body } from '../../../../common/StepLayout/Body';
import { Shimmer } from '../../../../common/Shimmer/Shimmer';
import { FormLabel } from 'react-bootstrap';
import { CustomInput } from '../../../../common/TextBox/CustomInput';
import { IIndividualTaxReturn, IMarriedJointTaxReturn, ISignerModel } from '../../../../../model/TaxReturn/TaxReturn';
import TaxpayerHelper from '../../../signflow/Helper/TaxpayerHelper';
import { EditableDropdown } from '../../../../common/Select/EditableDropdown';
import { CoutryCodes } from '../../../../../common/constants/CoutryCodes';
import { PhoneNumberComponent } from '../../../../common/PhoneNumberComponent/PhoneNumberComponent';
import { initialTaxpayer, ITaxpayer } from '../../../../../model/ITaxClient';
import { TYPES } from '../../../../../core/startup/inversify/types';
import { container } from '../../../../../core/startup/inversify/inversify.config';
import { IUtilities } from '../../../../../core/utilities/Utilities';
import { SiginingConstants, ValidationContants } from '../../../../../common/constants/Constants';
import { ISnackbar } from '../../../../../core/utilities/ui/Snackbar';
import { TelemetryLogger } from '../../../../../logger/AppInsights';
import { MessageBox, Toaster } from '../../../../common/Notification/MessageBox';

const logger = TelemetryLogger.getInstance();

export type SpouseDetailsProps = {
	onUpdateMail(id: string, mail: string, type: ClientType, callback: any): void;
	onUpdateMobile(id: string, mobile: string, type: ClientType, countryCode: string, ssn: string, callback: any): void;
	clientId: string;

	individual: IIndividualTaxReturn;
	mutual: IMarriedJointTaxReturn;
	signerData: ISignerModel[];
	isPreviewMode: boolean;
};

export interface SpouseDetailsState {
	email: string;
	mobile: string;
	countryCode: string;
}

const utilities = container.get<IUtilities>(TYPES.IUtilities);
const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

export class SpouseDetails extends React.Component<SpouseDetailsProps, SpouseDetailsState> {
	constructor(props: SpouseDetailsProps) {
		super(props);

		var taxpayer = TaxpayerHelper.getSecondSigner(props.mutual, props.signerData, props.clientId);

		this.state = {
			email: taxpayer.email || '',
			mobile: taxpayer.mobileNumber || '',
			countryCode: taxpayer.countryCode || '',
		};
	}

	componentDidMount() {
		logger.trackTrace(`Spouse Details Page`, {
			PreviewMode: this.props.isPreviewMode,
			ClientId: TaxpayerHelper.getClientId(),
		});
	}

	handleSpouseNameChange = (event: any) => {
		this.setState({ email: event.target.value });
	};

	handleSpouseMobileChange = (value: any) => {
		this.setState({
			mobile: value,
		});
	};

	handleSpouseMobileCountryCodeChange = (value: any) => {
		this.setState({
			countryCode: value,
		});
	};

	public handleSave = (gotoNextStep: () => void) => {
		this.handleUpdateMail(() => {
			this.handleUpdateMobile(gotoNextStep);
		});
	};

	private handleUpdateMail = (updateMailCallback: () => void) => {
		const { onUpdateMail, clientId, mutual, signerData } = this.props;
		const { email } = this.state;

		if (!email || !utilities.isValidEmailAddress(email)) {
			snackbar.show(SiginingConstants.WarningMessage.InvalidEmailAddress);
			return;
		}

		var taxpayer = TaxpayerHelper.getSecondSigner(mutual, signerData, clientId);

		onUpdateMail(
			taxpayer.id.toString(),
			email,
			TaxpayerHelper.getSecondSignerType(this.props.mutual, this.props.signerData, clientId),
			updateMailCallback,
		);
	};

	private handleUpdateMobile = (updateMobileCallback: () => void) => {
		const { onUpdateMobile, clientId, mutual, signerData } = this.props;
		const { mobile, countryCode } = this.state;
		var taxpayer = TaxpayerHelper.getSecondSigner(mutual, signerData, clientId);

		if (countryCode.trim() !== '' || mobile.trim() !== '') {
			if (!this.validateCountryCode(countryCode) || !this.validatePhone(mobile)) {
				return false;
			}
		}

		if (taxpayer.countryCode !== countryCode || taxpayer.mobileNumber !== mobile) {
			onUpdateMobile(
				taxpayer.id.toString(),
				mobile,
				TaxpayerHelper.getSecondSignerType(this.props.mutual, this.props.signerData, clientId),
				countryCode,
				taxpayer.ssn,
				updateMobileCallback,
			);
		} else {
			updateMobileCallback();
		}
	};

	validatePhone = (phoneNoId: any) => {
		var phoneNo = phoneNoId.trim();
		if (phoneNo.trim() === '') {
			MessageBox.Warning(ValidationContants.PhoneNumberWarning);
			return false;
		} else if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
			MessageBox.Warning(ValidationContants.PhoneNumberLengthWarning);
			return false;
		} else return true;
	};

	validateCountryCode = (countryCode: any) => {
		if (countryCode.trim() === '') {
			MessageBox.Warning(ValidationContants.CountryCodeWarning);
			return false;
		} else return true;
	};

	public render() {
		const { signerData, mutual, clientId } = this.props;
		var taxpayer = TaxpayerHelper.getSecondSigner(mutual, signerData, clientId);

		return (
			<StepLayout>
				<Toaster />
				<Body
					containerClassName={'content-wrapper-full'}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					title={"Spouse's Signature Required"}
					bodyClassName={'esign-consent-container'}>
					<div className='esign-spouse'>
						<div className='content-paragraph'>
							Please provide an email address for your spouse. It may be the same as your own if you share an email
							account. They will receive a similar request asking them to Sign their forms electronically.
						</div>

						<br />
						<div className='padding-full-20'>
							<span className='width-50 dib'>
								Spouse's Name <br />
								<div style={{ marginTop: '15px' }}>
									{taxpayer.name ? (
										<FormLabel>
											<b>{taxpayer.name}</b>
										</FormLabel>
									) : (
										<Shimmer height={85} />
									)}
								</div>
							</span>

							<span className='width-50 dib'>
								Spouse's Email Address <br />
								<div style={{ marginTop: '15px' }}>
									{taxpayer.name ? (
										<CustomInput
											automationId={'2C580DDD-1425-4242-A2B2-F371B968ACEF'}
											value={this.state.email || this.state.email}
											onChange={this.handleSpouseNameChange}
										/>
									) : (
										<Shimmer height={85} />
									)}{' '}
								</div>
							</span>

							<br />
							<span className='width-50 dib'>
								Spouse's Mobile Number <br />
								<div className={'spouse-details-phone-number-container'}>
									<EditableDropdown
										id='ddlCountryCode'
										options={CoutryCodes()}
										onChange={this.handleSpouseMobileCountryCodeChange}
										selectedValue={this.state.countryCode}
										clearable={true}
										data-test-auto='271C2A00-2C22-4E8A-9EEF-966E58AAE121'
									/>

									<div>
										{taxpayer.name ? (
											<PhoneNumberComponent
												phoneNumber={this.state.mobile}
												handleChangePhoneNumber={this.handleSpouseMobileChange}
												data-test-auto='8243250E-2983-4267-B864-8EFA449BDAAB'
											/>
										) : (
											<Shimmer height={85} />
										)}{' '}
									</div>
								</div>
							</span>
						</div>
					</div>
				</Body>
			</StepLayout>
		);
	}
}
