import ControlBase from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { Guid } from '../../../../../../core/utilities/Guid';
import { Document, IDocument, IPage, Page } from '../../../../../../model/Esign/Document';
import {
	CompanyControl,
	CustomTextControl,
	DateControl,
	IControl,
	ICustomData,
	InitialControl,
	ISignatureData,
	ITextData,
	SignatureControl,
	TitleControl,
	TextData,
	NameAndTitleControl,
	ControlRole,
	IControlRole,
	ISignerInfo,
	SignerInfo,
	PrintNameControl,
	CheckBoxControl,
	RadioButtonControl,
	IChoosableControlResult,
	ChoosableControlData,
} from '../../../../../../model/Esign/IControls';
import {
	DocumentSignatureDataModel,
	ISignatureControlModel,
	ISignaturePageData,
	SignatureControlModel,
	SignaturePageData,
} from '../../../../../../model/Esign/IDocumentSignatureDataModel';
import { ISignerControlDataModal, SignerControlDataModal } from '../../../../../../model/Esign/ISignerControlDataModal';
import { SignatureControlRole, SignatureControlTypes } from '../../../../../../model/IGroup';

export interface IDocumentAdapter {
	convertToClientModel(documentModel: DocumentSignatureDataModel[]): IDocument[];
	convertToClientModelWithDisable(documentModel: DocumentSignatureDataModel[]): IDocument[];
	convertToServerModel(document: IDocument[]): ISignerControlDataModal;
}

const signatureTopAdjustment: number = 5;
const choosableControlAdjustment: number = 12;

export class DocumentAdapter implements IDocumentAdapter {
	public static create(): DocumentAdapter {
		return new DocumentAdapter();
	}

	public convertToClientModel(documentModel: DocumentSignatureDataModel[]): IDocument[] {
		let _self = this;
		let documents: IDocument[] = [];

		documentModel.forEach(function (document: DocumentSignatureDataModel) {
			let tmpDocument: IDocument = Document.create(
				document.id,
				document.name,
				document.fileLink,
				document.disabled,
				document.documentType,
				[],
				false,
			);

			document.documentControls.forEach(function (pageItem: ISignaturePageData) {
				let tmpControls: IControl[] = [];

				pageItem.signingControls.forEach(function (controlItem: ISignatureControlModel) {
					tmpControls.push(_self.convertToClientControlModel(controlItem));
				});

				tmpDocument.pages.push(Page.create(pageItem.pageNo, pageItem.formName, pageItem.bookmark, tmpControls, false));
			});

			documents.push(tmpDocument);
		});

		return documents;
	}

	public convertToClientModelWithDisable(documentModel: DocumentSignatureDataModel[]): IDocument[] {
		let _self = this;
		let documents: IDocument[] = [];

		documentModel.forEach(function (document: DocumentSignatureDataModel) {
			let tmpDocument: IDocument = Document.create(
				document.id,
				document.name,
				document.fileLink,
				document.disabled,
				document.documentType,
				[],
				false,
			);

			document.documentControls.forEach(function (pageItem: ISignaturePageData) {
				let tmpControls: IControl[] = [];

				pageItem.signingControls.forEach(function (controlItem: ISignatureControlModel) {
					tmpControls.push(_self.convertToClientControlModelWithDisable(controlItem));
				});

				tmpDocument.pages.push(Page.create(pageItem.pageNo, pageItem.formName, pageItem.bookmark, tmpControls, false));
			});

			documents.push(tmpDocument);
		});

		return documents;
	}

	public convertToServerModel(documents: IDocument[]): ISignerControlDataModal {
		let _self = this;
		let signerControlDataModal: ISignerControlDataModal = SignerControlDataModal.create('', '', [], '');

		let documentsModel: DocumentSignatureDataModel[] = [];

		documents.forEach(function (document: IDocument) {
			let tmpDocument: DocumentSignatureDataModel = DocumentSignatureDataModel.create(
				document.id,
				document.name,
				document.url,
				document.disabled,
				document.documentType,
				[],
			);

			document.pages.forEach(function (page: IPage) {
				let tmpControls: ISignatureControlModel[] = [];

				page.controls.forEach(function (control: IControl) {
					if (control instanceof CheckBoxControl || control instanceof RadioButtonControl) {
						control.items.forEach(function (item: ChoosableControlData) {
							tmpControls.push(_self.convertToServerControlModel(control, signerControlDataModal, item.id));
						});
					} else {
						tmpControls.push(_self.convertToServerControlModel(control, signerControlDataModal));
					}
				});

				tmpDocument.documentControls.push(
					SignaturePageData.create(page.page, page.formName, page.bookmark, tmpControls),
				);
			});

			documentsModel.push(tmpDocument);
		});

		signerControlDataModal.documentData = documentsModel;

		return signerControlDataModal;
	}

	private convertToServerControlModel(
		control: IControl,
		signerControlDataModal: ISignerControlDataModal,
		dataIdentifier: string = '',
	): ISignatureControlModel {
		let signatureControlModel: ISignatureControlModel;

		const signerInfo: ICustomData | undefined = control.customData.find((x) => x instanceof SignerInfo == true);
		const controlRole: ICustomData | undefined = control.customData.find((x) => x instanceof ControlRole == true);

		const signer: string = (signerInfo as ISignerInfo).email;
		const role: SignatureControlRole = (controlRole as IControlRole).role;

		if (control instanceof SignatureControl) {
			signatureControlModel = SignatureControlModel.create(
				signer,
				1,
				control.top + signatureTopAdjustment, // to fix small change in signature position bug - #42324
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: '', type: SignatureControlTypes.Signature },
			);

			if (control.data != undefined && control.data != null) {
				var signatureData: ISignatureData = control.data as ISignatureData;
				signerControlDataModal.signature = signatureData.signature
					? encodeURIComponent(signatureData.signature)
					: signatureData.signature;
			}
		} else if (control instanceof InitialControl) {
			signatureControlModel = SignatureControlModel.create(
				signer,
				2,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ISignatureData).signature, type: SignatureControlTypes.Initial },
			);

			if (
				signerControlDataModal.signatureInitial == null ||
				signerControlDataModal.signatureInitial == undefined ||
				(signerControlDataModal.signatureInitial != null &&
					signerControlDataModal.signatureInitial != undefined &&
					signerControlDataModal.signatureInitial.trim() == '')
			) {
				if (control.data != undefined && control.data != null) {
					var signatureData: ISignatureData = control.data as ISignatureData;
					signerControlDataModal.signature = signatureData.signature
						? encodeURIComponent(signatureData.signature)
						: signatureData.signature;
				}
			}
		} else if (control instanceof DateControl) {
			signatureControlModel = SignatureControlModel.create(
				signer,
				3,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.Date },
			);
		} else if (control instanceof NameAndTitleControl) {
			signatureControlModel = SignatureControlModel.create(
				signer,
				4,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.NameAndTitle },
			);
		} else if (control instanceof CompanyControl) {
			signatureControlModel = SignatureControlModel.create(
				signer,
				11,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.Company },
			);
		} else if (control instanceof CustomTextControl) {
			signatureControlModel = SignatureControlModel.create(
				signer,
				10,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.Text },
			);
		} else if (control instanceof PrintNameControl) {
			signatureControlModel = SignatureControlModel.create(
				signer,
				12,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.Text },
			);
		} else if (control instanceof TitleControl) {
			signatureControlModel = SignatureControlModel.create(
				signer,
				13,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.Text },
			);
		} else if (control instanceof CheckBoxControl) {
			let checked = false;

			var checkBoxResult = control.data && (control.data as any[]);

			let index = checkBoxResult && checkBoxResult.findIndex((x) => x.id === dataIdentifier);
			if (!index || index === -1) {
				index = control.items.findIndex((x) => x.id === dataIdentifier);
			}

			checked = checkBoxResult ? checkBoxResult[index].checked : false;

			signatureControlModel = SignatureControlModel.create(
				signer,
				SignatureControlTypes.CheckBox,
				control.top - ControlBase.DEPRECATED_getControlLeftProportionPDFJS(control.items[index].top, 1),
				control.left + ControlBase.DEPRECATED_getControlLeftProportionPDFJS(control.items[index].left, 1),
				role,
				control.tooltip,
				control.required,
				{ checked: checked, type: SignatureControlTypes.CheckBox },
			);
		} else if (control instanceof RadioButtonControl) {
			let checked = false;

			var radionButtonResult = control.data && (control.data as IChoosableControlResult);

			let index = control.items.findIndex((x) => x.id === dataIdentifier);

			if (dataIdentifier === radionButtonResult?.id) {
				checked = radionButtonResult?.checked;
			}

			signatureControlModel = SignatureControlModel.create(
				signer,
				SignatureControlTypes.RadioButton,
				control.top - ControlBase.DEPRECATED_getControlLeftProportionPDFJS(control.items[index].top, 1),
				control.left + ControlBase.DEPRECATED_getControlLeftProportionPDFJS(control.items[index].left, 1),
				role,
				control.tooltip,
				control.required,
				{ checked: checked, type: SignatureControlTypes.RadioButton },
			);
		} else {
			throw new Error('Method not implemented.');
		}

		return signatureControlModel;
	}

	private convertToClientControlModel(control: ISignatureControlModel | any): IControl {
		let model: IControl;
		let customData: ICustomData[] = [];

		customData.push(SignerInfo.create(control.signer));
		customData.push(ControlRole.create(control.signatureControlRole));

		if (control.required === undefined || control.required === null) {
			control.required = true;
		}

		switch (control.type) {
			case 1: // signature
				model = SignatureControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					customData,
					undefined,
				);

				break;

			case 2: // Initial
				model = InitialControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
				);

				break;

			case 3: // Date
				model = DateControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					customData,
					TextData.create(this.getCurrentDate()),
				);

				break;

			case 4: // Name and Title
				model = NameAndTitleControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
				);

				break;

			case 11: // company
				model = CompanyControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
				);

				break;

			case 10: // Text
				model = CustomTextControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
				);

				break;

			case 12: // Print Name
				model = PrintNameControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
				);

				break;

			case 13: // Title
				model = TitleControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
				);

				break;

			case 14: // Check Box
				model = CheckBoxControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.width,
					control.height,
					control.tooltip,
					control.required,
					control.items,
					customData,
					undefined,
				);

				break;

			case 15: // Radio Button
				model = RadioButtonControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.width,
					control.height,
					control.tooltip,
					control.required,
					control.items,
					customData,
					undefined,
				);

				break;

			default:
				throw new Error('Method not implemented.');
		}

		return model;
	}

	private convertToClientControlModelWithDisable(control: ISignatureControlModel | any): IControl {
		let model: IControl;
		let customData: ICustomData[] = [];

		customData.push(SignerInfo.create(control.signer));
		customData.push(ControlRole.create(control.role));
		// Making the control optional in disable mode
		//if (control.required === undefined || control.required === null) {
		control.required = false;
		//}

		switch (control.type) {
			case 1: // signature
				model = SignatureControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					customData,
					undefined,
					true,
				);

				break;

			case 2: // Initial
				model = InitialControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
					true,
				);

				break;

			case 3: // Date
				model = DateControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					customData,
					TextData.create(this.getCurrentDate()),
					true,
				);

				break;

			case 4: // Name and Title
				model = NameAndTitleControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
					true,
				);

				break;

			case 11: // company
				model = CompanyControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
					true,
				);

				break;

			case 10: // Text
				model = CustomTextControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
					true,
				);

				break;

			case 12: // Print Name
				model = PrintNameControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
					true,
				);

				break;

			case 13: // Title
				model = TitleControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.tooltip,
					control.required,
					false,
					customData,
					undefined,
					true,
				);

				break;

			case 14: // Check Box
				model = CheckBoxControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.width,
					control.height,
					control.tooltip,
					control.required,
					control.items,
					customData,
					undefined,
					true,
				);

				break;

			case 15: // RadioButton
				model = RadioButtonControl.create(
					Guid.newGuid().toString(),
					control.top,
					control.left,
					control.width,
					control.height,
					control.tooltip,
					control.required,
					control.items,
					customData,
					undefined,
					true,
				);

				break;

			default:
				throw new Error('Method not implemented.');
		}

		return model;
	}

	private getCurrentDate = (): string => {
		var today = new Date();
		var dd = today.getDate().toString();

		var mm = (today.getMonth() + 1).toString();
		var yyyy = today.getFullYear();
		if (Number(dd) < 10) {
			dd = '0' + dd;
		}

		if (Number(mm) < 10) {
			mm = '0' + mm;
		}

		return mm + '/' + dd + '/' + yyyy;
	};
}
