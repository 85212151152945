import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SlideBar } from './DefaultLayout';
import { ApplicationState } from '../../../reducers/index';
import {
	fetchHeaderInfo,
	fetchTaxDocument,
	updateTaxDocument,
	fetchSigners,
	fetchTaxDocumentClientType,
} from '../signflow/Action/TaxpayerSignFlowActions';
import { ClientType } from '../../../model/Common/Enums';
import {  getSessionTimeOutSeconds, updateSpouseMail, updateSpouseMobile } from '../Sign/Esign/Action/EsignActions';
import { ITaxReturn } from '../../../model/TaxReturn/TaxReturn';

function mapStateToProps(state: ApplicationState) {
	return {
		headerInfo: state.headerInfo,
		taxdocument: state.taxReturn,
		signerData: state.signerData,
	};
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchHeaderInfo: (successCallback?: () => void) => dispatch(fetchHeaderInfo(successCallback)),
		fetchTaxDocument: (successCallback?: (taxReturn: ITaxReturn) => void) =>
			dispatch(fetchTaxDocument(successCallback)),
		updateSpouseMail: (id: string, newMail: string, type: ClientType, callback?: () => void) =>
			dispatch(updateSpouseMail(id, newMail, type, callback)),
		updateSpouseMobile: (
			id: string,
			mobile: string,
			type: ClientType,
			countryCode: string,
			ssn: string,
			callback?: () => void,
		) => dispatch(updateSpouseMobile(id, mobile, type, countryCode, ssn, callback)),

		updateTaxDocument: (taxReturn: ITaxReturn, callback?: () => void) =>
			dispatch(updateTaxDocument(taxReturn, callback)),

		getSessionTimeOutSeconds: (clientId: string, callBack?: (sessionTimeout: number) => void) =>
			dispatch(getSessionTimeOutSeconds(clientId, callBack)),

		fetchSigners: () => dispatch(fetchSigners()),
		fetchTaxDocumentClientType: () => dispatch(fetchTaxDocumentClientType()),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(SlideBar));
