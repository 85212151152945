import * as React from 'react';
import { FormLabel } from 'react-bootstrap';
import { DateType } from '../../../../../model/Common/Enums';
import YearMonthDay from '../../../../common/YearMonthDayComponent/YearMonthDayComponent';

export type ConsentProps = {
	dateOfBirth: Date;
	consentChecked: boolean;
	handleConsent(isChecked: boolean): void;
	handleDOBChange(dob: string, type: DateType): void;
};

export interface IConsentState {}

export class Consent extends React.Component<ConsentProps, IConsentState> {
	constructor(props: any) {
		super(props);

		this.state = {};
	}

	componentDidMount() {}

	onConsent = (e: any) => {
		this.props.handleConsent(e.target.checked);
	};

	onDobChange = (val: string, type: DateType) => {
		this.props.handleDOBChange(val, type);
	};

	public render() {
		return (
			<div
				className='esign-consent'
				data-test-auto='4FFB32DC-4674-4682-A047-FF94DAA7E259'>
				<div className='content-paragraph'>
					Before signing, you will need to verify your identity by answering a few short questions about yourself.
				</div>
				<br />
				<div className='content-paragraph'>
					Please verify/enter the information requested and click continue to begin
				</div>
				<br />
				<div className='padding-full-20'>
					{
						<>
							{' '}
							<b>Your Date of Birth:</b> <br />{' '}
							<YearMonthDay
								id='ddlYear'
								controlValue={this.props.dateOfBirth}
								onControlValueChange={this.onDobChange}></YearMonthDay>
						</>
					}
				</div>
				<br />
				<div className='consent-checkbox'>
					<input
						data-test-auto='2BA6893F-0D36-4F45-8185-47B246FAB6E0'
						type='checkbox'
						id='chkConsent'
						className='form-check-inline'
						onChange={this.onConsent}
						checked={this.props.consentChecked}
					/>
					<span className='checkmark'></span>
					<FormLabel> I have reviewed my tax returns and consent to e-Sign </FormLabel>
				</div>
			</div>
		);
	}
}
