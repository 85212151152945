import { TelemetryLogger } from '../../../logger/AppInsights';
import * as React from 'react';
import { IDocumentReviewModel } from '../../../model/DocumentReviewModel/IDocumentReviewModel';
import { Body } from '../../common/StepLayout/Body';
import { StepLayout } from '../../common/StepLayout/StepLayout';
import { Invoice } from './Parts/Invoice';
import TaxpayerHelper from '../signflow/Helper/TaxpayerHelper';

const logger = TelemetryLogger.getInstance();

interface InvoiceInfoProps {
	documentReviewModel: IDocumentReviewModel[];
	isPreviewMode: boolean;
}

export const InvoiceInfo: React.FunctionComponent<InvoiceInfoProps> = (props) => {
	React.useEffect(() => {
		logger.trackTrace(`Invoice Info Page`, {
			PreviewMode: props.isPreviewMode,
			ClientId: TaxpayerHelper.getClientId(),
		});
	}, []);

	return (
		<React.Fragment>
			<StepLayout>
				<Body
					lg={10}
					md={12}
					sm={12}
					xs={12}
					title={'Invoice'}
					automationTestId={'5EB8ED34-27C6-4E27-9CAE-57DFE471A146'}>
					<Invoice documentReviewModel={props.documentReviewModel} />
				</Body>
			</StepLayout>
		</React.Fragment>
	);
};
