import axios from 'axios';
import { PathConstants } from '../../../common/constants/Constants';
import { MessageBox } from '../../../components/common/Notification/MessageBox';
import { IDataService } from './abstraction/IDataService';
import * as Constants from '../../../common/constants/Constants';
import { TelemetryLogger } from '../../../logger/AppInsights';
import { API_BASE_URL } from '../../../common/constants/Constants';
import { history } from './History';

export class AxiosDataService implements IDataService {
	private _baseUri: string = API_BASE_URL;
	private _pageSize: number = 0;
	private _error: boolean = false;
	private logger: TelemetryLogger;
	private clientId: string = '';
	private actionName: string = '';

	constructor(clientId?: string) {
		this.logger = TelemetryLogger.getInstance();
		this.clientId = clientId ? clientId : '';
		const _self = this;

		axios.interceptors.response.use(
			function (response) {
				// windowUtilities.hideLoader();
				_self.actionName = response?.config?.url ?? '';
				if (!response || response?.status === 0) {
					_self.logger.trackWarning(
						`${_self.actionName} failed for client: ${_self.clientId}, ${
							response && `response status: ${response.status}, response data: ${response.data},`
						} response: ${response}`,
						{ ClientId: _self.clientId },
					);
					return new Promise(() => {});
				}
				else if (response.status === 401) {
					(window as any).location.href = '/invalid';
					_self.logger.trackWarning(`${_self?.actionName} failed for client: ${_self?.clientId}, error: UnAuthorized`, { "ClientId": _self?.clientId });
					throw new Error('UnAuthorized');
				}
				return response;
			},
			function (error: any) {
				_self.actionName = error?.response?.config?.url ?? error?.config?.url ?? '';
				if (error?.response) {
					if (error.response.status == '400' || error.response.status == '401' || error.response.status == '403') {
						(window as any).location.href = '/invalid';
						_self.logger.trackWarning(
							`${_self.actionName} failed for client: ${_self.clientId}, response: ${error.response?.data}, errorCode: ${error.response.status}, errorMessage: ${error.message}`,
							{ ClientId: _self.clientId },
						);
					} else if (error.response.status == '307') {
						_self.logger.trackWarning(
							`${_self.actionName} failed for client: ${_self.clientId}, response: ${error.response?.data}, errorCode: ${error.response.status}, errorMessage: ${error.message}`,
							{ ClientId: _self.clientId },
						);
					} else if (error.response.status == '0') {
						_self.logger.trackWarning(
							`${_self.actionName} failed for client: ${_self.clientId}, response: ${error.response?.data}, errorCode: ${error.response.status}, errorMessage: ${error.message}`,
							{ ClientId: _self.clientId },
						);
						MessageBox.Error(Constants.ErrorMessages.NoResponseMessage);
						return new Promise(() => {});
					} else {
						_self.logger.trackError(
							`Error occurred for ${_self.actionName}, client: ${_self.clientId}, error: ${JSON.stringify(
								error.response,
							)}`,
							{ ClientId: _self.clientId },
						);
					}
				} else {
					if (error.message === 'Network Error' || error.code === 'ECONNABORTED') {
						const config = error.config;
						if (!config || !config.retry) {
							MessageBox.Error(error.message);
							_self.logger.trackWarning(
								`Invalid response ${_self.actionName} failed for client ${_self.clientId}, errorMessage: ${error.message}`,
								{ ClientId: _self.clientId },
							);
						} else {
							config.retry -= 1;
							_self.logger.trackWarning(
								`Invalid response ${_self.actionName} failed for client ${_self.clientId}, errorMessage: ${error.message}`,
								{ ClientId: _self.clientId, RetryLeft: config.retry },
							);
							const delayRetryRequest = new Promise<void>((resolve) => {
								setTimeout(() => {
									console.log('Retry the request', config.url);
									resolve();
								}, config.retryDelay || 1000);
							});
							return delayRetryRequest.then(() => axios(config));
						}
					} else {
						_self.logger.trackError(
							`Invalid response ${_self.actionName} failed for client ${_self.clientId}, errorMessage: ${
								error.message
							}, error: ${JSON.stringify(error)}`,
							{ ClientId: _self.clientId },
						);
					}
				}
			},
		);
	}

	getJsonValue(uriPart: string) {
		this._baseUri = this.getApiEndpoint();
		return axios.get(this._baseUri + uriPart);
	}

	postJsonValue(uriPart: string, data?: any, config?: any) {
		this._baseUri = this.getApiEndpoint();
		return axios.post(this._baseUri + uriPart, data, config);
	}

	set(baseUri: string, pageSize: number) {
		this._baseUri = baseUri;
		this._pageSize = pageSize;
	}

	getPaged(page: number, uriPart?: string | undefined): any {
		this._baseUri = this.getApiEndpoint();
		return axios.get(this._baseUri + uriPart);
	}

	get(uriPart?: string | undefined, data?: any): any {
		this._baseUri = this.getApiEndpoint();
		return axios.get(this._baseUri + uriPart, { params: data, method: 'get', withCredentials: true });
	}

	getWithConfig(uriPart?: string | undefined, config?: any): any {
		this._baseUri = this.getApiEndpoint();
		return axios.get(this._baseUri + uriPart, config);
	}

	post(uriPart: string, data?: any, mapResult?: boolean | undefined): any {
		this._baseUri = this.getApiEndpoint();
		return axios.post(this._baseUri + uriPart, data, {
			headers: { 'Content-Type': 'multipart/form-data' },
			method: 'post',
			withCredentials: true,
		});
	}

	put(uriPart: string, data?: any, config?: any, mapResult?: boolean): any {
		this._baseUri = this.getApiEndpoint();
		return axios.put(this._baseUri + uriPart, data, {
			headers: { 'Content-Type': 'multipart/form-data' },
			method: 'put',
			withCredentials: true,
		});
	}

	putJson(data: any, uriPart?: string | undefined): any {
		this._baseUri = this.getApiEndpoint();
		return axios.put(this._baseUri + uriPart, data, {
			headers: { 'Content-Type': 'application/json; charset=utf-8' },
			withCredentials: true,
		});
	}

	postJson(data: any, uriPart?: string | undefined, mapResult?: boolean | undefined, retryOptions?: any): any {
		this._baseUri = this.getApiEndpoint();
		return axios.post(this._baseUri + uriPart, data, {
			headers: { 'Content-Type': 'application/json; charset=utf-8' },
			withCredentials: true,
			retry: retryOptions?.retry,
			retryDelay: retryOptions?.retryDelay,
		} as any);
	}

	delete(id: number, uriPart?: string | undefined): any {
		this._baseUri = this.getApiEndpoint();
		return axios.delete(this._baseUri + uriPart, {
			headers: { 'Content-Type': 'multipart/form-data' },
			method: 'delete',
			withCredentials: true,
		});
	}

	deleteExtended(data: any, uriPart?: any): any {
		this._baseUri = this.getApiEndpoint();
		throw new Error('Method not implemented.');
	}

	private getApiEndpoint(): string {
		return API_BASE_URL;
	}
}

export function initializeAxios(clientId?: string): AxiosDataService {
	return new AxiosDataService(clientId);
}
