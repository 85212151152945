import { SignProcessConstants, PathConstants } from '../../../../common/constants/Constants';
import { container } from '../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../core/startup/inversify/types';
import { IDialogBox } from '../../../../core/utilities/ui/DialogBox';
import { DocumentStatus, Role, SignatureType } from '../../../../model/Common/Enums';
import { TaxpayerSignFlowProps } from '../TaxpayerSignFlow';

//Note: please make sure that enum should same as MARS application
export enum SignProcessSteps {
	Summary = 1,
	PreparerMessage = 2,
	Invoice = 3,
	Review = 4,
	SignMethod = 5,
	EsignConsentEmail = 6,
	KBA = 7,
	Esign = 8,
	SpouseSignature = 9,
	SignComplete = 10,
	ManualSignDownload = 11,
	ManualSign = 12,
	Pay = 13,
	DistributeSelection = 14,
	DistributeManual = 15,
	DistributeElectronic = 16,
	PaperFileMessage = 17,
	PaperFileList = 18,
	PaperFileListSkip = 19,
	PaperFileListComplete = 20,
	KBAInstructions = 21,
	FilingInstructions = 22,
	TaxReturns = 23,
	SignerProcessComplete = 1000,
}

export interface ISignProcessInfo {
	clientId: string;
	lastVisitedStep: SignProcessSteps;
	lastUpdateOn: Date;
	visitedSteps: SignProcessSteps[];
}

export const initialSignProcessInfoModel: ISignProcessInfo = {
	clientId: '',
	lastVisitedStep: SignProcessSteps.Summary,
	lastUpdateOn: new Date(),
	visitedSteps: [],
};

export interface IClientProcessModel {
	role: Role;
	currentstep: ISignProcessInfo;
	permission: string;
	isSigned: boolean;
	isDelegated: boolean;
}

export const initialClientProcessModel: IClientProcessModel = {
	role: Role.None,
	currentstep: initialSignProcessInfoModel,
	permission: '',
	isSigned: false,
	isDelegated: false,
};

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const NO_INDEX = -1;

export class TaxpayerSignFlowHelper {
	private _wizardRef: any;
	private _props?: TaxpayerSignFlowProps;

	constructor(wizardRef: any, taxpayerSignFlowProps?: TaxpayerSignFlowProps) {
		this._wizardRef = wizardRef;
		this._props = taxpayerSignFlowProps;
	}

	public static create(wizardRef: any, taxpayerSignFlowProps?: TaxpayerSignFlowProps) {
		return new TaxpayerSignFlowHelper(wizardRef, taxpayerSignFlowProps);
	}

	public static createNullObject() {
		return new TaxpayerSignFlowHelper(null);
	}

	public signProcessRecoverConfirmation(
		lastVisitedStep: SignProcessSteps,
		clientId?: string,
		requestTutorialInfo?: (clientGuid: string) => any,
	) {
		const _self = this;

		if (lastVisitedStep) {
			dialogBox.confirmCustom(
				SignProcessConstants.ProcessRecoveryConfirmation,
				SignProcessConstants.ProcessRecoveryMessage,
				SignProcessConstants.ProcessRecoveryConfirmationDialogLeftButton,
				SignProcessConstants.ProcessRecoveryConfirmationDialogRightButton,
				function (result: boolean) {
					if (result) {
						/* 10 - retry count until the step is visible based on the server call */
						_self._wizardRef?.goToStepById(lastVisitedStep, 10).then(function (success: boolean) {
							if (success == false) {
								//dialogBox.alert(SignProcessConstants.ProcessRecoveryFailedMessage);
								_self._wizardRef?.goToStep(0);
							}
						});
					} else {
						_self._wizardRef?.goToStep(0);
					}
					if (requestTutorialInfo && clientId) {
						requestTutorialInfo(clientId);
					}
				},
			);
		} else {
			if (requestTutorialInfo && clientId) {
				requestTutorialInfo(clientId);
			}
		}
	}

	isLinkVisited(step: SignProcessSteps): boolean | undefined {
		let index = NO_INDEX;
		index =
			this._props && this._props.signProcessInfo.visitedSteps
				? this._props.signProcessInfo.visitedSteps.indexOf(step)
				: NO_INDEX;
		return index !== null && index !== NO_INDEX;
	}

	public handleRedirect(clientId: string, clientProcessState?: IClientProcessModel) {
		if (
			this.isSigningProcessCompleted() ||
			(clientProcessState && clientProcessState.isSigned) ||
			(clientProcessState && clientProcessState.isDelegated)
		) {
			(window as any).location.href = `${PathConstants.CompletionWizard}${clientId}`;
		} else {
			if (
				this._props?.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType ==
				SignatureType.ManualSign
			) {
				(window as any).location.href = `${PathConstants.ManualSignRestricted}${clientId}`;
			}
		}
	}

	isSigningProcessCompleted(): boolean | undefined {
		const index =
			this._props && this._props.signProcessInfo.visitedSteps
				? this._props.signProcessInfo.visitedSteps.filter(
						(x) =>
							x === SignProcessSteps.Pay ||
							x === SignProcessSteps.DistributeSelection ||
							x === SignProcessSteps.SignComplete ||
							x === SignProcessSteps.SignerProcessComplete,
				  )
				: null;
		return (index !== null && index.length > 0) || this._props?.taxReturn.documentStatus == DocumentStatus.USERSIGNED;
	}
}
