import * as React from 'react';
import { IDocumentReviewModel } from '../../../../model/DocumentReviewModel/IDocumentReviewModel';
import { DocumentGroups } from '../../../../model/IGroup';
import { Viewer } from '../../../common/PdfView/Viewer';
import { Shimmer } from '../../../common/Shimmer/Shimmer';

interface InvoiceProps {
	documentReviewModel: IDocumentReviewModel[];
}

export const Invoice: React.FunctionComponent<InvoiceProps> = (props) => {
	let document: IDocumentReviewModel = props.documentReviewModel.filter(
		(x) => x.documentGroup == DocumentGroups.Invoice,
	)[0];

	return props.documentReviewModel.length == 0 ? (
		<Shimmer height={560} />
	) : (
		<div className='row'>
			<div
				className='col-lg-12 col-md-12 col-sm-12 col-xs-12 review-left-pdf-viewer'
				style={{ height: 'inherit' }}>
				<Viewer url={document && document.fileLink} />
			</div>
		</div>
	);
};
