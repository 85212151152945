import { actionTypes } from '../../../../common/constants/actionTypes';
import { IDataService } from '../../../../core/services/dataAccess/abstraction/IDataService';
import { container } from '../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../core/startup/inversify/types';
import { initialSummaryModel, ISummaryModel } from '../../../../model/SummaryModel/SummaryModel';
import TaxpayerHelper from '../../signflow/Helper/TaxpayerHelper';
import { TelemetryLogger } from '../../../../logger/AppInsights';
import { initializeAxios } from '../../../../core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();

export const fetchSummaryDetails = () => (dispatch: any) => {
	const clientid: string = TaxpayerHelper.getClientId();

	initializeAxios(clientid)
		.get('api/Summary/GetSummaryDetailsAsync/' + clientid)
		.then(function (response: any) {
			dispatch({
				type: actionTypes.FETCH_SUMMARY_DETAIL,
				data: response.data,
			});
		})
		.catch(function (error: any) {
			console.log(error);
			logger.trackError(`taxpayer mobile fetchSummaryDetails failed with error ${error.message}`, {
				ClientId: clientid,
			});
		});
};

export const summaryDetailsReducer = (state: ISummaryModel = initialSummaryModel, action: any) => {
	switch (action.type) {
		case actionTypes.FETCH_SUMMARY_DETAIL:
			return action.data;
	}

	return state;
};
