import * as React from 'react';
import { Consent } from './Consent';
import { ClientType, DateType } from '../../../../../model/Common/Enums';
import { SiginingConstants } from '../../../../../common/constants/Constants';
import { TYPES } from '../../../../../core/startup/inversify/types';
import { container } from '../../../../../core/startup/inversify/inversify.config';
import { IUtilities } from '../../../../../core/utilities/Utilities';
import { StepLayout } from '../../../../common/StepLayout/StepLayout';
import { Body } from '../../../../common/StepLayout/Body';
import { IIndividualTaxReturn, IMarriedJointTaxReturn, ISignerModel } from '../../../../../model/TaxReturn/TaxReturn';
import TaxpayerHelper from '../../../signflow/Helper/TaxpayerHelper';
import { ISnackbar } from '../../../../../core/utilities/ui/Snackbar';
import { TelemetryLogger } from '../../../../../logger/AppInsights';

const logger = TelemetryLogger.getInstance();

export type EsignConsentEmailProps = {
	updateDOBChange?(id: string, dob: string, step: boolean, callback: (step: boolean) => void): void;
	individual: IIndividualTaxReturn;
	mutual: IMarriedJointTaxReturn;
	consent: boolean;
	onConsentAgreed(value: boolean): void;
	prevStep: () => void;
	nextStep: () => void;
	fetchTaxDocument: () => void;
	disableNextButton: (value: boolean) => void;
	clearParentState: () => void;
	signerData: ISignerModel[];
	clientId: string;
	isPreviewMode: boolean;
};

export interface IEsignConsentEmailState {
	dateOfBirth: Date;
	chainedOperationCount: number;
	noOfSteps: number;
	isDOBUpdated: boolean;
}

const utilities = container.get<IUtilities>(TYPES.IUtilities);
const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

export class EsignConsentEmail extends React.Component<EsignConsentEmailProps, IEsignConsentEmailState> {
	constructor(props: EsignConsentEmailProps) {
		super(props);
		this.state = {
			dateOfBirth: this.getCurrentSignerDOB(props),
			chainedOperationCount: 1,
			noOfSteps: 0,
			isDOBUpdated: false,
		};
		this.props.clearParentState();
		this.props.disableNextButton(!this.props.consent);
		this.onPrevious = this.onPrevious.bind(this);
		this.onNext = this.onNext.bind(this);
	}

	componentDidMount() {
		logger.trackTrace(`Esign Consent Email Page`, {
			PreviewMode: this.props.isPreviewMode,
			ClientId: TaxpayerHelper.getClientId(),
		});
	}

	static getDerivedStateFromProps(props: EsignConsentEmailProps, state: IEsignConsentEmailState) {
		if (!props.individual || !props.individual.taxpayer || !props.individual.taxpayer.dob) {
			return null;
		} else {
			if (
				props.individual.taxpayer &&
				props.individual.taxpayer.dob &&
				props.individual.taxpayer.dob !== state.dateOfBirth
			) {
				return {
					dateOfBirth: new Date(state.dateOfBirth),
				};
			}
		}
		return null;
	}

	private handleConsent = (isChecked: boolean) => {
		this.props.onConsentAgreed(isChecked);
	};

	private handleDOBChange = (dob: string, type: DateType) => {
		const birthDate: Date = this.state.dateOfBirth;
		switch (type) {
			case DateType.Date:
				birthDate.setDate(parseInt(dob));
				break;
			case DateType.Month:
				birthDate.setMonth(parseInt(dob) - 1);
				break;
			case DateType.Year:
				birthDate.setFullYear(parseInt(dob));
				break;
		}
		this.setState({ dateOfBirth: birthDate, isDOBUpdated: true });
	};

	private updateEsignState = (step: boolean) => {
		if (this.state.chainedOperationCount === this.state.noOfSteps) {
			this.props.fetchTaxDocument();
			if (step) {
				this.props.nextStep();
			} else {
				this.props.prevStep();
			}
		} else {
			this.setState({ chainedOperationCount: this.state.chainedOperationCount + 1 });
		}
	};

	updateConsentAndEmail(step: boolean) {
		if (!utilities.isValidDOB(this.state.dateOfBirth)) {
			snackbar.show(SiginingConstants.WarningMessage.InvalidDOB);
			return;
		}

		let steps = 1;

		const { clientId } = this.props;

		this.setState({ chainedOperationCount: 1 });

		if (this.state.isDOBUpdated) {
			this.props.updateDOBChange &&
				this.props.updateDOBChange(
					this.getCurrentUserId(this.props).toString(),
					this.state.dateOfBirth.toLocaleDateString(),
					step,
					this.updateEsignState,
				);
		} else {
			this.props.nextStep();
		}
		this.setState({ noOfSteps: steps });
	}
	onPrevious(index: number) {
		this.updateConsentAndEmail(false);
	}

	onNext(index: number) {
		this.updateConsentAndEmail(true);
	}

	private getCurrentSignerDOB = (props: EsignConsentEmailProps) => {
		if (
			!TaxpayerHelper.isMarried(EsignConsentEmail.getTaxReturn(props)) || //if individual return
			props.mutual === null ||
			props.mutual === undefined || //if joint tax return but taxreturn object is null
			((props.mutual.taxpayer === null || props.mutual.taxpayer === undefined) && //if joint tax return but both taxpayer and spouse object are null
				(props.mutual.spouse === null || props.mutual.spouse === undefined))
		) {
			return props.individual.taxpayer && props.individual.taxpayer.dob
				? new Date(props.individual.taxpayer.dob)
				: new Date();
		} else {
			if (props.mutual.taxpayer.clientGuid === this.props.clientId) {
				return props.mutual.taxpayer && props.mutual.taxpayer.dob ? new Date(props.mutual.taxpayer.dob) : new Date();
			} else if (props.mutual.spouse.clientGuid === this.props.clientId) {
				return props.mutual.spouse && props.mutual.spouse.dob ? new Date(props.mutual.spouse.dob) : new Date();
			} else {
				return new Date();
			}
		}
	};

	private getCurrentUserId = (props: EsignConsentEmailProps) => {
		if (TaxpayerHelper.isMarried(EsignConsentEmail.getTaxReturn(props))) {
			if (props.mutual.taxpayer.clientGuid === this.props.clientId) {
				return props.mutual.taxpayer.id;
			} else {
				return props.mutual.spouse.id;
			}
		} else {
			return props.individual.taxpayer.id;
		}
	};

	static getTaxReturn = (props: EsignConsentEmailProps) => {
		return props.mutual ? props.mutual : props.individual;
	};

	public render() {
		return (
			<StepLayout>
				<Body
					containerClassName={'content-wrapper-full'}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					title={'Sign Tax Documents Electronically'}
					bodyClassName={'esign-consent-container'}>
					<Consent
						consentChecked={this.props.consent}
						handleConsent={this.handleConsent}
						handleDOBChange={this.handleDOBChange}
						dateOfBirth={this.state.dateOfBirth}
					/>
				</Body>
			</StepLayout>
		);
	}
}
