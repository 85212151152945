import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { ExpiredIcon } from '../common/Icons/CustomGraphics';
import HeaderComp from '../HeaderComp';
import {  RouteComponentProps } from 'react-router-dom';
import { API_BASE_URL, PathConstants } from 'src/common/constants/Constants';

interface SessionTimeoutPageProps extends RouteComponentProps {
	companyLogo: string;
	companyName: string;
	headerTextColor: string;
	headerBackColor: string;
}
const SessionTimeoutPage: React.FC<SessionTimeoutPageProps> = ({
	companyLogo,
	companyName,
	headerTextColor,
	headerBackColor,
}) => {
	const handleLoginAgain = () => {
		(window as any).location.href = API_BASE_URL + PathConstants.Logout + (window as any).Variables.clientId;
	};
	return (
		<>
			<div>
				<HeaderComp
					companyLogo={companyLogo}
					companyName={companyName}
					headerTextColor={headerTextColor}
					headerBackColor={headerBackColor}
				/>
			</div>
			<div className='session-page-container'>
				<div className='session-page-content'>
					<div className='session-page-icon'>
						<ExpiredIcon />
					</div>

					<div className='sessionexpiration'>
						This session has expired.
						<br />
						To log back in, Kindly use the link
						<br />
						provided in your email.
					</div>

					<div className='session-page-button'>
						<Button
							className='custom-btn'
							onClick={handleLoginAgain}>
							Log In Again
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default SessionTimeoutPage;
