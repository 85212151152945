import * as React from 'react';
import { IUtilities } from '../../../core/utilities/Utilities';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';

interface PhoneNumberComponentProps {
	phoneNumber: string;
	handleChangePhoneNumber(value: string): void;
	className?: string;
	trackKeyEvent?: (event: any) => void;
	isCcPhoneInput?: boolean;
	id?: string;
}
interface PhoneNumberComponentState {
	phoneNumberState: string;
}

const utilities = container.get<IUtilities>(TYPES.IUtilities);

export class PhoneNumberComponent extends React.Component<PhoneNumberComponentProps, PhoneNumberComponentState> {
	constructor(props: PhoneNumberComponentProps) {
		super(props);
		this.state = {
			phoneNumberState: '',
		};
	}
	componentDidMount() {
		this.phoneNumberDisplayValue();
	}
	onChangePhoneNumber = (event: any) => {
		let value = event.target.value;
		let length = value.length;
		let phoneNumber: string = '';
		if (value !== '') {
			if (utilities.validateTenDigitphoneNumber(value)) {
				if (length <= 5) {
					if (length > 1) {
						phoneNumber = value.replace('(', '');
						phoneNumber = phoneNumber.replace(')', '');
						if (
							(this.state.phoneNumberState.includes(')') && !value.includes(')')) ||
							(this.state.phoneNumberState.includes('(') && !value.includes('('))
						)
							phoneNumber = value;
						else if (length > 3) phoneNumber = '(' + phoneNumber + ')';
						else phoneNumber = value;
					} else {
						if (length < this.state.phoneNumberState.length && length > 1) phoneNumber = value;
						else {
							phoneNumber = !value.includes('(') ? '(' : '';
							phoneNumber = phoneNumber != '' ? phoneNumber + value + ')' : '';
						}
					}
					this.setState({ phoneNumberState: phoneNumber });
				}
				if (length >= 6 && length <= 12) {
					if (length == 6 && !value.includes(' '))
						phoneNumber = this.state.phoneNumberState + ' ' + value.substring(6, 5);
					else phoneNumber = value;
					this.setState({ phoneNumberState: phoneNumber });
				}
				if (length >= 10) {
					if (length == 10 && !value.includes('-'))
						phoneNumber = this.state.phoneNumberState + '-' + event.target.value.substring(10, 9);
					else phoneNumber = value;
					this.setState({ phoneNumberState: phoneNumber });
				}
				phoneNumber = this.replaceBrackets(value);
				this.props.handleChangePhoneNumber(phoneNumber);
			}
		} else {
			phoneNumber = value;
			this.setState({ phoneNumberState: phoneNumber });
			this.props.handleChangePhoneNumber(phoneNumber);
		}
	};

	replaceBrackets(value: string): string {
		let res = value.replace('(', '');
		res = res.replace(')', '');
		res = res.replace(' ', '');
		res = res.replace('-', '');
		return res;
	}

	phoneNumberDisplayValue = () => {
		let phoneNumber = this.props.phoneNumber ? this.props.phoneNumber : '';
		let phoneNumberDisplayvalue: any = utilities.phoneNumberDisplay(phoneNumber);
		this.setState({ phoneNumberState: phoneNumberDisplayvalue });
	};
	render() {
		return (
			<input
				id={this.props.id && this.props.id}
				type='text'
				data-test-auto='389F94CC-BD4F-4636-B286-8BCF199BC6AE'
				className={'form-control phoneNumeberTextBox ' + this.props.className}
				placeholder='( ) -'
				value={this.state.phoneNumberState}
				onChange={this.onChangePhoneNumber}
				onKeyDown={(e: any) => {
					this.props.isCcPhoneInput && this.props.trackKeyEvent && this.props.trackKeyEvent(e);
				}}
			/>
		);
	}
}
export default PhoneNumberComponent;
