import React, { FC } from 'react';
import { ErrorMessages } from '../../common/constants/Constants';

const InvlaidLink: FC = () => {
	return (
		<>
			<div className='ErrorContainer'>
				<div className='Errorheader'></div>
				<div className='ErrorPageContent'>
					<div className='MessageContainer'>
						<p className='ErrorMsgPara'>{ErrorMessages.InvalidLink}</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default InvlaidLink;
