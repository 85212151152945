import {
	ControlBaseProps,
	ControlData,
	SignatureData,
	TextBoxData,
	CheckBox,
	RadioButton,
} from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import {
	CompanyControl,
	CustomTextControl,
	IControl,
	InitialControl,
	ISignatureData,
	SignatureControl,
	TextData,
	TitleControl,
	DateControl,
	ITextData,
	PrintNameControl,
	NameAndTitleControl,
	RadioButtonControl,
	IChoosableControlResult,
	CheckBoxControl,
	ChoosableControlResult,
} from '../../../../../model/Esign/IControls';
import { IDocument, Document } from '../../../../../model/Esign/Document';
import * as Enumerable from 'linq';
import { EsignStrings } from '../../../../../common/constants/Constants';

export interface IValidationModel {
	status: boolean;
	message: string;
}

export class ValidationModel implements IValidationModel {
	status: boolean;
	message: string;

	constructor(status: boolean, message: string) {
		this.status = status;
		this.message = message;
	}

	public static create(status: boolean, message: string) {
		return new ValidationModel(status, message);
	}
}

export interface IEsignValidationModel extends IValidationModel {
	document: IDocument;
	page: number;
	control?: IControl;
}

export class EsignValidationModel implements IEsignValidationModel {
	status: boolean;
	message: string;
	document: IDocument;
	page: number;

	constructor(status: boolean, message: string, document: IDocument, page: number, control?: IControl) {
		this.status = status;
		this.message = message;
		this.document = document;
		this.page = page;
	}

	public static create(status: boolean, message: string, document: IDocument, page: number, control?: IControl) {
		return new EsignValidationModel(status, message, document, page, control);
	}
}

export class PdfViewManager {
	public static updateControlData(
		documents: IDocument[],
		controlData: ControlData,
		controlProps: ControlBaseProps,
	): number {
		try {
			let total: number = 0;
			let controlsWithValue: number = 0;

			for (var i = 0; i <= documents.length - 1; i++) {
				// doument loop

				const pages = documents[i].pages;

				for (var j = 0; j <= pages.length - 1; j++) {
					// document pages loop

					const controls = pages[j].controls;

					if (controls && controls.length > 0) {
						for (var k = 0; k <= controls.length - 1; k++) {
							// page controls loop

							const control = controls[k];

							this.update(control, controlData, controlProps);
						}

						const totalRequiredControls = Enumerable.from(controls).count((x) => x.required == true);
						total = total + totalRequiredControls;

						const tmpControlsWithValues = Enumerable.from(controls).count(
							(x: any) =>
								x.required == true &&
								x.data &&
								((x.data['text'] && x.data['text'].trim() != '') ||
									x.data['signature'] ||
									(x.data['checked'] && x.data['checked'] === true) ||
									this.controlDataExist(x.data) === true),
						);

						controlsWithValue = controlsWithValue + tmpControlsWithValues;

						pages[j].allControlSigned = totalRequiredControls == tmpControlsWithValues;
					} else if (controls.length == 0) {
						pages[j].allControlSigned = true;
					}
				}
				const totalAllSignedPages = Enumerable.from(documents[i].pages).count((x) => x.allControlSigned == true);
				documents[i].allControlSigned = documents[i].pages.length == totalAllSignedPages;
			}

			return isNaN(controlsWithValue / total) ? 0 : (controlsWithValue / total) * 100;
		} catch (error) {
			console.log(error);
		}

		return 0;
	}

	private static controlDataExist(controlData: any[]): boolean {
		if (controlData && controlData.length > 0) {
			return controlData.filter((x) => x.checked === true).length > 0;
		}
		return false;
	}

	public static highlightSelectedFile(fileId: number, pageNo: number) {
		const currentfileitem: any = document.getElementsByClassName('file-node-selected')[0];
		currentfileitem && currentfileitem.classList.remove('file-node-selected');

		const nextFileitem: any = document.getElementsByClassName('file-node-' + fileId + '_' + pageNo)[0];
		nextFileitem && nextFileitem.classList.add('file-node-selected');
	}

	public static getControlValueUpdatedPercentage(documents: IDocument[]): number {
		try {
			let total: number = 0;
			let controlsWithValue: number = 0;

			for (var i = 0; i <= documents.length - 1; i++) {
				// doument loop

				const pages = documents[i].pages;

				for (var j = 0; j <= pages.length - 1; j++) {
					// document pages loop

					const controls = pages[j].controls;

					total = total + Enumerable.from(controls).count((x) => x.required == true);

					const tmpControlsWithValues = Enumerable.from(controls).count(
						(x: any) =>
							x.required == true &&
							x.data &&
							((x.data['text'] && x.data['text'].trim() != '') ||
								x.data['signature'] ||
								(x.data['checked'] && x.data['checked'] === true) ||
								this.controlDataExist(x.data) === true),
					);

					controlsWithValue = controlsWithValue + tmpControlsWithValues;
				}
			}

			return isNaN(controlsWithValue / total) ? 0 : (controlsWithValue / total) * 100;
		} catch (error) {}

		return 0;
	}

	private static update(control: IControl, controlData: ControlData, controlProps: ControlBaseProps) {
		if (
			(control instanceof SignatureControl && controlProps.data['controlType'] == 1) ||
			(control instanceof InitialControl && controlProps.data['controlType'] == 2)
		) {
			if (control.id == controlProps.data['controlId'] || control.data != undefined) {
				// add signature to the particluar control or update already signed controls
				const tmpSignatureData: any = SignatureData.create(
					(controlData as SignatureData).name,
					(controlData as SignatureData).signature,
					(controlData as SignatureData).signatureMode,
				);
				control.data = tmpSignatureData;
			}
		} else if (
			(control instanceof NameAndTitleControl && controlProps.data['controlType'] == 4) ||
			(control instanceof CompanyControl && controlProps.data['controlType'] == 5) ||
			(control instanceof PrintNameControl && controlProps.data['controlType'] == 12) ||
			(control instanceof TitleControl && controlProps.data['controlType'] == 13)
		) {
			const tmpTextData: TextBoxData = TextData.create((controlData as TextBoxData).text || '');
			control.data = tmpTextData;
		} else if (control instanceof CheckBoxControl && controlProps.data['controlType'] == 14) {
			if (control.id == controlProps.data.controlId) {
				let tmpCheckBoxData: ChoosableControlResult[] = [];
				let checkBoxData = controlData as CheckBox[];
				control.items.forEach(function (checkbox: CheckBox) {
					const index = checkBoxData.findIndex((x) => x.id == checkbox.id);
					if (index != -1) {
						tmpCheckBoxData.push(ChoosableControlResult.create(checkbox.id, checkBoxData[index].value.checked));
					}
				});
				control.data = tmpCheckBoxData;
			}
		} else if (control instanceof RadioButtonControl && controlProps.data['controlType'] == 15) {
			if (control.id == controlProps.data.controlId) {
				let tmpRadioButtonData: ChoosableControlResult = ChoosableControlResult.create('', false);
				let radiobuttonData = controlData as RadioButton[];
				control.items.forEach(function (radio: RadioButton) {
					const index = radiobuttonData.findIndex((x) => x.id == radio.id);
					if (index != -1 && radiobuttonData[index].value.checked) {
						tmpRadioButtonData = ChoosableControlResult.create(radio.id, true);
					}
				});
				control.data = tmpRadioButtonData;
			}
		} else if (control instanceof CustomTextControl) {
			if (control.id == controlProps.data['controlId']) {
				const tmpTextData: TextBoxData = TextData.create((controlData as TextBoxData).text || '');
				control.data = tmpTextData;
			}
		}
	}
}

export class EsignManager {
	public static validateEsignData(documents: IDocument[]): IEsignValidationModel {
		let _self = this;
		let validationModel: IEsignValidationModel = EsignValidationModel.create(true, '', Document.createNullObject(), 0);

		try {
			for (var i = 0; i <= documents.length - 1; i++) {
				// doument loop

				if (!validationModel.status) {
					break;
				}

				const pages = documents[i].pages;

				for (var j = 0; j <= pages.length - 1; j++) {
					// document pages loop

					if (!validationModel.status) {
						break;
					}

					const controls = pages[j].controls;

					for (var k = 0; k <= controls.length - 1; k++) {
						// page controls loop

						const control = controls[k];

						if (control.required) {
							validationModel = _self.validate(control);

							if (!validationModel.status) {
								const document = documents[i];
								const page = pages[j];

								validationModel.control = control;
								validationModel.page = page.page;
								validationModel.document = document;

								break;
							}
						}
					}
				}
			}
		} catch (error) {}

		return validationModel;
	}

	private static validate(control: IControl): IEsignValidationModel {
		let validationModel: IEsignValidationModel = EsignValidationModel.create(true, '', Document.createNullObject(), 0);

		if (control instanceof SignatureControl) {
			const signatureData: ISignatureData = control.data as ISignatureData;

			if (signatureData == undefined || !signatureData.signature) {
				validationModel = EsignValidationModel.create(
					false,
					EsignStrings.SIGNATURE_VALIDATION_ERROR_MESSAGE,
					Document.createNullObject(),
					0,
				);
			}
		} else if (control instanceof DateControl) {
			const textData: ITextData = control.data as ITextData;

			if (textData == undefined || !textData.text) {
				validationModel = EsignValidationModel.create(
					false,
					EsignStrings.DATE_VALIDATION_ERROR_MESSAGE,
					Document.createNullObject(),
					0,
				);
			}
		} else if (control instanceof InitialControl) {
			const signatureData: ISignatureData = control.data as ISignatureData;

			if (signatureData == undefined || !signatureData.signature) {
				validationModel = EsignValidationModel.create(
					false,
					EsignStrings.INITIAL_VALIDATION_ERROR_MESSAGE,
					Document.createNullObject(),
					0,
				);
			}
		} else if (control instanceof NameAndTitleControl) {
			const textData: ITextData = control.data as ITextData;

			if (textData == undefined || !textData.text) {
				validationModel = EsignValidationModel.create(
					false,
					EsignStrings.TITLE_VALIDATION_ERROR_MESSAGE,
					Document.createNullObject(),
					0,
				);
			}
		} else if (control instanceof TitleControl) {
			const textData: ITextData = control.data as ITextData;

			if (textData == undefined || !textData.text) {
				validationModel = EsignValidationModel.create(
					false,
					EsignStrings.TITLE_VALIDATION_ERROR_MESSAGE,
					Document.createNullObject(),
					0,
				);
			}
		} else if (control instanceof CompanyControl) {
			const textData: ITextData = control.data as ITextData;

			if (textData == undefined || !textData.text) {
				validationModel = EsignValidationModel.create(
					false,
					EsignStrings.COMPANY_VALIDATION_ERROR_MESSAGE,
					Document.createNullObject(),
					0,
				);
			}
		} else if (control instanceof CustomTextControl) {
			const textData: ITextData = control.data as ITextData;

			if (textData == undefined || !textData.text) {
				validationModel = EsignValidationModel.create(
					false,
					EsignStrings.CUSTOM_TEXTBOX_VALIDATION_ERROR_MESSAGE,
					Document.createNullObject(),
					0,
				);
			}
		} else if (control instanceof PrintNameControl) {
			const textData: ITextData = control.data as ITextData;

			if (textData == undefined || !textData.text) {
				validationModel = EsignValidationModel.create(
					false,
					EsignStrings.NAME_TEXTBOX_VALIDATION_ERROR_MESSAGE,
					Document.createNullObject(),
					0,
				);
			}
		} else if (control instanceof CheckBoxControl) {
			const checkBoxResult: IChoosableControlResult = control.data as IChoosableControlResult;

			if (checkBoxResult == undefined) {
				validationModel = EsignValidationModel.create(
					false,
					EsignStrings.CHECKBOX_VALIDATION_ERROR_MESSAGE,
					Document.createNullObject(),
					0,
				);
			}
		} else if (control instanceof RadioButtonControl) {
			const radionButtonGroupResult: IChoosableControlResult = control.data as IChoosableControlResult;

			if (radionButtonGroupResult == undefined) {
				validationModel = EsignValidationModel.create(
					false,
					EsignStrings.RADIOBUTTON_VALIDATION_ERROR_MESSAGE,
					Document.createNullObject(),
					0,
				);
			}
		} else {
			throw new Error('Method not implemented.');
		}

		return validationModel;
	}
}
