import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';

interface IConsentPopUpProps {
	companyName: string;
	handleConsent: (consentGiven: boolean, countryCodeInput?: string, mobileNumberInput?: string) => void;
	countryCode: string;
	mobileNumber: string;
}

interface CountryData {
	name: string;
	dialCode: string;
	countryCode: string;
	format: string;
}

const ConsentPopUp = (props: IConsentPopUpProps) => {
	const { companyName, countryCode, mobileNumber } = props;
	const [mobileNumberInput, setMobileNumberInput] = useState(mobileNumber);
	const [countryCodeInput, setCountryCodeInput] = useState(countryCode ?? '+1');
	const [mobileNumberError, setMobileNumberError] = useState(false);
	const [mobileNumberErrorMessage, setMobileNumberErrorMessage] = useState('');

	useEffect(() => {
		setCountryCodeInput(countryCode ?? '+1');
		setMobileNumberInput(mobileNumber ?? '');
	}, [countryCode, mobileNumber]);

	const isValidTenDigitNumber = (value: string) => {
		const pattern = new RegExp(/^[0-9]{0,10}$/);
		return pattern.test(value);
	};

	const onChangeCountryCode = (fullValue: string, countryObject: CountryData) => {
		setCountryCodeInput(`+${countryObject.dialCode}`);
	};

	const onChangeMobileNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (isValidTenDigitNumber(event.target.value)) setMobileNumberInput(event.target.value);
	};

	const onBlurMobileNumberInput = () => {
		if (mobileNumberInput.length === 0) {
			setMobileNumberError(true);
			setMobileNumberErrorMessage('This field is required');
		} else if (mobileNumberInput.length < 10) {
			setMobileNumberError(true);
			setMobileNumberErrorMessage('Please enter a 10 digit mobile number');
		} else {
			setMobileNumberError(false);
			setMobileNumberErrorMessage('');
		}
	};

	return (
		<>
			<Modal.Body className='consentPopUpBody'>
				<div className='consentPopUpBodyHeading'>Would you like to receive SMS Text Notification?</div>
				<p>
					By responding "Yes", I authorize <span>{companyName}</span> and its service providers (including SafeSend) to
					send me notifications and other messages at the number provided on this form via text (SMS) using automated
					dialing technology. Consent is not a condition for purchase. Message and data rates may apply. To opt-out at
					any time, reply "STOP".
				</p>
				<div className='mobileNumberSection'>
					<label htmlFor=''>Enter Mobile Number</label>
					<PhoneInput
						containerClass={`phoneInput ${mobileNumberError ? 'mobileNumberError' : ''}`}
						country={'us'}
						autoFormat={false}
						placeholder=''
						value={countryCodeInput}
						onChange={onChangeCountryCode}
					/>
					<input
						type='text'
						name='mobileNumberInput'
						id='mobileNumberInput'
						value={mobileNumberInput}
						onChange={onChangeMobileNumberInput}
						onBlur={(e) => {
							onBlurMobileNumberInput();
						}}
						className={`mobileNumberInput ${mobileNumberError ? 'mobileNumberInputBorder' : ''}`}
						maxLength={10}
					/>
					<div
						id='mobileNumberErrorMessage'
						className='mobileNumberErrorMessage'>
						{mobileNumberErrorMessage}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='consentPopUpFooter'>
				<div>
					<button
						data-test-auto='7e9ca792-e972-41cc-a7a8-596770e07b6e'
						className='noButton'
						onClick={() => {
							props.handleConsent(false);
						}}>
						No
					</button>
					<button
						disabled={mobileNumberInput.length < 10}
						data-test-auto='9ff5fabf-fb69-4a7e-b3dc-f2365b860dc9'
						className={mobileNumberInput.length < 10 ? 'disabledYesButton' : 'yesButton'}
						onClick={() => {
							props.handleConsent(true, countryCodeInput, mobileNumberInput);
						}}>
						Yes
					</button>
				</div>
			</Modal.Footer>
		</>
	);
};

export default ConsentPopUp;
