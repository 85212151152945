import * as React from 'react';

export interface CustomInputProps {
	value?: string;
	onChange?(event: any): void;
	automationId: string;
}

export const CustomInput: React.FunctionComponent<CustomInputProps> = (props) => {
	return (
		<input
			type='text'
			data-test-auto={props.automationId}
			className='custom-input'
			onChange={props.onChange}
			value={props.value}
		/>
	);
};
