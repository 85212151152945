import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ISelectItem } from '../../../model/Common/Common';
import { DateType } from '../../../model/Common/Enums';
import { DropdownComponent } from '../Dropdown/DropdownComponent';

export interface IYearMonthDayProps {
	controlValue: Date;
	onControlValueChange(value: string, type: DateType): void;
	id: string;
}

interface IYearMonthDayState {
	selectedYear: ISelectItem;
	selectedMonth: ISelectItem;
	selectedDay: ISelectItem;
	dayList: ISelectItem[];
	monthList: ISelectItem[];
	yearList: ISelectItem[];
}

export class YearMonthDay extends React.Component<IYearMonthDayProps, IYearMonthDayState> {
	constructor(props: any) {
		super(props);
		let today = new Date();
		let day: string = today.getDate().toString();
		let month: string = (today.getMonth() + 1).toString();
		let year: string = today.getFullYear().toString();
		this.state = {
			selectedYear: {
				key: year,
				value: year,
			},
			selectedMonth: {
				key: month,
				value: month,
			},
			selectedDay: {
				key: day,
				value: day,
			},
			dayList: [],
			monthList: [],
			yearList: [],
		};

		this.onYearChange = this.onYearChange.bind(this);
		this.onMonthChange = this.onMonthChange.bind(this);
		this.onDayChange = this.onDayChange.bind(this);
	}

	componentDidMount() {
		this.SetControlValues();
	}

	static getDerivedStateFromProps(props: IYearMonthDayProps, state: IYearMonthDayState) {
		if (
			!props.controlValue ||
			(props.controlValue.getDate().toString() == state.selectedDay.value &&
				(props.controlValue.getMonth() + 1).toString() == state.selectedMonth.value &&
				props.controlValue.getFullYear().toString() == state.selectedYear.value)
		) {
			return null;
		} else {
			let day: string = props.controlValue.getDate().toString();
			let month: string = (props.controlValue.getMonth() + 1).toString();
			let year: string = props.controlValue.getFullYear().toString();
			return {
				selectedDay: { key: day, value: day },
				selectedMonth: { key: month, value: month },
				selectedYear: { key: year, value: year },
			} as IYearMonthDayState;
		}
	}

	componentDidUpdate(prevProps: IYearMonthDayProps) {
		if (this.props.controlValue != prevProps.controlValue) {
			this.SetControlValues();
		}
	}

	private SetControlValues = () => {
		let dayOptions = [];
		for (var i = 1; i <= 31; i++) {
			dayOptions.push({ key: i.toString(), value: i.toString() });
		}
		let monthOptions = [];
		for (var i = 1; i <= 12; i++) {
			monthOptions.push({ key: i.toString(), value: i.toString() });
		}
		let yearOptions = [];
		for (var i = new Date().getFullYear(); i > 1900; i--) {
			yearOptions.push({ key: i.toString(), value: i.toString() });
		}
		this.setState({
			yearList: yearOptions,
			monthList: monthOptions,
			dayList: dayOptions,
		});
		this.SetDayOptions(this.props);
		this.SetMonthOptions(this.props);
		this.SetYearOptions(this.props);
	};

	private SetDayOptions = (props: IYearMonthDayProps) => {
		let day = props.controlValue.getDate();

		this.setState({ selectedDay: { key: day.toString(), value: day.toString() } });
	};

	private SetMonthOptions = (props: IYearMonthDayProps) => {
		let month = props.controlValue.getMonth();

		this.setState({ selectedMonth: { key: (month + 1).toString(), value: (month + 1).toString() } });
	};

	private SetYearOptions = (props: IYearMonthDayProps) => {
		let year = props.controlValue.getFullYear();

		this.setState({ selectedYear: { key: year.toString(), value: year.toString() } });
	};

	private onYearChange = (value: string) => {
		let val: number = parseInt(value);
		if (val === undefined || val === null) {
			val = 0;
		}
		if (!isNaN(val)) {
			this.props.onControlValueChange(val.toString(), DateType.Year);

			this.setState({
				selectedYear: {
					key: val.toString(),
					value: val.toString(),
				},
			});
		}
	};

	private onMonthChange = (value: string) => {
		let val: number = parseInt(value);
		if (val === undefined || val === null) {
			val = 0;
		}
		if (!isNaN(val) && !(val > 12)) {
			this.props.onControlValueChange(val.toString(), DateType.Month);

			this.setState({
				selectedMonth: {
					key: val.toString(),
					value: val.toString(),
				},
			});
		}
	};

	private onDayChange = (value: string) => {
		let val: number = parseInt(value);
		if (val === undefined || val === null) {
			val = 0;
		}
		if (!isNaN(val) && !(val > 31)) {
			this.props.onControlValueChange(val.toString(), DateType.Date);

			this.setState({
				selectedDay: {
					key: val.toString(),
					value: val.toString(),
				},
			});
		}
	};

	public render() {
		return (
			<Col
				className='YearMonthDay no-padding'
				data-test-auto='26FEB28E-20D0-4EF9-B114-E3E58E435BA8'>
				<div className='select-control'>
					<DropdownComponent
						options={this.state.monthList}
						onChange={this.onMonthChange}
						selectedValue={this.state.selectedMonth.value}
						customPlaceHolder='Months'
					/>
				</div>
				<div className='select-control'>
					<DropdownComponent
						options={this.state.dayList}
						onChange={this.onDayChange}
						selectedValue={this.state.selectedDay.value}
						customPlaceHolder='Days'
					/>
				</div>
				<div className='select-control'>
					<DropdownComponent
						options={this.state.yearList}
						onChange={this.onYearChange}
						selectedValue={this.state.selectedYear.value}
						customPlaceHolder='Years'
					/>
				</div>
			</Col>
		);
	}
}

export default YearMonthDay;
