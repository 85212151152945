import * as React from 'react';
import { PdfView } from './PdfView';
import { EsignManager, IEsignValidationModel } from './EsignBase';
import { IDocument } from '../../../../../model/Esign/Document';
import { IHeaderInfoModel } from '../../../../../model/IHeaderInfoModel';
import { DocumentSignatureDataModel } from '../../../../../model/Esign/IDocumentSignatureDataModel';
import { ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { Main } from './Main';
import TaxpayerHelper from '../../../signflow/Helper/TaxpayerHelper';
import { IMarriedJointTaxReturn, ISignerModel, ITaxReturn } from '../../../../../model/TaxReturn/TaxReturn';
import { EngagementTypeS, SignatureType } from '../../../../../model/Common/Enums';
import * as TaxDocument from '../../../../../model/TaxReturn/TaxReturn';
import { ISnackbar } from '../../../../../core/utilities/ui/Snackbar';
import { container } from '../../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../../core/startup/inversify/types';
import { TelemetryLogger } from '../../../../../logger/AppInsights';

const logger = TelemetryLogger.getInstance();

export const NO_INDEX = -1;

interface EsignState {
	documentControlsAndData: DocumentSignatureDataModel[];
	showTimeout: boolean;
	finishEnabled: boolean;
	showSubmitLoader: boolean;
}

export interface ESignProps {
	requestSignatureControls: () => void;
	clientId: string;
	signatureControlsData: IDocument[];
	onSigningComplete: (signData: IDocument[]) => any;
	disableNextButton: (value: boolean) => void;
	completeSigningStep: () => void;
	sign: (documentData: IDocument[], callback: (status: boolean) => void) => void;
	onHeaderAndFooterVisibility: (visibility: boolean) => void;
	headerInfo: IHeaderInfoModel;
	taxReturn: ITaxReturn;
	signerData: ISignerModel[];
	isPreviewMode: boolean;
}

const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

export class Esign extends React.Component<ESignProps, EsignState> {
	private _pdfView: any;

	constructor(props: any, states: EsignState) {
		super(props, states);
		this.state = {
			documentControlsAndData: [],
			showTimeout: false,
			finishEnabled: false,
			showSubmitLoader: false,
		};
		this.props.disableNextButton(true);
	}

	componentDidMount() {
		if (this.props.signatureControlsData == undefined || this.props.signatureControlsData.length == 0) {
			this.props.requestSignatureControls();
		}
		logger.trackTrace(`Esign Page`, { PreviewMode: this.props.isPreviewMode, ClientId: TaxpayerHelper.getClientId() });
	}

	static getDerivedStateFromProps(nextProps: ESignProps, nextState: EsignState) {
		if (
			nextProps.signatureControlsData &&
			nextProps.signatureControlsData.length !== nextState.documentControlsAndData.length
		) {
			return { documentControlsAndData: nextProps.signatureControlsData };
		} else {
			return { documentControlsAndData: nextState.documentControlsAndData };
		}
	}

	handleDocumentSignFinish = (documentControlData: DocumentSignatureDataModel) => {
		let documentDataList: DocumentSignatureDataModel[] = [...this.state.documentControlsAndData];

		const index = documentDataList.findIndex((x) => x.id === documentControlData.id);
		if (index == NO_INDEX) {
			documentDataList.push(documentControlData);
		} else {
			documentDataList[index] = documentControlData;
		}
		this.setState({ documentControlsAndData: documentDataList });
	};

	handleSigningCompleted = () => {
		const documentData: IDocument[] = this._pdfView.getDocumentsData();
		const validation: IEsignValidationModel = EsignManager.validateEsignData(documentData);

		if (validation.status) {
			this.props.onSigningComplete(documentData);
			this.props.disableNextButton(false);
		} else {
			this._pdfView.showValidationMessage(validation.document, validation.page, validation.control);
		}
	};

	private isSpouseSignRequired = (): boolean => {
		const { taxReturn, clientId } = this.props;

		return (
			this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType ==
				SignatureType.ESign &&
			TaxDocument.isMutual(this.props.taxReturn) &&
			this.props.taxReturn.engagementType == EngagementTypeS.E1040 &&
			TaxpayerHelper.isMarried(this.props.taxReturn) &&
			TaxpayerHelper.isFirstSignerLoggedIn(taxReturn as IMarriedJointTaxReturn, this.props.signerData, clientId)
		);
	};

	handleNextButton = (isDisable = false) => {
		this.props.disableNextButton(isDisable);
	};

	onNext(index: number) {
		this.setState({ showSubmitLoader: true }, () => {
			this.props.sign(this.props.signatureControlsData, (status: boolean) => {
				if (status) {
					this.props.completeSigningStep();
					logger.trackEvent(
						{ name: `E-Signing completed successfully in SSR Taxpayer Mobile` },
						{ ClientId: TaxpayerHelper.getClientId() },
					);
				} else {
					this.setState({ showSubmitLoader: false }, () => {
						snackbar.show('Signing process failed!');
					});
				}
			});
		});
	}

	handleViewModeChange = (viewerMode: ViewerMode) => {
		if (viewerMode == ViewerMode.Read) {
			this.props.onHeaderAndFooterVisibility(true);
		} else {
			this.props.onHeaderAndFooterVisibility(false);
		}
	};

	public render() {
		return (
			<Main>
				{this.props.signatureControlsData && (
					<PdfView
						onViewModeChange={this.handleViewModeChange}
						documents={this.props.signatureControlsData}
						onSigningCompleted={this.handleSigningCompleted}
						disableNextButton={this.handleNextButton}
						headerInfo={this.props.headerInfo}
						ref={(ref: PdfView) => (this._pdfView = ref)}
						doNotAllowToEditSignatureName={this.props.taxReturn.engagementType == EngagementTypeS.E1040 ? true : false}
					/>
				)}
			</Main>
		);
	}
}
