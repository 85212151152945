import PdfViewer from 'awesome-pdf-viewer';
import {
	ControlData,
	LabelData,
	SignatureData,
	TextBoxData,
	SignatureMode,
	RadioButton,
	RadioButtonData,
	CheckBox,
	CheckBoxData,
} from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import LabelControl from 'awesome-pdf-viewer/dist/Controls/LabelControl';
import SignatureControl, { SignatureControlProps } from 'awesome-pdf-viewer/dist/Controls/SignatureControl';
import TextBoxControl, { TextBoxControlProps } from 'awesome-pdf-viewer/dist/Controls/TextBoxControl';
import {
	CheckBoxGroupControl,
	CheckBoxGroupControlProps,
} from 'awesome-pdf-viewer/dist/Controls/CheckBox/CheckBoxGroupControl';
import {
	RadioButtonControl,
	RadioButtonControlProps,
} from 'awesome-pdf-viewer/dist/Controls/RadioButtonControl/RadioButtonControl';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import { ControlBaseProps } from 'awesome-pdf-viewer/dist/Layers/ControlLayerBase';
import Header from 'awesome-pdf-viewer/dist/layout/Header';
import LeftPanel from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import Main from 'awesome-pdf-viewer/dist/layout/Main';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import Toolbar from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import * as React from 'react';
import { IPage } from '../../../../../model/Esign/Document';
import * as Controls from '../../../../../model/Esign/IControls';
import { PageProperties } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { IHeaderInfoModel } from '../../../../../model/IHeaderInfoModel';
import Progress from '../../../../common/Progress/Progress';
import Pagination from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import ControlBase from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { PdfJSSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';

export interface ViewProps {
	url: string;
	pages: IPage[];
	fileList: any;
	startNavigationOnDocumentLoad?: boolean;
	finishTarget?: string;
	progress: number;
	onNavigationFinish(): void;
	onAddControlData(controlData: ControlData, controlProps: ControlBaseProps): void;
	onViewModeChange?(viewerMode: ViewerMode): void;
	headerInfo: IHeaderInfoModel;
	onPageChanging(pageProperties: PageProperties): void;
	doNotAllowToEditSignatureName?: boolean;
}

export interface ViewState {
	hideStartNavigationControl: boolean;
	signatureData: SignatureData | undefined;
	initialcontrlData: SignatureData | undefined;
	isDocumentLoaded: boolean;
}

const DEFAULT_VIEWER_SCALE: number = 1;

export class Viewer extends React.Component<ViewProps, ViewState> {
	private _viewPanel: any;
	private _controlLayer: any;
	private _controlList: any[] = [];
	private _toolbar: any;

	constructor(props: any) {
		super(props);
		this.state = {
			hideStartNavigationControl: false,
			signatureData: {
				name: this.props.doNotAllowToEditSignatureName ? this.props.headerInfo.clientName : '',
				signature: undefined,
				signatureMode: SignatureMode.Type,
			},
			isDocumentLoaded: false,
			initialcontrlData: undefined,
		};
	}

	componentDidMount() {
		this.setReferences();
	}

	setReferences() {
		this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
		this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
		this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
		this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
		this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
		this._controlLayer && this._controlLayer.setViewerReference(this._viewPanel);
	}

	componentDidUpdate() {
		this.setReferences();
	}

	public showValidationMessage = (page: number, control: Controls.IControl) => {
		this._viewPanel.gotoPage(page);
		const tmpControl: any = this._controlList.find((x) => x && x.props.id == 'document-control-' + control.id);
		tmpControl && tmpControl.setValidationStatus(false);
	};

	private startNavigation = () => {
		this.setState({ isDocumentLoaded: true }, () => {
			let _self = this;
			this.closeNavigation();

			// find the page it contains controls and navigate to that page

			if (_self.props.startNavigationOnDocumentLoad) {
				const page: IPage | undefined = this.props.pages.find((x: IPage) => x.controls.length > 0);
				page && _self._viewPanel.gotoPage(page.page);
			}

			// some delay to load the pdf pages with controls

			setTimeout(
				function () {
					_self.props.startNavigationOnDocumentLoad &&
						_self.state.hideStartNavigationControl &&
						_self._controlLayer?.startNavigation();
					_self.onNavigateNext();
				}.bind(this),
				2000,
			);
		});
	};

	private closeNavigation = () => {
		this._controlLayer.closeNavigation();
	};

	private setAllSignatureControlValueToSame = (signatureData: SignatureData, controlProps: SignatureControlProps) => {
		this.setState({ signatureData: signatureData }, () => {
			this._controlList.forEach(function (item) {
				if (item && item.props.data && item.props.data.controlType === 1 && item.isSigned()) {
					// signature control
					item.setControlData({
						name: signatureData.name,
						signature: signatureData.signature,
						signatureMode: signatureData.signatureMode,
					});
				}
			});

			this.props.onAddControlData(signatureData, controlProps);
		});
	};

	private setAllInitialControlValueToSame = (initialcontrlData: SignatureData, controlProps: SignatureControlProps) => {
		this.setState({ initialcontrlData: initialcontrlData }, () => {
			this._controlList.forEach(function (item) {
				if (item && item.props.data && item.props.data.controlType == 2 && item.isSigned()) {
					// signature control
					item.setControlData({ name: initialcontrlData.name, signature: initialcontrlData.signature });
				}
			});
			this.props.onAddControlData(initialcontrlData, controlProps);
		});
	};

	setInitial = (controlProps: any, controlDisable?: boolean) => {
		const { initialcontrlData } = this.state;
		const control = this._controlList.find((x) => x && x.props.id == controlProps.id);
		if (initialcontrlData) {
			control && control.setControlData({ name: initialcontrlData.name, signature: initialcontrlData.signature });
			this.props.onAddControlData(initialcontrlData, controlProps);
		} else {
			if (controlDisable == null || (controlDisable && controlDisable !== true)) {
				control && control.openSignatureModal();
			}
		}
	};

	setSignature = (controlProps: any) => {
		const { signatureData } = this.state;
		const control = this._controlList.find((x) => x && x.props.id == controlProps.id);
		control &&
			signatureData &&
			control.setControlData({
				name: signatureData.name,
				signature: signatureData.signature,
				signatureMode: signatureData.signatureMode,
			});

		if (signatureData && signatureData.signature) {
			this.props.onAddControlData(signatureData, controlProps);
		} else {
			control && control.openSignatureModal();
		}
	};

	private setAllTextControlValueToSame = (textBoxData: TextBoxData, controlPops: TextBoxControlProps) => {
		this._controlList.forEach(function (item: any) {
			if (item && controlPops.id == item.props.id) return;

			if (item && item.props.data && item.props.data.controlType == controlPops.data.controlType) {
				item.setControlData(textBoxData);
			}
		});

		this.props.onAddControlData(textBoxData, controlPops);
	};

	private updateCheckBoxControlValue = (checkBoxes: CheckBox[], controlPops: CheckBoxGroupControlProps) => {
		this.props.onAddControlData(checkBoxes, controlPops);
	};

	private updateRadioButtonControlValue = (radioButtons: RadioButton[], controlPops: RadioButtonControlProps) => {
		this.props.onAddControlData(radioButtons, controlPops);
	};

	private createControls = () => {
		this._controlList = [];
		let controlCollection: any[] = [];
		let _self = this;
		const { pages } = this.props;
		const { isDocumentLoaded } = this.state;

		if (!isDocumentLoaded) return this._controlList;

		pages.forEach(function (page: IPage) {
			const pageSize: PageSize = _self._viewPanel.getPageSize(page.page, 1);

			if (pageSize.height > 0) {
				page.controls.forEach(function (control: Controls.IControl, index: number) {
					const top = ControlBase.getPdfViewerControlTopPosition(pageSize.height, control.top);
					const left = ControlBase.getPdfViewerControlLeftPosition(control.left);

					if (control instanceof Controls.SignatureControl) {
						const controlData: Controls.IControlData | undefined = control.data;
						const signatureData: SignatureData | undefined =
							controlData &&
							SignatureData.create(
								(controlData as Controls.ISignatureData).name,
								(controlData as Controls.ISignatureData).signature,
								(controlData as Controls.ISignatureData).signatureMode,
							);

						controlCollection.push(
							<SignatureControl
								isRequired={true}
								ref={(ref) => _self._controlList.push(ref)}
								page={page.page}
								id={'document-control-' + control.id}
								key={'sig-' + control.id}
								name={'Signature'}
								helptext={control.tooltip}
								onAddSignature={_self.setAllSignatureControlValueToSame}
								top={top}
								left={left}
								width={180}
								height={70}
								data={{ controlType: 1, controlId: control.id }}
								signatureData={signatureData}
								onClick={(controlProps: any) => {
									_self.setSignature(controlProps);
								}}
								disabled={true}
								doNotAllowToEditSignatureName={_self.props.doNotAllowToEditSignatureName}
							/>,
						);
					} else if (control instanceof Controls.InitialControl) {
						const controlData: Controls.IControlData | undefined = control.data;
						const initialControlData: SignatureData | undefined =
							controlData &&
							SignatureData.create(
								(controlData as Controls.ISignatureData).name,
								(controlData as Controls.ISignatureData).signature,
							);

						controlCollection.push(
							<SignatureControl
								isRequired={control.required}
								ref={(ref) => _self._controlList.push(ref)}
								page={page.page}
								id={'document-control-' + control.id}
								key={'initial-' + control.id}
								name={'Initial'}
								helptext={control.tooltip}
								top={top}
								left={left}
								width={180}
								height={70}
								onAddSignature={_self.setAllInitialControlValueToSame}
								data={{
									controlType: 2,
									controlId: control.id,
									role: control.customData.find((x) => x instanceof Controls.ControlRole)?.role,
								}}
								signatureData={initialControlData}
								disabled={true}
								onClick={(controlProps: any) => {
									_self.setInitial(controlProps, control.disabled);
								}}
							/>,
						);
					} else if (control instanceof Controls.DateControl) {
						const controlData: Controls.IControlData | undefined = control.data;
						const labelData: LabelData | undefined =
							controlData && LabelData.create((controlData as Controls.ITextData).text);

						controlCollection.push(
							<LabelControl
								skipNavigation={true}
								ref={(ref) => _self._controlList.push(ref)}
								page={page.page}
								id={'document-control-' + control.id}
								key={'date-' + control.id}
								name={'Current Date'}
								value={labelData || LabelData.createNullObject()}
								helptext={control.tooltip}
								data={{ controlType: 3, controlId: control.id }}
								top={top}
								left={left}
								width={99}
								height={15}
								disabled={false}
							/>,
						);
					} else if (control instanceof Controls.NameAndTitleControl) {
						const controlData: Controls.IControlData | undefined = control.data;
						const textboxData: TextBoxData | undefined =
							controlData && TextBoxData.create((controlData as Controls.ITextData).text);

						controlCollection.push(
							<TextBoxControl
								maxLength={60}
								ref={(ref) => _self._controlList.push(ref)}
								page={page.page}
								id={'document-control-nameAndTitle-' + control.id}
								isRequired={control.required}
								key={'nameAndTitle-' + control.id}
								name={'Title'}
								placeholder={'Title'}
								data={{ controlType: 4, controlId: control.id }}
								helptext={control.tooltip}
								textboxData={textboxData}
								top={top}
								left={left}
								width={200}
								height={20}
								onChange={_self.setAllTextControlValueToSame}
								disabled={false}
							/>,
						);
					} else if (control instanceof Controls.CompanyControl) {
						const controlData: Controls.IControlData | undefined = control.data;
						const textboxData: TextBoxData | undefined =
							controlData && TextBoxData.create((controlData as Controls.ITextData).text);

						controlCollection.push(
							<TextBoxControl
								maxLength={60}
								ref={(ref) => _self._controlList.push(ref)}
								page={page.page}
								id={'document-control-' + control.id}
								key={'company-' + control.id}
								name={'Company'}
								isRequired={control.required}
								placeholder={'Company name'}
								data={{ controlType: 5, controlId: control.id }}
								helptext={control.tooltip}
								textboxData={textboxData}
								top={top}
								left={left}
								width={250}
								height={20}
								onChange={_self.setAllTextControlValueToSame}
								disabled={false}
							/>,
						);
					} else if (control instanceof Controls.CustomTextControl) {
						const controlData: Controls.IControlData | undefined = control.data;
						const textboxData: TextBoxData | undefined =
							controlData && TextBoxData.create((controlData as Controls.ITextData).text);

						controlCollection.push(
							<TextBoxControl
								maxLength={60}
								ref={(ref) => _self._controlList.push(ref)}
								page={page.page}
								id={'document-control-' + control.id}
								key={'custom-text-' + control.id}
								name={'Text'}
								isRequired={control.required}
								placeholder={control.tooltip}
								data={{ controlType: 10, controlId: control.id }}
								helptext={control.tooltip}
								textboxData={textboxData}
								top={top}
								left={left}
								width={200}
								height={20}
								onChange={(controlData: ControlData, controlProps: ControlBaseProps) =>
									_self.props.onAddControlData(controlData, controlProps)
								}
								disabled={false}
							/>,
						);
					} else if (control instanceof Controls.PrintNameControl) {
						const controlData: Controls.IControlData | undefined = control.data;
						const textboxData: TextBoxData | undefined =
							controlData && TextBoxData.create((controlData as Controls.ITextData).text);

						controlCollection.push(
							<TextBoxControl
								maxLength={60}
								ref={(ref) => _self._controlList.push(ref)}
								page={page.page}
								id={'document-control-' + control.id}
								key={'printName-' + control.id}
								name={'Print Name'}
								isRequired={control.required}
								placeholder={'Print Name'}
								data={{ controlType: 12, controlId: control.id }}
								helptext={control.tooltip}
								textboxData={textboxData}
								top={top}
								left={left}
								width={200}
								height={20}
								onChange={_self.setAllTextControlValueToSame}
								disabled={false}
							/>,
						);
					} else if (control instanceof Controls.TitleControl) {
						const controlData: Controls.IControlData | undefined = control.data;
						const textboxData: TextBoxData | undefined =
							controlData && TextBoxData.create((controlData as Controls.ITextData).text);

						controlCollection.push(
							<TextBoxControl
								maxLength={60}
								ref={(ref) => _self._controlList.push(ref)}
								page={page.page}
								id={'document-control-' + control.id}
								key={'title-' + control.id}
								name={'Title'}
								isRequired={control.required}
								placeholder={'Title'}
								data={{ controlType: 13, controlId: control.id }}
								helptext={control.tooltip}
								textboxData={textboxData}
								top={top}
								left={left}
								width={250}
								height={20}
								onChange={_self.setAllTextControlValueToSame}
								disabled={false}
							/>,
						);
					} else if (control instanceof Controls.CheckBoxControl) {
						let controlItems = _self.getCheckBoxControlItems(control);
						const minTop = Math.min.apply(
							Math,
							controlItems.map(function (control) {
								return control.top;
							}),
						);
						let checkBoxControlItems = [...controlItems];
						const controlItem = checkBoxControlItems[checkBoxControlItems.findIndex((x) => x.top == minTop)];
						checkBoxControlItems.forEach(function (checkBox: any) {
							checkBox.top = checkBox.top - minTop;
						});
						controlCollection.push(
							<CheckBoxGroupControl
								id={'document-control-' + control.id}
								key={'title-' + control.id}
								ref={(ref) => {
									_self._controlList.push(ref);
								}}
								page={page.page}
								isRequired={control.required}
								disabled={control.disabled}
								width={controlItem.left + 30}
								height={15}
								left={left}
								top={top + minTop}
								helptext={control.tooltip}
								name={'Options'}
								data={{ controlType: 14, controlId: control.id }}
								items={checkBoxControlItems}
								onChange={_self.updateCheckBoxControlValue}
							/>,
						);
					} else if (control instanceof Controls.RadioButtonControl) {
						let controlItems = _self.getRadioButtonControlItems(control);
						const minTop = Math.min.apply(
							Math,
							controlItems.map(function (control) {
								return control.top;
							}),
						);
						let radioButtonControlItems = [...controlItems];
						const controlItem = radioButtonControlItems[radioButtonControlItems.findIndex((x) => x.top == minTop)];
						radioButtonControlItems.forEach(function (checkBox: any) {
							checkBox.top = checkBox.top - minTop;
						});

						controlCollection.push(
							<RadioButtonControl
								id={'document-control-' + control.id}
								key={'title-' + control.id}
								ref={(ref) => {
									_self._controlList.push(ref);
								}}
								page={page.page}
								isRequired={control.required}
								disabled={control.disabled}
								width={controlItem.left + 30}
								height={15}
								left={left}
								top={top + minTop}
								helptext={control.tooltip}
								name={'Options'}
								data={{ controlType: 15, controlId: control.id }}
								items={radioButtonControlItems}
								onChange={_self.updateRadioButtonControlValue}
							/>,
						);
					} else {
					}
				});
			}
		});

		return controlCollection;
	};

	private getCheckBoxControlItems = (control: Controls.CheckBoxControl) => {
		const controlData: Controls.IControlData | undefined = control.data;
		const checkBoxResult = controlData as Controls.IChoosableControlResult[];

		let checkBoxes: CheckBox[] = [];
		control.items.forEach(function (checkBox: any) {
			let checked: boolean = false;
			let result = checkBoxResult?.filter((x) => x.id === checkBox.id);
			if (result && result.length > 0) {
				checked = result[0].checked;
			}

			const tmpCheckBox: CheckBox = CheckBox.create(
				checkBox.name,
				checkBox.top,
				checkBox.left,
				checkBox.width,
				checkBox.height,
				CheckBoxData.create(checked),
			);

			tmpCheckBox.id = checkBox.id;

			checkBoxes.push(tmpCheckBox);
		});

		return checkBoxes;
	};

	private getRadioButtonControlItems = (control: Controls.RadioButtonControl) => {
		const controlData: Controls.IControlData | undefined = control.data;
		const radioButtonResult = controlData as Controls.IChoosableControlResult;
		const radioButtonData: Controls.ChoosableControlResult | undefined =
			controlData && Controls.ChoosableControlResult.create(radioButtonResult.id, radioButtonResult.checked);

		let radioButtons: RadioButton[] = [];

		control.items.forEach(function (radio: any) {
			let selected: boolean = false;
			if (radio.id.toString() == radioButtonData?.id) {
				selected = true;
			}

			const tmpRadioButtonData = RadioButtonData.create(selected, '');
			const tmpRadioButton: RadioButton = RadioButton.create(
				radio.name,
				radio.top,
				radio.left,
				radio.width,
				radio.height,
				tmpRadioButtonData,
			);

			tmpRadioButton.id = radio.id;

			radioButtons.push(tmpRadioButton);
		});

		return radioButtons;
	};

	onNavigateNext = () => {
		this._controlLayer &&
			this._controlLayer._controlLayerManager._controls.forEach((control: any) => {
				if (control instanceof CheckBoxGroupControl || control instanceof RadioButtonControl) {
					const elements: any = document.querySelectorAll('[data-shepherd-step-id=' + control.props.id + ']');
					for (var i = 0; i <= elements.length - 1; i++) {
						const element: any = elements[i];
						if (!element.classList.contains('shepherd-element-left-adjustment')) {
							element.classList.add('shepherd-element-left-adjustment');
						}
					}
				}
			});
	};

	public render() {
		let navigationFinishTarget: any = undefined;

		if (this.props.finishTarget) {
			navigationFinishTarget = {
				targetId: this.props.finishTarget,
				text: 'Finish',
			};
		}

		return (
			<PdfViewer id={'esign-aweosme-pdf-viewer'}>
				<Header>
					<Toolbar
						ref={(ref: any) => (this._toolbar = ref)}
						hideRightPanel={true}
						onViewModeChange={this.props.onViewModeChange}>
						<Pagination />
						<Zoom />
					</Toolbar>
				</Header>
				{this.props.progress > 0 && <Progress status={this.props.progress} />}
				<Main>
					<LeftPanel>{this.props.fileList}</LeftPanel>

					<ControlLayer
						ref={(ref: any) => (this._controlLayer = ref)}
						useDefaultNavigationStartControl={!this.state.hideStartNavigationControl}
						onNavigationComplete={this.props.onNavigationFinish}
						onNavigationBegin={() => {
							this.setState({ hideStartNavigationControl: true });
						}}
						navigationFinishTarget={navigationFinishTarget}
						onNavigateNext={this.onNavigateNext}>
						{this.createControls()}
					</ControlLayer>

					<ViewPanel
						onDocumentLoad={this.startNavigation}
						ref={(ref: any) => (this._viewPanel = ref)}
						onPageChanging={this.props.onPageChanging}
						pdfSource={PdfJSSource.createFromUrl(this.props.url)}
						disableTextLayer={true}></ViewPanel>
				</Main>
			</PdfViewer>
		);
	}
}
