import { SignatureType } from "./Common/Enums"


export interface ISignatureSettingsModel {
    signatureFormSelectionType: SignatureType,
    knowledgeBasedAuthentication: boolean,
    enableSignerDeligation: boolean,
    useSignatureStamp: boolean,
    enableEsignatureForBusinessReturns: boolean,
    manualAddressId: number
}

export const initailsignatureSettingsModel: ISignatureSettingsModel = {
    signatureFormSelectionType: SignatureType.ESign,
    knowledgeBasedAuthentication: false,
    enableSignerDeligation: false,
    enableEsignatureForBusinessReturns: false,
    useSignatureStamp: false,
    manualAddressId: 0
}