import { combineReducers } from 'redux';
import { IDocumentSettings } from '../model/DocumentSettings';
import { ISummaryModel } from '../model/SummaryModel/SummaryModel';
import { ITaxingAuthority } from '../model/Common/TaxingAuthority';
import { IDocumentReviewModel } from '../model/DocumentReviewModel/IDocumentReviewModel';
import { ITaxReturn, ISignerModel } from '../model/TaxReturn/TaxReturn';
import { IKBATransactionResponse } from '../model/KBA/KBA';
import { IDocument } from '../model/Esign/Document';
import { IHeaderInfoModel } from '../model/IHeaderInfoModel';
import { ISignProcessInfo } from '../components/Pages/signflow/Helper/TaxpayerSignFlowHelper';
import { preparerMessageReducer } from '../components/Pages/PreparerMessage/Actions/PreparerMessageActions';
import { summaryDetailsReducer } from '../components/Pages/TaxSummary/Action/TaxSummaryActions';
import { taxingAuthoritiesReducer } from '../components/Pages/TaxSummary/Action/TaxingAuthorityActions';
import { documentReviewDetailsReducer } from '../components/Pages/Review/Actions/ReviewActions';
import {
	taxDocumentReducer,
	headerInfoReducer,
	signProcessInfoReducer,
	signerDataReducer,
	delegateeDetailsReducer,
} from '../components/Pages/signflow/Action/TaxpayerSignFlowActions';
import { kbaReducer, esignReducer } from '../components/Pages/Sign/Esign/Action/EsignActions';
import * as ClientCoverReducer from '../components/Cover/clientCoverActions';
import * as headerReducer from '../components/HeaderActions';
import * as OTPReducer from '../components/OTP/OTPActions';
import * as SSNReducer from '../components/SSN/SSNPageAction';
import * as SSNPage from '../components/SSN/SSNPageAction';
import * as ErrorReducer from './ErrorStore';
import { IDelegateeInfo } from '../model/IDelegateeSignerModel';

export interface ApplicationState {
	documentSettings: IDocumentSettings;
	summaryModel: ISummaryModel;
	taxingAuthorities: ITaxingAuthority[];
	documentReviewModel: IDocumentReviewModel[];
	taxReturn: ITaxReturn;
	kbaData: IKBATransactionResponse;
	signatureControlsData: IDocument[];
	headerInfo: IHeaderInfoModel;
	signProcessInfo: ISignProcessInfo;
	isKBAPassed: any;
	signerData: ISignerModel[];
	coverState: ClientCoverReducer.IClientCoverState;
	headerState: headerReducer.IBaseViewModel;
	OTPState: OTPReducer.IOTPPage;
	SSNPageReducer: SSNPage.ISSNAuthModel;
	ErrorState: ErrorReducer.ErrorState;
	delegateeDetails: IDelegateeInfo;
}

export const reducers = {
	documentSettings: preparerMessageReducer,
	summaryModel: summaryDetailsReducer,
	taxingAuthorities: taxingAuthoritiesReducer,
	documentReviewModel: documentReviewDetailsReducer,
	taxReturn: taxDocumentReducer,
	kbaData: kbaReducer,
	signatureControlsData: esignReducer,
	headerInfo: headerInfoReducer,
	signProcessInfo: signProcessInfoReducer,
	isKBAPassed: kbaReducer,
	signerData: signerDataReducer,
	coverState: ClientCoverReducer.reducer,
	headerState: headerReducer.reducer,
	OTPState: OTPReducer.reducer,
	SSNPageReducer: SSNPage.reducer,
	ErrorState: ErrorReducer.reducer,
	delegateeDetails: delegateeDetailsReducer,
};

export interface AppThunkAction<TAction> {
	(dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}
