import { Step } from 'awesome-multi-steps/dist/Body/Step';
import { Wizard } from 'awesome-multi-steps/dist/Body/Wizard';
import { ISignatureSettingsModel, initailsignatureSettingsModel } from '../../../model/ISignatureSettingsModel';
import * as React from 'react';
import { match } from 'react-router-dom';
import { Common, WelcomeSummaryConstants } from '../../../common/constants/Constants';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';
import { ILoader } from '../../../core/utilities/ui/Loader';
import { ClientType, EngagementTypeS, KBATransactionResultType, SignatureType } from '../../../model/Common/Enums';
import { ITaxingAuthority } from '../../../model/Common/TaxingAuthority';
import { IDocumentReviewModel } from '../../../model/DocumentReviewModel/IDocumentReviewModel';
import { IDocumentSettings } from '../../../model/DocumentSettings';
import { IDocument } from '../../../model/Esign/Document';
import { DocumentGroups } from '../../../model/IGroup';
import { IHeaderInfoModel } from '../../../model/IHeaderInfoModel';
import { IKBAAnswers, IKBATransactionResponse } from '../../../model/KBA/KBA';
import { ISummaryModel } from '../../../model/SummaryModel/SummaryModel';
import * as TaxDocument from '../../../model/TaxReturn/TaxReturn';
import { PreparerMessage } from '../PreparerMessage/PreparerMessage';
import { FilingInstructions } from '../Review/FilingInstructions';
import { InvoiceInfo } from '../Review/InvoiceInfo';
import { ReviewTaxReturn } from '../Review/ReviewTaxReturn';
import { EsignConsentEmail } from '../Sign/Esign/EsignConsent/EsignConsentEmail';
import { KBAInstructions } from '../Sign/Esign/KBA/KBAInstructions';
import { KBAQuestions } from '../Sign/Esign/KBA/KBAQuestions';
import { Esign } from '../Sign/Esign/Sign/Esign';
import { PdfViewManager } from '../Sign/Esign/Sign/EsignBase';
import { SpouseDetails } from '../Sign/Esign/SpouseDetails/SpouseDetails';
import { SignMethod } from '../SignMethod/SignMethod';
import { TaxSummary } from '../TaxSummary/TaxSummary';
import TaxpayerHelper from './Helper/TaxpayerHelper';
import {
	IClientProcessModel,
	ISignProcessInfo,
	SignProcessSteps,
	TaxpayerSignFlowHelper,
} from './Helper/TaxpayerSignFlowHelper';
import { IDelegateeSignerDetails } from '../../../model/IDelegateeSignerModel';
import { History } from 'history';
import { TelemetryLogger } from '../../../logger/AppInsights';
import SuiteModal from '../../../components/common/SuiteModal';
import ConsentPopUp from '../../../components/ConsentPopUp';
import { ISMSGETResponse, ISMSPUTRequest } from 'src/core/services/SMSService/SMSService.model';
import { getConsentDetails, updateConsentDetails } from '../../../core/services/SMSService/SMSService.api';
import { isPartnership } from '../../../model/TaxReturn/TaxReturn';

export const logger = TelemetryLogger.getInstance();

const NO_INDEX = -1;

export interface TaxpayerSignFlowProps {
	documentSettings: IDocumentSettings;
	summaryModel: ISummaryModel;
	taxingAuthorities: ITaxingAuthority[];
	documentReviewModel: IDocumentReviewModel[];
	taxReturn: TaxDocument.ITaxReturn;
	signerData: TaxDocument.ISignerModel[];

	fetchHeaderInfo(successCallback?: () => void): void;

	fetchPreparerMessage(): void;
	fetchSummaryDetails(): void;
	fetchTaxingAuthorities(): void;
	fetchDocumentReviewDetails(): void;

	fetchTaxDocument(successCallback?: (taxReturn: TaxDocument.ITaxReturn) => void): void;

	fetchTaxDocumentClientType(): void;
	headerInfo: IHeaderInfoModel;

	updateSpouseMail(id: string, newMail: string, type: ClientType, callback?: any): void;
	updateSpouseMobile(
		id: string,
		mobile: string,
		type: ClientType,
		countryCode: string,
		ssn: string,
		callback?: any,
	): void;

	updateDOB(id: string, dob: string, step: boolean, callback?: (step: boolean) => void): void;

	signProcessInfo: ISignProcessInfo;

	authenticateClient(failureCallback: () => void): void;
	validateClient(
		data: IKBAAnswers,
		successCallback: (isNextQuestionSet?: boolean) => void,
		failureCallback: () => void,
	): void;

	updateDocumentSignatureSettingModel(signatureType: number, callback: any): void;

	fetchKBAStatus(failureCallback?: () => void): void;

	match: match;
	history: History;

	kbaData: IKBATransactionResponse;

	fetchControlData(): void;
	updateSignatureControlsData(data: IDocument[]): void;
	sign(documentData: IDocument[], callback: (status: boolean) => void): void;
	signatureControlsData: IDocument[];

	downloadAllDocuments: (fileName: string) => any;

	fetchCurrentStepAndUserRole(successCallback?: (clientProcess: IClientProcessModel) => void): void;

	updateLastVisitedStep(step: SignProcessSteps, successCallback?: () => void): void;

	assignToDelegatee(delegatee: IDelegateeSignerDetails, callback: () => void): void;
}

interface TaxpayerSignFlowState {
	signatureSettings: ISignatureSettingsModel;
	hideNextButton: boolean;
	hidePreviousButton: boolean;
	isNextButtonDisable: boolean;
	isConsentAgreed: boolean;
	hideNavigationBar: boolean;
	hideFooter: boolean;
	isDelegateeAssigned: boolean;
	isPreviewMode: boolean;
	showConsentPopUp: boolean;
	countryCode: string;
	mobileNumber: string;
	clientId: string;
	clientType: number;
	companyName: string;
	initialCountryCode: string;
	initialMobileNumber: string;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const loader = container.get<ILoader>(TYPES.ILoader);

export class TaxpayerSignFlow extends React.Component<TaxpayerSignFlowProps, TaxpayerSignFlowState> {
	private _wizardRef: any;
	private _consentRef: any;
	private _spouseDetails: any;
	private _esignRef: any;
	private _clientId: string;

	private _taxpayerSignFlowManager: TaxpayerSignFlowHelper = TaxpayerSignFlowHelper.createNullObject();

	constructor(props: TaxpayerSignFlowProps) {
		super(props);

		this.state = {
			signatureSettings: initailsignatureSettingsModel,
			hideNextButton: false,
			hidePreviousButton: false,
			isNextButtonDisable: false,
			isConsentAgreed: false,
			hideNavigationBar: false,
			hideFooter: false,
			isDelegateeAssigned: false,
			isPreviewMode: false,
			showConsentPopUp: false,
			countryCode: '+1',
			mobileNumber: '',
			clientId: '',
			clientType: 0,
			companyName: '',
			initialCountryCode: '',
			initialMobileNumber: '',
		};

		this._clientId = TaxpayerHelper.getClientId();
	}

	componentDidMount() {
		const clientGuid = TaxpayerHelper.getClientId();
		this.props.fetchTaxDocument((taxReturnReceived: any) => {
			let clientIdReceived = taxReturnReceived.clientId;
			let clientTypeReceived = 0;
			let countryCode = '';
			let mobileNumber = '';
			if (taxReturnReceived.spouse?.clientGuid === clientGuid) {
				clientTypeReceived = 2;
				countryCode = taxReturnReceived.spouse?.countryCode;
				mobileNumber = taxReturnReceived.spouse?.mobileNumber;
			} else {
				clientTypeReceived = 1;
				countryCode = isPartnership(taxReturnReceived)
					? taxReturnReceived.partnership?.countryCode
					: taxReturnReceived.taxpayer?.countryCode;
				mobileNumber = isPartnership(taxReturnReceived)
					? taxReturnReceived.partnership?.mobileNumber
					: taxReturnReceived.taxpayer?.mobileNumber;
			}
			this.setState({
				clientId: clientIdReceived,
				clientType: clientTypeReceived,
			});
			getConsentDetails(
				clientGuid,
				clientIdReceived,
				clientTypeReceived,
				mobileNumber,
				(consentResponse: ISMSGETResponse) => {
					if (!countryCode || countryCode.length === 0) {
						countryCode = consentResponse.countryCode ?? '+1';
					}
					if (!mobileNumber || mobileNumber.length === 0) {
						mobileNumber = consentResponse.mobileNumber ?? '';
					}
					this.setState({
						showConsentPopUp: consentResponse.isConsent,
						countryCode: countryCode,
						mobileNumber: mobileNumber,
						initialCountryCode: countryCode,
						initialMobileNumber: mobileNumber,
					});
					if (!consentResponse.isConsent) {
						this.getSignProcessStatus();
					} else {
						this.props.fetchHeaderInfo(() => {
							const { headerInfo } = this.props;
							this.setState({ companyName: headerInfo.companyName });
						});
					}
				},
			);
		});
	}

	showInstructionModal = () => {
		const { headerInfo } = this.props;
		const message: string = `<br/> <b>Welcome ${headerInfo.clientName}, your ${headerInfo.taxYear} tax returns are ready! </b> <br/><br/> ${WelcomeSummaryConstants.WelcomeMessage}`;
		dialogBox.alert('', message, '');
	};

	signProcessRecoverConfirmation = (lastVisitedStep: SignProcessSteps) => {
		if (lastVisitedStep !== SignProcessSteps.SignerProcessComplete) {
			this._taxpayerSignFlowManager.signProcessRecoverConfirmation(lastVisitedStep, this._clientId);
		}
	};

	goToStep = (stepNumber: number) => {
		this._wizardRef && this._wizardRef.goToStep(stepNumber);
	};

	goToStepById = (id: any) => {
		this._wizardRef && this._wizardRef.goToStepById(id);
	};

	goToNextStep = () => {
		this._wizardRef && this._wizardRef.goToNextStep();
	};

	goToPreviousStep = () => {
		this._wizardRef && this._wizardRef.goToPreviousStep();
	};

	onConsentWizardNextBtnClick = (currStep: number) => {
		this._consentRef.onNext(currStep);
	};

	onConsentWizardPreviousBtnClick = (currStep: number) => {
		this._consentRef.onPrevious(currStep);
	};

	handleDisableNextButton = (value: boolean) => {
		this.setState({ isNextButtonDisable: value });
	};

	handleHeaderAndFooterVisibility = (hidden: boolean) => {
		this.setState({ hideNavigationBar: hidden, hideFooter: hidden });
	};

	handleStepChange = (step: number, stepId: string) => {
		this.props.updateLastVisitedStep(Number(stepId), () => {
			logger.trackTrace(`Clicked on step --> ${SignProcessSteps[Number(stepId)]}`, {
				PreviewMode: this.state.isPreviewMode,
				ClientId: TaxpayerHelper.getClientId(),
			});
		});
	};

	private resetWizardProps = () => {
		this.setState({
			hideNextButton: false,
			hidePreviousButton: false,
			isNextButtonDisable: false,
		});
	};

	handleConsentAgreed = (value: boolean) => {
		this.handleDisableNextButton(!value);
		this.setState({ isConsentAgreed: value });
	};

	handleEsignStepNext = (currStep: number) => {
		loader.show();
		this.handleDisableNextButton(true);
		this.props.updateLastVisitedStep(SignProcessSteps.SignerProcessComplete, () => {
			this._esignRef && this._esignRef.onNext(currStep);
		});
	};

	handleSetSignMethod = (signatureType: SignatureType) => {
		const param: any = this.props.match.params;

		let tempSignatureSetting: ISignatureSettingsModel = this.state.signatureSettings as ISignatureSettingsModel;
		tempSignatureSetting.signatureFormSelectionType = signatureType;

		this.setState({ signatureSettings: tempSignatureSetting }, () => {
			this.props.updateDocumentSignatureSettingModel(signatureType, () => {
				this.updateSettingsAndRefreshTaxDocument(param.clientId);
			});
		});
	};

	updateSettingsAndRefreshTaxDocument = (clientId: string) => {
		// this.props.requestTaxDocument(clientId);
		if (this.state.signatureSettings.signatureFormSelectionType === SignatureType.ESign) {
			this.goToNextStep();
		} else {
			this.goToStepById(SignProcessSteps.ManualSignDownload.toString());
		}
	};

	checkIfBothSignerDeceased = () => {
		const param: any = this.props.match.params;
		if (
			this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType !==
				SignatureType.ManualSign &&
			TaxpayerHelper.bothSignerDeceased(this.props.taxReturn as any, this.props.signerData)
		) {
			this.handleSetSignMethod(SignatureType.ManualSign);
		}
	};

	saveDelegateeState = () => {
		this.setState({ isDelegateeAssigned: true });
	};

	disableNavigationLink = (step: SignProcessSteps) => {
		let index = NO_INDEX;
		index = this.props.signProcessInfo.visitedSteps && this.props.signProcessInfo.visitedSteps.indexOf(step);
		if (index == null || (index != null && index === NO_INDEX) || this.state.isDelegateeAssigned) {
			return true;
		} else {
			return false;
		}
	};

	private clearState = () => {
		this.setState({
			hideNextButton: false,
			hidePreviousButton: false,
			isNextButtonDisable: false,
		});
	};

	private getTaxDocument = () => {
		const { fetchTaxDocument, fetchTaxDocumentClientType } = this.props;
		fetchTaxDocument(fetchTaxDocumentClientType);
	};

	private getSignProcessStatus = () => {
		this.props.fetchCurrentStepAndUserRole(this.handleRedirect);
	};

	private handleRedirect = (clientProcessState: IClientProcessModel) => {
		const clientId: string = TaxpayerHelper.getClientId();
		if (
			!(
				this._taxpayerSignFlowManager.isSigningProcessCompleted() ||
				(clientProcessState && clientProcessState.isSigned)
			)
		) {
			this.props.fetchHeaderInfo(this.showInstructionModal);
		}
		this._taxpayerSignFlowManager.handleRedirect(clientId, clientProcessState);
		this.fetchTaxDocumentInfo();
		this.signProcessRecoverConfirmation(clientProcessState.currentstep.lastVisitedStep);

		this.setState({
			isDelegateeAssigned: clientProcessState.isDelegated,
		});
	};

	private fetchTaxDocumentInfo = () => {
		const { fetchPreparerMessage, fetchDocumentReviewDetails, fetchTaxDocument, fetchKBAStatus } = this.props;
		fetchTaxDocument(this.redirectToManualSignrestrictedPage);
		fetchPreparerMessage();
		fetchDocumentReviewDetails();
		fetchKBAStatus();
	};

	private isSpouseSignRequired = (): boolean => {
		return (
			this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType ==
				SignatureType.ESign &&
			TaxDocument.isMutual(this.props.taxReturn) &&
			this.props.taxReturn.engagementType == EngagementTypeS.E1040 &&
			TaxpayerHelper.isMarried(this.props.taxReturn) &&
			TaxpayerHelper.isFirstSignerLoggedIn(
				this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn,
				this.props.signerData,
				this._clientId,
			)
		);
	};

	private redirectToManualSignrestrictedPage = () => {
		const { taxReturn, fetchTaxDocumentClientType } = this.props;
		fetchTaxDocumentClientType();
		let param: any = this.props.match.params;
		this._taxpayerSignFlowManager.handleRedirect(param.id);
	};

	private handleConsent = (consentGiven: boolean, countryCodeInput?: string, mobileNumberInput?: string) => {
		const consentDetails: ISMSPUTRequest = {
			clientId: this.state.clientId,
			clientGuid: TaxpayerHelper.getClientId(),
			clientType: this.state.clientType,
			countryCode: consentGiven ? (countryCodeInput ? countryCodeInput : '') : this.state.initialCountryCode,
			mobileNumber: consentGiven ? (mobileNumberInput ? mobileNumberInput : '') : this.state.initialMobileNumber,
			isConsent: consentGiven,
		};
		updateConsentDetails(consentDetails, () => {
			this.setState({ showConsentPopUp: false }, () => {
				this.getSignProcessStatus();
			});
		});
	};

	public render() {
		const {
			documentSettings,
			summaryModel,
			documentReviewModel,
			taxingAuthorities,
			fetchSummaryDetails,
			fetchTaxingAuthorities,
			taxReturn,
			signerData,
		} = this.props;

		const completedPercentage = PdfViewManager.getControlValueUpdatedPercentage(this.props.signatureControlsData);
		var taxpayer = TaxpayerHelper.getSecondSigner(
			taxReturn as TaxDocument.IMarriedJointTaxReturn,
			signerData,
			this._clientId,
		);

		return (
			<React.Fragment>
				<Wizard
					hideTopNavigation={true}
					ref={(ref: any) => {
						this._wizardRef = ref;
						this._taxpayerSignFlowManager = TaxpayerSignFlowHelper.create(ref, this.props);
					}}
					onStepChange={this.handleStepChange}
					disableMobileResponsiveness={true}
					submitLabel={Common.Wizard.SubmitButtonLabel}>
					<Step
						id={SignProcessSteps.Summary.toString()}
						tooltip={'Summary'}
						name={'Summary'}
						previousLabel={Common.Wizard.PreviousButtonLabel}
						nextLabel={Common.Wizard.NextButtonLabel}
						hideNextButton={true}
						hidePreviousButton={true}>
						<TaxSummary
							summaryModel={summaryModel}
							taxingAuthorities={taxingAuthorities}
							onFetchSummaryDetails={fetchSummaryDetails}
							onFetchTaxingAuthorities={fetchTaxingAuthorities}
							isTaxreturnReady={taxReturn.id > 0 && documentReviewModel.length > 0}
							onNext={this.goToNextStep}
							isPreviewMode={this.state.isPreviewMode}
						/>
					</Step>

					<Step
						id={SignProcessSteps.PreparerMessage.toString()}
						tooltip={'PreparerMessage'}
						name={'PreparerMessage'}
						previousLabel={Common.Wizard.PreviousButtonLabel}
						nextLabel={Common.Wizard.NextButtonLabel}>
						<PreparerMessage
							message={documentSettings.deliverySettings.preparerMessage}
							headerInfo={this.props.headerInfo}
							isPreviewMode={this.state.isPreviewMode}
						/>
					</Step>

					<Step
						id={SignProcessSteps.Invoice.toString()}
						tooltip={'Invoice'}
						name={'Invoice'}
						previousLabel={Common.Wizard.PreviousButtonLabel}
						nextLabel={Common.Wizard.NextButtonLabel}
						hidden={this.props.documentReviewModel.find((x) => x.documentGroup == DocumentGroups.Invoice) == undefined}>
						<InvoiceInfo
							documentReviewModel={documentReviewModel}
							isPreviewMode={this.state.isPreviewMode}
						/>
					</Step>

					<Step
						id={SignProcessSteps.FilingInstructions.toString()}
						tooltip={'Filing Instructions'}
						name={'Filing Instructions'}
						previousLabel={Common.Wizard.PreviousButtonLabel}
						nextLabel={Common.Wizard.NextButtonLabel}
						hidden={
							this.props.documentReviewModel.find((x) => x.documentGroup == DocumentGroups.Transmittals) == undefined
						}>
						<FilingInstructions
							documentReviewModel={documentReviewModel}
							isPreviewMode={this.state.isPreviewMode}
						/>
					</Step>

					<Step
						id={SignProcessSteps.Review.toString()}
						tooltip={'Review'}
						name={'Review'}
						previousLabel={Common.Wizard.PreviousButtonLabel}
						nextLabel={Common.Wizard.NextButtonLabel}
						hidden={
							this.props.documentReviewModel.find((x) => x.documentGroup == DocumentGroups.TaxReturns) == undefined
						}>
						<ReviewTaxReturn
							documentReviewModel={documentReviewModel}
							signProcessInfo={this.props.signProcessInfo}
							isPreviewMode={this.state.isPreviewMode}
						/>
					</Step>

					<Step
						id={SignProcessSteps.SignMethod.toString()}
						name={'Sign'}
						tooltip={'Sign'}
						hidden={
							!(
								this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType ==
									SignatureType.ESign && !this._taxpayerSignFlowManager.isSigningProcessCompleted()
							)
						}
						hideNextButton={true}
						hidePreviousButton={false}
						onPrevious={this.onConsentWizardPreviousBtnClick}
						disableNavigationClick={this.disableNavigationLink(SignProcessSteps.SignMethod)}
						previousLabel={Common.Wizard.PreviousButtonLabel}
						nextLabel={Common.Wizard.NextButtonLabel}>
						<SignMethod
							onSetSignMethod={this.handleSetSignMethod}
							clearParentState={this.clearState}
							match={this.props.match}
							history={this.props.history}
							taxReturn={this.props.taxReturn}
							saveDelegateeDetails={this.props.assignToDelegatee}
							isDelegateeAssigned={this.state.isDelegateeAssigned}
							goToStep={this.goToStepById}
							saveDelegateeState={this.saveDelegateeState}
							isPreviewMode={this.state.isPreviewMode}
							checkIfBothSignerDeceased={this.checkIfBothSignerDeceased}
							onDelegationSuccess={TaxpayerHelper.redirectToSignCompletedPage}
						/>
					</Step>

					<Step
						id={SignProcessSteps.EsignConsentEmail.toString()}
						hidden={
							!(
								this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType ==
									SignatureType.ESign &&
								this.props.taxReturn.engagementType == EngagementTypeS.E1040 &&
								!this._taxpayerSignFlowManager.isSigningProcessCompleted()
							)
						}
						disableNextButton={this.state.isNextButtonDisable}
						onNext={this.onConsentWizardNextBtnClick}
						onPrevious={this.onConsentWizardPreviousBtnClick}
						preventNextButtonDefaultBehaviour={true}
						preventPreviousButtonDefaultBehaviour={true}
						disableNavigationClick={true}
						previousLabel={Common.Wizard.PreviousButtonLabel}
						nextLabel={Common.Wizard.NextButtonLabel}>
						<EsignConsentEmail
							clientId={this._clientId}
							ref={(ref: any) => {
								this._consentRef = ref;
							}}
							updateDOBChange={this.props.updateDOB}
							individual={this.props.taxReturn as TaxDocument.IIndividualTaxReturn}
							mutual={this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn}
							consent={this.state.isConsentAgreed}
							onConsentAgreed={this.handleConsentAgreed}
							nextStep={this.goToNextStep}
							prevStep={this.goToPreviousStep}
							fetchTaxDocument={this.getTaxDocument}
							signerData={this.props.signerData}
							disableNextButton={this.handleDisableNextButton}
							clearParentState={this.resetWizardProps}
							isPreviewMode={this.state.isPreviewMode}
						/>
					</Step>

					<Step
						id={SignProcessSteps.SpouseSignature.toString()}
						hidden={
							!(
								taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESign &&
								taxReturn.engagementType == EngagementTypeS.E1040 &&
								!this._taxpayerSignFlowManager.isSigningProcessCompleted() &&
								this.isSpouseSignRequired() &&
								taxpayer.isDeceased == false
							)
						}
						disableNextButton={this.state.isNextButtonDisable}
						onNext={() => {
							this._spouseDetails.handleSave(() => {
								this.goToNextStep();
							});
						}}
						onPrevious={this.onConsentWizardPreviousBtnClick}
						preventNextButtonDefaultBehaviour={true}
						preventPreviousButtonDefaultBehaviour={true}
						disableNavigationClick={true}
						previousLabel={Common.Wizard.PreviousButtonLabel}
						nextLabel={Common.Wizard.NextButtonLabel}
						hidePreviousButton={true}>
						<SpouseDetails
							onUpdateMail={this.props.updateSpouseMail}
							onUpdateMobile={this.props.updateSpouseMobile}
							clientId={this._clientId}
							ref={(ref: any) => {
								this._spouseDetails = ref;
							}}
							signerData={this.props.signerData}
							individual={this.props.taxReturn as TaxDocument.IIndividualTaxReturn}
							mutual={this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn}
							isPreviewMode={this.state.isPreviewMode}
						/>
					</Step>

					<Step
						id={SignProcessSteps.KBAInstructions.toString()}
						tooltip={'KBA Instructions'}
						name={'KBAInstructions'}
						previousLabel={Common.Wizard.PreviousButtonLabel}
						nextLabel={Common.Wizard.NextButtonLabel}
						hidePreviousButton={true}
						hidden={
							!(
								this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType ==
									SignatureType.ESign &&
								this.props.taxReturn.engagementType == EngagementTypeS.E1040 &&
								!this.props.kbaData.isKBAPassed &&
								this.props.documentSettings.documentSignatureSetting.knowledgeBasedAuthentication
							)
						}>
						<KBAInstructions />
					</Step>

					<Step
						id={SignProcessSteps.KBA.toString()}
						hideNextButton={true}
						hidePreviousButton={
							this.props.kbaData.transactionStatus.toString() ===
							KBATransactionResultType[KBATransactionResultType.error]
								? false
								: true
						}
						disableNavigationClick={true}
						previousLabel={Common.Wizard.PreviousButtonLabel}
						nextLabel={Common.Wizard.NextButtonLabel}
						hidden={
							!(
								this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType ==
									SignatureType.ESign &&
								this.props.taxReturn.engagementType == EngagementTypeS.E1040 &&
								!this.props.kbaData.isKBAPassed &&
								this.props.documentSettings.documentSignatureSetting.knowledgeBasedAuthentication
							)
						}>
						<KBAQuestions
							clientId={this._clientId}
							loadKBAQuestions={this.props.authenticateClient}
							validateKBAAnswers={this.props.validateClient}
							response={this.props.kbaData}
							clearParentState={this.resetWizardProps}
							refreshKBAStatus={this.props.fetchKBAStatus}
							onCompletion={this.goToNextStep}
							updateDocumentSignatureSettingModel={this.props.updateDocumentSignatureSettingModel}
							onKBAFailure={() => {
								TaxpayerHelper.redirectToManualSignrestrictedPage(this._clientId);
							}}
							refreshTaxDocument={() => {
								this.props.fetchTaxDocument(this.redirectToManualSignrestrictedPage);
							}}
						/>
					</Step>

					<Step
						id={SignProcessSteps.Esign.toString()}
						hidden={
							!(
								this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType ==
									SignatureType.ESign && !this._taxpayerSignFlowManager.isSigningProcessCompleted()
							)
						}
						disableNextButton={completedPercentage < 100}
						disableNavigationClick={true}
						preventNextButtonDefaultBehaviour={true}
						onNext={this.handleEsignStepNext}
						previousLabel={Common.Wizard.PreviousButtonLabel}
						nextLabel={Common.Wizard.SubmitButtonLabel}
						hideScrollbar={true}
						hidePreviousButton={true}>
						<Esign
							clientId={this._clientId}
							ref={(ref: any) => {
								this._esignRef = ref;
							}}
							onHeaderAndFooterVisibility={this.handleHeaderAndFooterVisibility}
							requestSignatureControls={this.props.fetchControlData}
							signatureControlsData={this.props.signatureControlsData}
							onSigningComplete={this.props.updateSignatureControlsData}
							disableNextButton={this.handleDisableNextButton}
							completeSigningStep={() => {
								TaxpayerHelper.redirectToSignCompletedPage(this._clientId);
							}}
							sign={this.props.sign}
							headerInfo={this.props.headerInfo}
							signerData={this.props.signerData}
							taxReturn={this.props.taxReturn}
							isPreviewMode={this.state.isPreviewMode}
						/>
					</Step>

					<Step id={SignProcessSteps.SignComplete.toString()}></Step>
				</Wizard>

				{this.state.showConsentPopUp && (
					<SuiteModal
						width='301'
						theme='light'
						title='SMS&nbsp;Text Notification&nbsp;Consent'
						onClickOutside={() => {}}
						onClickClose={() => {
							this.handleConsent(false);
						}}>
						<ConsentPopUp
							companyName={this.state.companyName}
							handleConsent={this.handleConsent}
							countryCode={this.state.countryCode}
							mobileNumber={this.state.mobileNumber}
						/>
					</SuiteModal>
				)}
			</React.Fragment>
		);
	}
}
