import { connect } from 'react-redux';
import { downloadAllDocuments } from '../SignCompleted/Action/DownloadableDocumentActions';
import {
	fetchCurrentStepAndUserRole,
	fetchHeaderInfo,
	fetchTaxDocument,
	fetchTaxDocumentClientType,
} from '../signflow/Action/TaxpayerSignFlowActions';
import {
	requestTaxClientDelegateeDetails,
	updateDelegateeSignerDetails,
	cancelDelegation,
	resetLastVisitedSteps,
} from '../Delegatee/Actions/DelegateeActions';
import { SignCompletedFlow } from '../SignCompleted/SignCompletedFlow';
import { ApplicationState } from '../../../reducers';
import { IDelegateeSignerDetails } from '../../../model/IDelegateeSignerModel';
import { IClientProcessModel } from '../signflow/Helper/TaxpayerSignFlowHelper';
import { ITaxReturn } from 'src/model/TaxReturn/TaxReturn';

const mapStateToProps = (state: ApplicationState) => ({
	taxReturn: state.taxReturn,
	headerInfo: state.headerInfo,
	delegateeDetails: state.delegateeDetails,
});

const mapDispatchToProps = (dispatch: any) => ({
	fetchTaxDocument: (successCallback?: (taxReturn: ITaxReturn) => void) => dispatch(fetchTaxDocument(successCallback)),
	fetchHeaderInfo: () => dispatch(fetchHeaderInfo()),
	downloadAllDocuments: (fileName: string) => dispatch(downloadAllDocuments(fileName)),
	fetchTaxDocumentClientType: () => dispatch(fetchTaxDocumentClientType()),
	requestTaxClientDelegateeDetails: (callback?: () => void) => dispatch(requestTaxClientDelegateeDetails(callback)),
	updateDelegateeSignerDetails: (delegatee: IDelegateeSignerDetails, callback: () => void) =>
		dispatch(updateDelegateeSignerDetails(delegatee, callback)),
	cancelDelegation: (remarks: string, taxYear: number, callback: () => void) =>
		dispatch(cancelDelegation(remarks, taxYear, callback)),
	resetLastVisitedSteps: (callback: () => void) => dispatch(resetLastVisitedSteps(callback)),
	fetchCurrentStepAndUserRole: (successCallback?: (clientProcess: IClientProcessModel) => void) =>
		dispatch(fetchCurrentStepAndUserRole(successCallback)),
});

export const TaxpayerSignCompletedFlowContainer = connect(mapStateToProps, mapDispatchToProps)(SignCompletedFlow);
