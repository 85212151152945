import { DocumentSignatureDataModel } from "./IDocumentSignatureDataModel";

 
export interface ISignerControlDataModal {
    clientGuid: string,
    signature: string,
    documentData: DocumentSignatureDataModel[],
    signatureInitial: string
}

export class SignerControlDataModal implements ISignerControlDataModal {

    clientGuid: string;
    signature: string;
    documentData: DocumentSignatureDataModel[];
    signatureInitial: string;


    constructor(clientGuid: string, signature: string, documentData: DocumentSignatureDataModel[], signatureInitial:string) {

        this.clientGuid = clientGuid;
        this.signature = signature;
        this.documentData = documentData;
        this.signatureInitial = signatureInitial;
    }


    static create(clientGuid: string, signature: string, documentData: DocumentSignatureDataModel[], signatureInitial:string): ISignerControlDataModal {
        return new SignerControlDataModal(clientGuid, signature, documentData, signatureInitial);
    }

    public static createNullObject(): ISignerControlDataModal {
        return new SignerControlDataModal("", "", [], "");
    }
}