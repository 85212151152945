import * as React from 'react';
import { Common, DelegateeSignerConstants, SignCompletedConstants } from '../../../../common/constants/Constants';
import { IHeaderInfoModel } from '../../../../model/IHeaderInfoModel';
import { CustomButton, CustomButtonPlacement, CustomButtonSize } from '../../../common/CustomButton/CustomButton';
import { PagePartsContainer } from '../../../common/PagePartsContainer';
import { Body } from '../../../common/StepLayout/Body';
import { StepLayout } from '../../../common/StepLayout/StepLayout';
import { TYPES } from '../../../../core/startup/inversify/types';
import { container } from '../../../../core/startup/inversify/inversify.config';
import { ILoader } from '../../../../core/utilities/ui/Loader';
import { ISnackbar } from '../../../../core/utilities/ui/Snackbar';
import TaxpayerHelper from '../../signflow/Helper/TaxpayerHelper';
import { IFileUtilities } from '../../../../core/utilities/File/FileUtilities';
import { TelemetryLogger } from '../../../../logger/AppInsights';
import { AssignToDelegatee } from '../../Delegatee/AssignToDelegatee';
import { useState, useEffect } from 'react';
import { IDelegateeInfo, IDelegateeSignerDetails } from '../../../../model/IDelegateeSignerModel';
import { isPartnership, ITaxReturn } from '../../../../model/TaxReturn/TaxReturn';
import { initializeAxios } from '../../../../core/services/dataAccess/DataService.Axios';
import SuiteModal from '../../../../components/common/SuiteModal';
import ConsentPopUp from '../../../../components/ConsentPopUp';
import { ISMSGETResponse, ISMSPUTRequest } from 'src/core/services/SMSService/SMSService.model';
import { getConsentDetails, updateConsentDetails } from '../../../../core/services/SMSService/SMSService.api';
import image from '../../../../assets/images/icon-complete.svg';

const logger = TelemetryLogger.getInstance();

interface SignCompletedProps {
	match: any;
	history: any;
	headerInfo: IHeaderInfoModel;
	enagementType: string;
	downloadLink: string;
	delegateeDetails: IDelegateeInfo;
	taxReturn: ITaxReturn;
	updateDelegateeSignerDetails(delegatee: IDelegateeSignerDetails, callback: () => void): void;
	cancelDelegation(remarks: string, taxYear: number, callback: () => void): void;
	resetLastVisitedSteps(callback: () => void): void;
	isDelegateeAssigned: boolean;
	isSignCompleted: boolean;
	fetchTaxDocument(callback?: (taxReturn: ITaxReturn) => void): void;
}

const loader = container.get<ILoader>(TYPES.ILoader);
const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);
const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
const FileNameEndText: string = '_Tax Returns.zip';

export const SignCompleted: React.FunctionComponent<SignCompletedProps> = (props) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showConsentPopUp, setShowConsentPopUp] = useState<boolean>(false);
	const [countryCode, setCountryCode] = useState<string>('+1');
	const [mobileNumber, setMobileNumber] = useState<string>('');
	const [clientId, setClientId] = useState<string>('');
	const [clientType, setClientType] = useState<number>(0);
	const [initialMobileNumber, setInitialMobileNumber] = useState<string>('');
	const [initialCountryCode, setInitialCountryCode] = useState<string>('');

	useEffect(() => {
		const clientGuid = TaxpayerHelper.getClientId();
		props.fetchTaxDocument((taxReturnReceived: any) => {
			let countryCode = '';
			let mobileNumber = '';
			let clientIdReceived = taxReturnReceived.clientId;
			let clientTypeReceived = 0;
			if (taxReturnReceived.spouse?.clientGuid === clientGuid) {
				clientTypeReceived = 2;
				countryCode = taxReturnReceived.spouse?.countryCode;
				mobileNumber = taxReturnReceived.spouse?.mobileNumber;
			} else {
				clientTypeReceived = 1;
				countryCode = isPartnership(taxReturnReceived)
					? taxReturnReceived.partnership?.countryCode
					: taxReturnReceived.taxpayer?.countryCode;
				mobileNumber = isPartnership(taxReturnReceived)
					? taxReturnReceived.partnership?.mobileNumber
					: taxReturnReceived.taxpayer?.mobileNumber;
			}
			setClientId(clientIdReceived);
			setClientType(clientTypeReceived);
			getConsentDetails(
				clientGuid,
				clientIdReceived,
				clientTypeReceived,
				mobileNumber,
				(consentResponse: ISMSGETResponse) => {
					if (!countryCode || countryCode.length === 0) {
						countryCode = consentResponse.countryCode ?? '+1';
					}
					if (!mobileNumber || mobileNumber.length === 0) {
						mobileNumber = consentResponse.mobileNumber ?? '';
					}
					setShowConsentPopUp(consentResponse.isConsent);
					setCountryCode(countryCode);
					setMobileNumber(mobileNumber);
					setInitialCountryCode(countryCode);
					setInitialMobileNumber(mobileNumber);
				},
			);
		});
	}, []);

	let getFileNamePrefix = () => {
		return props.headerInfo.clientName + '_' + props.headerInfo.taxYear + '_' + props.enagementType.toString();
	};

	let getDownloadLink = () => {
		if (props.downloadLink) {
			return (
				<a
					style={{ color: '#0274bb' }}
					download={getFileNamePrefix() + FileNameEndText}
					target='_blank'
					href={props.downloadLink}>
					{SignCompletedConstants.DownloadAllText}
				</a>
			);
		} else {
			return SignCompletedConstants.DownloadAllText;
		}
	};

	let downloadFile = () => {
		loader.show();
		var config = {
			responseType: 'arraybuffer',
			headers: { 'Content-Type': 'application/json;utf-8' },
			withCredentials: true,
		};
		var clientId = TaxpayerHelper.getClientId();
		return initializeAxios(clientId)
			.getWithConfig('api/Download/GetAllDocumentsZipStreamAsync/' + clientId, config)
			.then(function (response: any) {
				fileUtilities.show(response.data, getFileNamePrefix() + FileNameEndText);
				loader.hide();
			})
			.catch(function (error: any) {
				console.log(error);
				loader.hide();
				logger.trackError(`taxpayer mobile getAllDocumentsZipStreamAsync failed with error ${error.message}`, {
					ClientId: clientId,
				});
			});
	};

	let closeDelegationPopUp = () => {
		setShowModal(false);
	};

	const handleConsent = (consentGiven: boolean, countryCodeInput?: string, mobileNumberInput?: string) => {
		setShowConsentPopUp(false);
		const consentDetails: ISMSPUTRequest = {
			clientId: clientId,
			clientGuid: TaxpayerHelper.getClientId(),
			clientType: clientType,
			countryCode: consentGiven ? (countryCodeInput ? countryCodeInput : '') : initialCountryCode,
			mobileNumber: consentGiven ? (mobileNumberInput ? mobileNumberInput : '') : initialMobileNumber,
			isConsent: consentGiven,
		};
		updateConsentDetails(consentDetails, () => {
			setShowConsentPopUp(false);
		});
	};

	return (
		<React.Fragment>
			<StepLayout>
				<Body
					title={''}
					bodyClassName={'sign-completed-body'}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					height={90}
					automationTestId={'4903FAD2-DEA0-423F-82E5-22D85B6BD89B'}>
					<div style={{ height: '40%', minHeight: '250px' }}>
						<img
							src={image}
							className={'sign-completed-icon'}
						/>

						<br />
						<br />

						<h5 data-test-auto='F0920A12-B57C-4D1C-AE54-B4294C8A4F48'>
							Congratulations, All Required Steps Completed.
						</h5>

						<br />

						<p
							data-test-auto='E7E05027-596B-4294-9ECA-B3A9B041F633'
							className='font-weight-normal-bold'>
							We will forward your signed documents to your tax preparer.
						</p>
					</div>

					<br />
					<br />
					<br />

					<div style={{ height: '10%', minHeight: '50px' }}>
						<CustomButton
							icon={Common.Images.Download}
							placement={CustomButtonPlacement.Center}
							size={CustomButtonSize.XL}
							content={getDownloadLink()}
							onClick={() => {
								!props.downloadLink && downloadFile();
								logger.trackTrace(`Clicked on "Download all my tax documents"`, {
									PreviewMode: false,
									ClientId: TaxpayerHelper.getClientId(),
								});
							}}
						/>
					</div>

					{props.isDelegateeAssigned && !props.isSignCompleted && (
						<div style={{ height: '10%', minHeight: '50px' }}>
							<CustomButton
								icon={Common.Images.UpdateDelegatee}
								placement={CustomButtonPlacement.Center}
								size={CustomButtonSize.XL}
								content={'Update Delegatee'}
								onClick={() => setShowModal(true)}
							/>
						</div>
					)}

					<br />

					<div style={{ height: '15%', minHeight: '105px' }}>
						<PagePartsContainer
							height={100}
							borderColor={'#0973ba'}
							padding={20}>
							To view, print, or forward your tax documents or make payments please login using a PC or Tablet
						</PagePartsContainer>
					</div>
				</Body>
			</StepLayout>

			<AssignToDelegatee
				show={showModal}
				onCancel={closeDelegationPopUp}
				param={props.match.params}
				history={props.history}
				delegateeSigner={props.delegateeDetails}
				taxReturn={props.taxReturn}
				isDelegateeAssigned={props.isDelegateeAssigned}
				saveDelegateeSignerDetails={props.updateDelegateeSignerDetails}
				cancelDelegation={props.cancelDelegation}
				resetLastVisitedSteps={props.resetLastVisitedSteps}
				successMsg={DelegateeSignerConstants.SuccessMessage.UpdateDelegateeSuccess}
				isPreviewMode={false}
			/>

			{showConsentPopUp && (
				<SuiteModal
					width='301'
					theme='light'
					title='SMS&nbsp;Text Notification&nbsp;Consent'
					onClickOutside={() => {}}
					onClickClose={() => {
						handleConsent(false);
					}}>
					<ConsentPopUp
						companyName={props.headerInfo.companyName}
						handleConsent={handleConsent}
						countryCode={countryCode}
						mobileNumber={mobileNumber}
					/>
				</SuiteModal>
			)}
		</React.Fragment>
	);
};
