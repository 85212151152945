import * as React from 'react';

interface PagePartsContainerProps {
	height: number;
	borderColor?: string;
	padding?: number;
}

export const PagePartsContainer: React.FunctionComponent<PagePartsContainerProps> = (props) => {
	return (
		<div
			className={'page-parts-container'}
			style={{ height: props.height + '%', borderColor: props.borderColor, padding: props.padding }}>
			{props.children}
		</div>
	);
};
