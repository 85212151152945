import * as React from 'react';
import { Modal, Button, Row } from 'react-bootstrap';
import { TinyMCEComponent } from '../../common/TinyMCE';
import { SelectComponent } from '../../common/SelectComponent';
import { CoutryCodes } from '../../../common/constants/CoutryCodes';
import { PhoneNumberComponent } from '../../common/PhoneNumberComponent/PhoneNumberComponent';
import {
	IDelegateeSignerDetails,
	initialState,
	IDelegateeInfo,
	DelegateeSignerDetails,
} from '../../../model/IDelegateeSignerModel';
import * as TaxDocument from '../../../model/TaxReturn/TaxReturn';
import { IUtilities, Utilities } from '../../../core/utilities/Utilities';
import { History } from 'history';
import {
	DelegateeSignerConstants,
	PathConstants,
	ValidationContants,
	getRoutePathUrl,
} from '../../../common/constants/Constants';
import { SignProcessSteps } from '../signflow/Helper/TaxpayerSignFlowHelper';
import { MessageBox, Toaster } from '../../common/Notification/MessageBox';
import TaxpayerHelper from '../signflow/Helper/TaxpayerHelper';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';
import { DelegateeCancelPopup } from './DelegateeCancelPopup';
import { TelemetryLogger } from '../../../logger/AppInsights';
const htmlencode = require('htmlencode');

const logger = TelemetryLogger.getInstance();

interface AssignToDelegateeProps {
	onReAssign?: (email: string, name: string, reason: string) => void;
	onCancel: () => void;
	show: boolean;
	param?: any;
	history?: History;
	taxReturn?: TaxDocument.ITaxReturn;
	saveDelegateeSignerDetails?: (delegatee: IDelegateeSignerDetails, callback: () => void) => void;
	isDelegateeAssigned?: boolean;
	successMsg: string;
	goToStep?: (stepNumber: number) => void;
	cancelDelegation?: (remarks: string, taxYear: number, callback: () => void) => void;
	resetLastVisitedSteps?: (callback: () => void) => void;
	saveDelegateeState?: () => void;
	delegateeSigner?: IDelegateeInfo;
	isPreviewMode: boolean;
}

interface AssignToDelegateeState {
	delegateeDetails: IDelegateeSignerDetails;
	showCancelDelegationModal: boolean;
	reasonForCancel: string;
	isStateUpdated: boolean;
}

const utilities = container.get<IUtilities>(TYPES.IUtilities);

export const AssignToDelegatee = (props: AssignToDelegateeProps) => {
	const [state, setState] = React.useState<AssignToDelegateeState>({
		delegateeDetails: initialState,
		showCancelDelegationModal: false,
		reasonForCancel: '',
		isStateUpdated: false,
	});

	React.useEffect(() => {
		if (props.delegateeSigner) {
			setState({
				...state,
				delegateeDetails: DelegateeSignerDetails.create(
					props.delegateeSigner.firstName,
					props.delegateeSigner.lastName,
					props.delegateeSigner.email,
					props.delegateeSigner.confirmEmail,
					props.delegateeSigner.countryCode,
					props.delegateeSigner.mobileNumber,
					props.delegateeSigner.description,
				),
			});
		}
	}, [props.delegateeSigner]);

	const handleChangeEmail = (event: any) => {
		let delegateeSigner = { ...state.delegateeDetails };
		delegateeSigner.email = event.target.value;
		setState({ ...state, delegateeDetails: delegateeSigner, isStateUpdated: true });
	};

	const handleChangeConfirmEmail = (event: any) => {
		let delegateeSigner = { ...state.delegateeDetails };
		delegateeSigner.confirmEmail = event.target.value;
		setState({ ...state, delegateeDetails: delegateeSigner });
	};

	const handleChangeFirstName = (event: any) => {
		let delegateeSigner = { ...state.delegateeDetails };
		delegateeSigner.firstName = event.target.value;
		setState({ ...state, delegateeDetails: delegateeSigner });
	};

	const handleChangeLastName = (event: any) => {
		let delegateeSigner = { ...state.delegateeDetails };
		delegateeSigner.lastName = event.target.value;
		setState({ ...state, delegateeDetails: delegateeSigner });
	};

	const handlePhoneNumberChange = (value: string) => {
		let delegateeSigner = { ...state.delegateeDetails };
		delegateeSigner.mobileNumber = value;
		setState({ ...state, delegateeDetails: delegateeSigner });
	};

	const handleCountryCodeChange = (value: string) => {
		let delegateeSigner = { ...state.delegateeDetails };
		delegateeSigner.countryCode = value;
		setState({ ...state, delegateeDetails: delegateeSigner });
	};

	const handleChangeReason = (event: any) => {
		let delegateeSigner = { ...state.delegateeDetails };
		delegateeSigner.description = event.target.getContent();
		setState({ ...state, delegateeDetails: delegateeSigner });
	};

	const validateDetails = () => {
		let result = true;
		if (state.delegateeDetails.firstName.trim() === '') {
			MessageBox.Warning(DelegateeSignerConstants.WarningMessage.EmptyFirstName);
			return (result = false);
		} else if (state.delegateeDetails.email.trim() === '') {
			MessageBox.Warning(DelegateeSignerConstants.WarningMessage.EmptyEmailAdrress);
			return (result = false);
		} else if (!utilities.isValidEmailAddress(state.delegateeDetails.email.trim())) {
			MessageBox.Warning(DelegateeSignerConstants.WarningMessage.InvalidEmailAddress);
			return (result = false);
		} else if (!utilities.isValidEmailAddress(state.delegateeDetails.confirmEmail.trim())) {
			MessageBox.Warning(DelegateeSignerConstants.WarningMessage.InvalidConfirmEmail);
			return (result = false);
		} else if (state.delegateeDetails.email.trim() !== state.delegateeDetails.confirmEmail.trim()) {
			MessageBox.Warning(DelegateeSignerConstants.WarningMessage.EmailDontMatch);
			return (result = false);
		} else if (state.delegateeDetails.description.trim() === '') {
			MessageBox.Warning(DelegateeSignerConstants.WarningMessage.EmptyDescription);
			return (result = false);
		} else if (state.delegateeDetails.mobileNumber.length > 0 || state.delegateeDetails.countryCode.trim() !== '') {
			if (!utilities.validateMobileLength(state.delegateeDetails.mobileNumber)) {
				MessageBox.Warning(DelegateeSignerConstants.WarningMessage.InvalidMobileNumber);
				return (result = false);
			} else if (state.delegateeDetails.countryCode.trim() === '') {
				MessageBox.Warning(ValidationContants.CountryCodeWarning);
				return (result = false);
			}
		}
		return result;
	};

	const handleSubmit = () => {
		var isValidate = validateDetails();
		const clientId: string = TaxpayerHelper.getClientId();
		if (isValidate) {
			if (props.saveDelegateeSignerDetails) {
				props.saveDelegateeSignerDetails(state.delegateeDetails, () => {
					MessageBox.Success(props.successMsg);
					props.saveDelegateeState && props.saveDelegateeState();
					handleCancel();
					logger.trackTrace(`Document delegated to other signer`, {
						PreviewMode: props.isPreviewMode,
						ClientId: clientId,
					});
					setTimeout(() => {
						props.goToStep && props.goToStep(SignProcessSteps.SignComplete);
						TaxpayerHelper.redirectToSignCompletedPage(clientId);
					}, 2000);
				});
			}
		}
	};

	const handleCancelDelegation = () => {
		setState({ ...state, showCancelDelegationModal: true });
	};

	const handleCloseCancelDelegationPopup = () => {
		setState({
			...state,
			showCancelDelegationModal: false,
			reasonForCancel: '',
			isStateUpdated: false,
		});
	};

	const handleReasonForCancelDelagtionChange = (event: any) => {
		setState({ ...state, reasonForCancel: event.target.value });
	};

	const handleSubmitCancelDelegation = () => {
		const clientId: string = TaxpayerHelper.getClientId();
		if (props.cancelDelegation) {
			props.cancelDelegation(state.reasonForCancel, props.taxReturn!.taxYear, () => {
				props.resetLastVisitedSteps &&
					props.resetLastVisitedSteps(() => {
						MessageBox.Success(DelegateeSignerConstants.SuccessMessage.SavedCancelDelegationSuccess);
						handleCancel();
						logger.trackTrace(`Document delegation canceled`, { PreviewMode: props.isPreviewMode, ClientId: clientId });
						setState({ ...state, showCancelDelegationModal: false });
						setTimeout(() => {
							if (!props.param.controllerId) {
								props.history?.push(`${PathConstants.SummaryPage}${props.param.id}`);
							} else {
								props.history?.push(
									getRoutePathUrl(PathConstants.SummaryPage, props.param.id, props.param.controllerId),
								);
							}
						}, 2000);
					});
			});
		}
	};

	const handleCancel = () => {
		props.onCancel();
		setState({
			...state,
			isStateUpdated: false,
		});
	};

	let messageBody: string = state.delegateeDetails.description;
	messageBody = htmlencode.htmlDecode(messageBody);

	return (
		<>
			<Toaster />
			<div>
				<Modal
					show={props.show}
					onHide={handleCancel}
					id='delegatee'>
					<Modal.Header>
						<div className='assign-delegatee-header'>
							<div className='assign-delegatee-header-content'>
								<Modal.Title>
									<div>
										<span
											data-test-auto='42EA6933-C808-486A-A033-D970669BD2F1'
											style={{ color: '#0274bb', fontSize: '1.4rem' }}>
											Send for Signature
										</span>
									</div>
								</Modal.Title>

								<button
									type='button'
									className='close'
									data-dismiss='modal'
									aria-label='Close'
									onClick={handleCancel}>
									<span aria-hidden='true'>&times;</span>
								</button>
							</div>
						</div>
					</Modal.Header>

					<Modal.Body style={{ padding: '15px' }}>
						<div>
							<div
								data-test-auto='EE8124A9-615F-4FCC-887F-AD4A07426075'
								className='delegatee-modal-title'>
								Please enter information for the person you would like to delegate the signing process to
							</div>
							<Row style={{ marginBottom: 10 }}>
								<div style={{ display: 'contents' }}>
									<div className='col-sm-6 col-md-6 col-xl-6'>
										<span
											data-test-auto='EBC0B98A-5C21-4275-84C1-06AB3BA45D83'
											className='font600'>
											First Name *
										</span>
										<div className='delegatee-input-field'>
											<input
												data-test-auto='FD972126-A7BA-45D9-BCC5-4723B1BEADA1'
												className='chip-input'
												type='text'
												value={state.delegateeDetails.firstName}
												onChange={(e) => {
													handleChangeFirstName(e);
												}}
												data-lpignore='true'
											/>
										</div>
									</div>

									<div className='col-sm-6 col-md-6 col-xl-6'>
										<span
											data-test-auto='6CD13AE0-B75C-4503-94F4-565C0F8220AC'
											className='font600'>
											Last Name
										</span>
										<div className='delegatee-input-field'>
											<input
												data-test-auto='8BD7A406-710E-4B48-99F9-5623C2DFF55F'
												className='chip-input'
												type='text'
												value={state.delegateeDetails.lastName}
												onChange={(e) => {
													handleChangeLastName(e);
												}}
												data-lpignore='true'
											/>
										</div>
									</div>
								</div>
							</Row>

							<Row style={{ marginBottom: 10 }}>
								<div style={{ display: 'contents' }}>
									<div className='col-sm-6 col-md-6 col-xl-6'>
										<span
											data-test-auto='EE67A4D3-967B-4343-9781-3C802FA450B4'
											className='font600'>
											Email *
										</span>
										<div className='delegatee-input-field'>
											<input
												data-test-auto='A96868CA-5067-46F9-A058-FC0229A37E2C'
												className='chip-input'
												type='text'
												value={state.delegateeDetails.email}
												onChange={(e) => {
													handleChangeEmail(e);
												}}
												data-lpignore='true'
											/>
										</div>
									</div>

									<div className='col-sm-6 col-md-6 col-xl-6'>
										<span
											data-test-auto='CE006AC3-EB63-4ADE-87CF-881E8EA81441'
											className='font600'>
											Confirm Email *
										</span>
										<div className='delegatee-input-field'>
											<input
												data-test-auto='ABB1D3A6-E33A-46CE-BDE8-14A465A813E0'
												className='chip-input'
												type='text'
												value={state.delegateeDetails.confirmEmail}
												onChange={(e) => {
													handleChangeConfirmEmail(e);
												}}
												data-lpignore='true'
											/>
										</div>
									</div>
								</div>
							</Row>

							<Row>
								<div className='col-sm-6 col-md-6 col-xl-6'>
									<span
										data-test-auto='1B10F3CB-965C-404B-B682-A0A5DF6FCAEF'
										className='font600'>
										SMS/Text enabled mobile number
									</span>
								</div>
							</Row>

							<Row style={{ marginBottom: 10 }}>
								<div style={{ display: 'contents' }}>
									<div className='col-sm-2 col-md-2 col-xl-2 phoneNumberSelect'>
										<SelectComponent
											id={'delegatee-signer-country-code'}
											options={CoutryCodes()}
											selectedValue={state.delegateeDetails.countryCode}
											onChange={handleCountryCodeChange}
										/>
									</div>

									<div className='col-sm-4 col-md-4 col-xl-4'>
										<div className='delegatee-textbox'>
											<PhoneNumberComponent
												phoneNumber={state.delegateeDetails.mobileNumber}
												handleChangePhoneNumber={handlePhoneNumberChange}
												className='height-38 width100 delegatee-number-field'
											/>
										</div>
										<span data-test-auto='B375AEEA-57C1-48EA-AED0-B9FEE7C63BB5'>(Used for authorization)</span>
									</div>
								</div>
							</Row>

							<Row>
								<div className='col-sm-12 col-md-12 col-xl-12'>
									<span
										data-test-auto='68C231A2-82A8-4AEE-A455-747E5D94A5B1'
										className='font600'>
										Message to Signer *
									</span>
									<TinyMCEComponent
										messageBody={messageBody}
										changeStateTinymceBody={handleChangeReason}
										readOnly={0}
									/>
									<br />
								</div>
							</Row>
						</div>
					</Modal.Body>
					<Modal.Footer>
						{props.isDelegateeAssigned ? (
							<Button
								data-test-auto='98723BA7-9A01-42DF-9F79-D53A6EA8F4BC'
								onClick={handleCancelDelegation}
								className='btn btn-primary btn-default rounded'
								style={{ backgroundColor: '#14d13f', border: '#14d13f', float: 'left' }}>
								Cancel Delegation
							</Button>
						) : (
							<Button
								data-test-auto='A1923ACA-6F32-4752-AC14-C647A7AF8962'
								onClick={handleCancel}
								className='btn btn-white btn-default rounded'
								variant={'light'}
								style={{ float: 'left' }}>
								Cancel
							</Button>
						)}

						<Button
							data-test-auto='01AD7084-DA85-4727-9B60-4621E7D66646'
							onClick={handleSubmit}
							variant={'primary'}
							className='btn btn-primary'
							style={{ backgroundColor: '#14d13f', border: '#14d13f' }}>
							Send
						</Button>
					</Modal.Footer>
				</Modal>

				<DelegateeCancelPopup
					onCancel={handleCloseCancelDelegationPopup}
					show={state.showCancelDelegationModal}
					onSaveRemarks={handleSubmitCancelDelegation}
					reason={state.reasonForCancel}
					onRemarksChange={handleReasonForCancelDelagtionChange}
					title={'Reason for cancellation'}
					warningMessage={DelegateeSignerConstants.WarningMessage.EmptyCancelDelegationRemarks}
				/>
			</div>
		</>
	);
};
