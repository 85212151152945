import React from 'react';
import Select from 'react-select';

export interface ISelectComponentProps {
	selectedValue?: any;
	options: any;
	onChange?: (selectedValue: any) => void;
	clearable?: boolean;
	disabled?: boolean;
	customPlaceHolder?: any;
	id: string;
	onFocus?: (event: any) => void;
	trackKeyEvent?: (event: any) => void;
	isCcCountrySelector?: boolean;
	className?: any;
}

export const SelectComponent: React.FC<ISelectComponentProps> = (props) => {
	const { clearable } = props;

	const handleChange = (event: any) => {
		if (props.onChange) {
			props.onChange(event.value);
		}
	};

	const selectedValue =
		props.selectedValue && props.options.find((option: any) => option.value === props.selectedValue.toString());

	return (
		<Select
			id={props.id}
			value={selectedValue}
			onChange={handleChange}
			options={props.options}
			placeholder={
				props.customPlaceHolder && props.customPlaceHolder.length > 0 ? props.customPlaceHolder : 'Select...'
			}
			clearable={clearable == undefined ? true : clearable}
			disabled={props.disabled ? props.disabled : false}
			data-toggle='dropdown'
			data-boundary='window'
			onFocus={props.onFocus}
			onKeyDown={(e: any) => {
				props.isCcCountrySelector && props.trackKeyEvent && props.trackKeyEvent(e);
			}}
			className={props.className}
		/>
	);
};
