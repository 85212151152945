import { connect } from 'react-redux';
import SSNAuth from './SSNPage';
import * as SSNReducer from './SSNPageAction';
import * as HeaderStore from '../HeaderActions';

export default connect(
	(state: any) => ({
		headerInfoState: state.headerState,
		SSNPageState: state.SSNPageReducer,
	}),
	{
		...HeaderStore.actionCreators,
		...SSNReducer.actionCreators,
	},
)(SSNAuth as any);
