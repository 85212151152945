import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from '../common/constants/actionTypes';
import axios from 'axios';
import { MessageBox } from './common/Notification/MessageBox';
import { HeaderConstants } from '../common/constants/Constants';
import { error } from 'jquery';
import { container } from '../core/startup/inversify/inversify.config';
import { TYPES } from '../core/startup/inversify/types';
import { TelemetryLogger } from '../logger/AppInsights';
import { initializeAxios } from '../core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();

interface IThemeSettings {
	bgColorCode: string;
	foreColorCode: string;
}

interface IBrandingSettings {
	coverPageSetting: IThemeSettings;
}

interface ILoggedInUserInfo {
	role: string;
	permission: string;
}

export interface IBaseViewModel {
	clientName: string;
	taxYear: string;
	companyName: string;
	companyLogoPath: string;
	companyWhiteLogoPath: string;
	contactPerson: any;
	brandingSettings: IBrandingSettings | null;
	loggedInUserInfo: ILoggedInUserInfo | null;
}

export const initialHeaderInfo: IBaseViewModel = {
	clientName: '',
	taxYear: '',
	companyName: '',
	companyLogoPath: '',
	companyWhiteLogoPath: '',
	contactPerson: null,
	brandingSettings: null,
	loggedInUserInfo: null,
};

export const actionCreators = {
	requestHeaderDetails:
		(clientId?: string): any =>
		(dispatch: any) => {
			if (initializeAxios(clientId).getJsonValue !== undefined) {
				return initializeAxios(clientId)
					.getJsonValue('api/Coverpage/HeaderInfo/' + clientId)
					.then(function (response: AxiosResponse<any>) {
						let result = response.data;
						if (result.isSuccess) {
							dispatch({
								type: actionTypes.RECEIVE_HEADER_DETAILS,
								data: result.data,
							});
						} else {
							let errorDesc = result.errorDescription;
							logger.trackWarning(
								`Taxpayer Mobile requestHeaderDetails failed with the error ${errorDesc} for client guid ${clientId}`,
								{ ClientId: clientId },
							);
							if (typeof errorDesc === 'string') {
								MessageBox.Error(errorDesc);
							}
						}
					})
					.catch((e: any) => {
						logger.trackError(
							`Taxpayer Mobile requestHeaderDetails failed with the error ${e.message} for client guid ${clientId}`,
							{ ClientId: clientId },
						);
						MessageBox.Error(HeaderConstants.ErrorMessage.RetrieveFailed);
					});
			}
		},
};

export const reducer: Reducer<IBaseViewModel> = (state: IBaseViewModel = initialHeaderInfo, incomingAction: Action) => {
	const action = incomingAction as any;
	const currentState = Object.assign({}, state);
	console.log('in reducers', action);
	switch (action.type) {
		case actionTypes.RECEIVE_HEADER_DETAILS:
			let rData = action.data;
			currentState.clientName = rData.clientName;
			currentState.taxYear = rData.taxYear;
			currentState.companyName = rData.companyName;
			currentState.companyLogoPath = rData.companyLogoPath;
			currentState.companyWhiteLogoPath = rData.companyWhiteLogoPath;
			currentState.contactPerson = rData.contactPerson;
			currentState.brandingSettings = rData.brandingSettings;
			currentState.loggedInUserInfo = rData.loggedInUserInfo;

			return { ...currentState };

		default:
			return currentState;
	}
};
