import { StatusType, actionTypes } from '../../../../../common/constants/actionTypes';
import { container } from '../../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../../core/startup/inversify/types';
import { ILoader } from '../../../../../core/utilities/ui/Loader';
import { ClientType, KBATransactionResultType } from '../../../../../model/Common/Enums';
import { IDocument } from '../../../../../model/Esign/Document';
import { ISignerControlDataModal } from '../../../../../model/Esign/ISignerControlDataModal';
import { IKBAAnswers, IKBATransactionResponse, initialKBAResponse } from '../../../../../model/KBA/KBA';
import TaxpayerHelper from '../../../signflow/Helper/TaxpayerHelper';
import { DocumentAdapter, IDocumentAdapter } from '../Sign/Helper/ControlDataAdapter';
import { TelemetryLogger } from '../../../../../logger/AppInsights';
import { initializeAxios } from '../../../../../core/services/dataAccess/DataService.Axios';
import { AxiosResponse } from 'axios';
import { ErrorMessages } from 'src/common/constants/Constants';

const logger = TelemetryLogger.getInstance();
var moment = require('moment-timezone');

const loader = container.get<ILoader>(TYPES.ILoader);

export interface NotificationAction {
	type: actionTypes.NOTIFICATION;
	statusMessage: string;
	statusType: StatusType;
}

export const authenticateClient = (failureCallback: () => void) => (dispatch: any) => {
	const clientid: string = TaxpayerHelper.getClientId();

	initializeAxios(clientid)
		.get('api/Esign/ClientAuthentication/' + clientid)
		.then(function (response: any) {
			const kbaResult = response.data as IKBATransactionResponse;

			if (
				kbaResult.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.error] ||
				kbaResult.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.failed]
			) {
				failureCallback();
			} else {
				dispatch({
					type: actionTypes.FETCH_KBA_QUESTIONS,
					data: kbaResult,
				});
			}

			dispatch({
				type: actionTypes.FETCH_TAXING_AUTHORITY,
				data: response.data,
			});
		})
		.catch(function (error: any) {
			console.log(error);
			logger.trackError(`taxpayer mobile authenticateClient failed with error ${error.message}`, {
				ClientId: clientid,
			});
		});
};

export const validateClient =
	(data: IKBAAnswers, successCallback: (isNextQuestionSet?: boolean) => void, failureCallback: () => void) =>
	(dispatch: any) => {
		const clientid: string = TaxpayerHelper.getClientId();
		loader.show();
		initializeAxios(clientid)
			.postJson(data, 'api/Esign/ClientValidation/' + clientid)
			.then(function (response: any) {
				const kbaResult = response.data as IKBATransactionResponse;
				if (
					kbaResult.transactionStatus.toString() ==
						KBATransactionResultType[KBATransactionResultType.failed].toString() ||
					kbaResult.transactionStatus.toString() ==
						KBATransactionResultType[KBATransactionResultType.error].toString() ||
					kbaResult.transactionStatus.toString() ==
						KBATransactionResultType[KBATransactionResultType.retryExceeded].toString()
				) {
					failureCallback();
				} else if (
					kbaResult.transactionStatus.toString() ==
					KBATransactionResultType[KBATransactionResultType.questions].toString()
				) {
					dispatch({
						type: actionTypes.FETCH_KBA_QUESTIONS,
						data: kbaResult,
					});

					successCallback(true);
				} else {
					successCallback();
				}

				loader.hide();
			})
			.catch(function (error: any) {
				console.log(error);
				loader.hide();
				logger.trackError(`taxpayer mobile validateClient failed with error ${error.message}`, { ClientId: clientid });
			});
	};

export const updateSpouseMail =
	(id: string, newMail: string, type: ClientType, callback?: () => void) => (dispatch: any) => {
		const clientid: string = TaxpayerHelper.getClientId();
		const data = { clientId: id, emailAddress: newMail, clientType: type };
		loader.show();
		initializeAxios(clientid)
			.putJson(data, 'api/Esign/UpdateEmailAddress/' + clientid)
			.then(function (response: any) {
				if (callback) {
					callback();
				}

				loader.hide();
			})
			.catch(function (error: any) {
				console.log(error);
				loader.hide();
				logger.trackError(`taxpayer mobile updateSpouseMail failed with error ${error.message}`, {
					ClientId: clientid,
				});
			});
	};

export const updateSpouseMobile =
	(id: string, mobile: string, type: ClientType, countryCode: string, ssn: string, callback?: () => void) =>
	(dispatch: any) => {
		const clientid: string = TaxpayerHelper.getClientId();
		const data = { clientId: id, mobileNumber: mobile, countryCode: countryCode, ssn: ssn };
		loader.show();
		initializeAxios(clientid)
			.putJson(data, 'api/Esign/UpdateMobileNumber/' + clientid)
			.then(function (response: any) {
				if (callback) {
					callback();
				}
				loader.hide();
			})
			.catch(function (error: any) {
				console.log(error);
				loader.hide();
				logger.trackError(`taxpayer mobile updateSpouseMobile failed with error ${error.message}`, {
					ClientId: clientid,
				});
			});
	};

export const updateDOB =
	(id: string, dob: string, step: boolean, callback?: (step: boolean) => void) => (dispatch: any) => {
		const clientid: string = TaxpayerHelper.getClientId();
		const data = { clientId: id, dateOfBirth: dob };

		loader.show();
		initializeAxios(clientid)
			.putJson(data, 'api/Esign/UpdateDateOfBirth/' + clientid)
			.then(function (response: any) {
				if (callback) {
					callback(step ? step : false);
				}

				loader.hide();
			})
			.catch(function (error: any) {
				console.log(error);
				loader.hide();
				logger.trackError(`taxpayer mobile updateDOB failed with error ${error.message}`, { ClientId: clientid });
			});
	};

export const updateDocumentSignatureSettingModel = (signatureType: number, callback: any) => (dispatch: any) => {
	const clientid: string = TaxpayerHelper.getClientId();
	loader.show();
	initializeAxios(clientid)
		.put('api/Esign/UpdateEsignFormSelectionAsync/' + signatureType + '/' + clientid)
		.then(function (response: any) {
			loader.hide();
			callback();
		})
		.catch(function (error: any) {
			console.log(error);
			loader.hide();
			logger.trackError(`taxpayer mobile updateDocumentSignatureSettingModel failed with error ${error.message}`, {
				ClientId: clientid,
			});
		});
};

export const fetchKBAStatus = (successCallback?: () => void) => (dispatch: any, getState: any) => {
	const state = getState();

	const clientid: string = TaxpayerHelper.getClientId();
	loader.show();
	initializeAxios(clientid)
		.get('api/Esign/GetKBAStatus/' + clientid)
		.then(function (response: any) {
			state.kbaData.isKBAPassed = response.data;

			dispatch({
				type: actionTypes.FETCH_KBA_STATUS,
				data: state.kbaData,
			});

			successCallback && successCallback();

			loader.hide();
		})
		.catch(function (error: any) {
			console.log(error);
			loader.hide();
			logger.trackError(`taxpayer mobile fetchKBAStatus failed with error ${error.message}`, { ClientId: clientid });
		});
};

export const fetchControlData = () => (dispatch: any) => {
	const clientid: string = TaxpayerHelper.getClientId();
	loader.show();
	initializeAxios(clientid)
		.get('api/Esign/GetDocumentSignatureDataAsync/' + clientid)
		.then(function (response: any) {
			const documentAdapter: IDocumentAdapter = DocumentAdapter.create();

			dispatch({
				type: actionTypes.FETCH_CONTROL_DATA,
				data: documentAdapter.convertToClientModel(response.data),
			});

			loader.hide();
		})
		.catch(function (error: any) {
			console.log(error);
			loader.hide();
			logger.trackError(`taxpayer mobile fetchControlData failed with error ${error.message}`, { ClientId: clientid });
		});
};

export const updateSignatureControlsData = (data: IDocument[]) => (dispatch: any) => {
	dispatch({
		type: actionTypes.FETCH_CONTROL_DATA,
		data: data,
	});
};

export const sign = (documentData: IDocument[], callback: (status: boolean) => void) => (dispatch: any) => {
	const clientId: string = TaxpayerHelper.getClientId();
	let signatureData: ISignerControlDataModal = DocumentAdapter.create().convertToServerModel(documentData);
	signatureData.clientGuid = clientId;
	loader.show();
	const timeZone = encodeURIComponent(moment.tz.guess(true));

	var retryOptions = { retry: 3, retryDelay: 3000 };

	initializeAxios(clientId)
		.postJson(
			signatureData,
			'api/Esign/v2/SignAsync/timeZone/' + clientId + '?userTimeZone=' + timeZone,
			false,
			retryOptions,
		)
		.then(function (response: any) {
			const { data } = response;
			callback(data);
			loader.hide();
		})
		.catch(function (error: any) {
			console.log(error);
			loader.hide();
			logger.trackError(`taxpayer mobile sign failed with error ${error.message}`, { ClientId: clientId });
		});
};

export const kbaReducer = (state: IKBATransactionResponse = initialKBAResponse, action: any) => {
	switch (action.type) {
		case actionTypes.FETCH_KBA_QUESTIONS:
			return action.data;
		case actionTypes.FETCH_KBA_STATUS:
			return action.data;
	}

	return state;
};

export const esignReducer = (state: IDocument[] = [], action: any) => {
	switch (action.type) {
		case actionTypes.FETCH_CONTROL_DATA:
			return action.data;
	}
	return state;
};

export const getSessionTimeOutSeconds =
	(clientId: string, callBack?: (sessionTimeout: number) => void) => (dispatch: any) => {
		initializeAxios(clientId)
			.get('api/Helper/GetSessionTimeOutSeconds/' + clientId)
			.then(function (response: AxiosResponse<number>) {
				callBack && callBack(response.data);
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: error.response ? error.response.statusText : ErrorMessages.GetSessionTimeOutSecondsError,
					statusType: StatusType.Error,
				});
			});
	};
