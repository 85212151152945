import * as React from 'react';
import { IDocumentReviewModel } from '../../../model/DocumentReviewModel/IDocumentReviewModel';
import { Body } from '../../common/StepLayout/Body';
import { StepLayout } from '../../common/StepLayout/StepLayout';
import { TaxReturns } from './Parts/TaxReturns';
import { ISignProcessInfo } from '../signflow/Helper/TaxpayerSignFlowHelper';
import { TelemetryLogger } from '../../../logger/AppInsights';
import TaxpayerHelper from '../signflow/Helper/TaxpayerHelper';

const logger = TelemetryLogger.getInstance();

interface ReviewTaxReturnProps {
	documentReviewModel: IDocumentReviewModel[];
	signProcessInfo: ISignProcessInfo;
	isPreviewMode: boolean;
}

export const ReviewTaxReturn: React.FunctionComponent<ReviewTaxReturnProps> = (props) => {
	React.useEffect(() => {
		logger.trackTrace(`Review Tax Return Page`, {
			PreviewMode: props.isPreviewMode,
			ClientId: TaxpayerHelper.getClientId(),
		});
	}, []);

	return (
		<React.Fragment>
			<StepLayout>
				<Body
					lg={10}
					md={12}
					sm={12}
					xs={12}
					title={'Tax Returns'}
					automationTestId={'5EB8ED34-27C6-4E27-9CAE-57DFE471A146'}>
					<TaxReturns documentReviewModel={props.documentReviewModel} />
				</Body>
			</StepLayout>
		</React.Fragment>
	);
};
