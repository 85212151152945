import { SignatureControlRole, SignatureControlTypes } from "../IGroup";

export class DocumentSignatureDataModel {

    id: number;
    name: string;
    fileLink: string;
    disabled: boolean;
    documentType: DocumentType;
    documentControls: ISignaturePageData[];

    constructor(id: number, name: string, fileLink: string, disabled: boolean, documentType: DocumentType, documentControls: ISignaturePageData[]) {

        this.id = id;
        this.name = name;
        this.fileLink = fileLink;
        this.disabled = disabled;
        this.documentType = documentType;
        this.documentControls = documentControls;
    }

    public static create(id: number, name: string, fileLink: string, disabled: boolean, documentType: DocumentType, documentControls: ISignaturePageData[]) {
        return new DocumentSignatureDataModel(id, name, fileLink, disabled, documentType, documentControls);
    }

    public static createNullObject() {
        return new DocumentSignatureDataModel(0, "", "", false, DocumentType.createNullObject(), []);
    }

}

export class DocumentType {

    id: number;
    name: string;

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }


    public static createNullObject() {
        return new DocumentType(0, "");
    }
}


export interface ISignaturePageData {
    pageNo: number;
    formName: string;
    bookmark: string;
	signingControls: ISignatureControlModel[]
}

export class SignaturePageData implements ISignaturePageData {
    pageNo: number;
    formName: string;
    bookmark: string;
	signingControls: ISignatureControlModel[]


	constructor(pageNo: number, formName: string, bookmark: string, signingControls: ISignatureControlModel[]) {
        this.pageNo = pageNo;
        this.bookmark = bookmark,
            this.formName = formName,
			this.signingControls = signingControls;
    }

	public static create(pageNo: number, formName: string, bookmark: string, signingControls: ISignatureControlModel[]) {
		return new SignaturePageData(pageNo, formName, bookmark, signingControls);
    }

}

export interface ISignatureControlModel {
    signer: string;
    type: SignatureControlTypes;
    role: SignatureControlRole;
    top: number;
    left: number;
    required: boolean;
    tooltip: string;
    controlData: IControlData
}

export class SignatureControlModel implements ISignatureControlModel {

    signer: string;
    type: SignatureControlTypes;
    top: number;
    left: number;
    role: SignatureControlRole;
    tooltip: string;
    required: boolean;
    controlData: any;

    constructor(signer: string,
        type: SignatureControlTypes,
        top: number,
        left: number,
        role: SignatureControlRole,
        tooltip: string,
        required: boolean,
        controlData: any) {

        this.signer = signer,
            this.type = type,
            this.top = top,
            this.left = left,
            this.role = role,
            this.tooltip = tooltip,
            this.required = required,
            this.controlData = controlData;

    }

    public static create(signer: string,
        type: SignatureControlTypes,
        top: number,
        left: number,
        role: SignatureControlRole,
        tooltip: string,
        required: boolean,
        controlData: any) {

        return new SignatureControlModel(signer, type, top, left, role, tooltip, required, controlData);

    }

}

export interface IControlData {

}

export interface ITextData extends IControlData {
    value: string
}

export class DocumentControlData {

    type: number;
    controlData?: IControlData;

    constructor(type: number, controlData?: IControlData) {
        this.type = type
        this.controlData = controlData;
    }

    public static create(type: number, controlData?: IControlData) {
        return new DocumentControlData(type, controlData);
    }

}