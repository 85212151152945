import { IDataService } from '../../../../core/services/dataAccess/abstraction/IDataService';
import { container } from '../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../core/startup/inversify/types';
import { FileUtilities } from '../../../../core/utilities/File/FileUtilities';
import { ILoader } from '../../../../core/utilities/ui/Loader';
import TaxpayerHelper from '../../signflow/Helper/TaxpayerHelper';
import { TelemetryLogger } from '../../../../logger/AppInsights';
import { ISnackbar } from '../../../../core/utilities/ui/Snackbar';

const dataService = container.get<IDataService>(TYPES.IDataService);
const fileUtilities = container.get<FileUtilities>(TYPES.IFileUtilities);
const loader = container.get<ILoader>(TYPES.ILoader);
const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

const logger = TelemetryLogger.getInstance();

export const downloadAllDocuments = (fileName: string) => (dispatch: any) => {
	loader.show();
	const clientId: string = TaxpayerHelper.getClientId();

	dataService
		.get('api/Download/GetAllDocumentZipSasAsync/' + clientId)
		.then(function (response: any) {
			if (response.data && response.data.length > 0) {
				fileUtilities.download(response.data);
				loader.hide();
			} else {
				return dataService
					.get('api/Download/GetAllDocumentsZipStreamAsync/' + clientId)
					.then(function (response: any) {
						fileUtilities.show(response.data, fileName);
						loader.hide();
					})
					.catch(function (error: any) {
						console.log(error);
						loader.hide();
						logger.trackError(
							`taxpayer mobile taxpayer mobile downloadAllDocuments failed with error ${error.message}`,
							{ ClientId: clientId },
						);
					});
			}
		})
		.catch(function (error: any) {
			console.log(error);
			loader.hide();
		});
};

export const getDownloadLink = (callback: (data: any) => void) => {
	const clientId: string = TaxpayerHelper.getClientId();

	dataService
		.get('api/Download/GetAllDocumentZipSasAsync/' + clientId)
		.then(function (response: any) {
			if (response.data && response.data.length > 0) {
				callback(response.data);
			}
		})
		.catch(function (error: any) {
			snackbar.show('failed to load download url !!');
			loader.hide();
			logger.trackError(`taxpayer mobile getAllDocumentZipSasAsync failed with error ${error.message}`, {
				ClientId: clientId,
			});
		});
};
