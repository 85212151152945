import * as React from 'react';
import HeaderComp from '../HeaderComp';
import { EngagementTypes } from './clientCoverActions';
import { MessageBox } from '../common/Notification/MessageBox';
import { Toaster } from '../common/Notification/MessageBox';
import { WarningModal } from './WarningModal';
import { PaymentOption, PaymentState } from '../../model/Common/Enums';

class CoverPage extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			showModal: false,
		};

		this.navigate = this.navigate.bind(this);
	}

	componentDidMount() {
		this.props.requestCoverClientData(this.props.match.params.id, this.handleSuccess, this.handleError);
		this.props.requestHeaderDetails(this.props.match.params.id);
	}

	handleSuccess = () => {
		this.toggleModalVisibility(true);
	};

	handleError = (errodCode) => {
		this.props.history.push('/Error/' + errodCode);
	};

	navigate() {
		let clientId: string = this.props.match.params.id;
		if (
			this.props.initialCoverState.engagementType === EngagementTypes.E1040 ||
			this.props.initialCoverState.engagementType === EngagementTypes.E1040NR
		) {
			this.props.history.push(`/Authentication/SSN/${clientId}`);

			if (this.props.initialCoverState.mobileVerification) {
				this.props.setMobileNo(
					this.props.initialCoverState.mobileVerification.mobileNumber,
					this.props.initialCoverState.mobileVerification.countryCode,
				);
			}
		} else {
			let mfaSettings = this.props.initialCoverState.mfaSettings;
			if (
				mfaSettings.iSTPMfaEnabled === true && this.props.initialCoverState.mobileVerification
					? this.props.initialCoverState.mobileVerification.mobileNumber
					: false && this.props.initialCoverState.mobileVerification
					? this.props.initialCoverState.mobileVerification.countryCode
					: false
			) {
				this.props.setMobileNo(
					this.props.initialCoverState.mobileVerification.mobileNumber,
					this.props.initialCoverState.mobileVerification.countryCode,
				);
				this.props.history.push(`/Authentication/MobileOTP/${clientId}`);
			} else {
				this.props.history.push(`/Authentication/OTP/${clientId}`);
			}
		}
	}

	private toggleModalVisibility = (visible: boolean) => {
		this.setState({ showModal: visible });
	};

	private closeTab = () => {
		window.opener = null;
		window.open('', '_self');
		window.close();
	};

	private checkPaymentStatus = () => {
		const { isPaymentRequired } = this.props.initialCoverState;

		if (isPaymentRequired) {
			return true;
		}

		return false;
	};

	render() {
		let clientData = this.props.initialCoverState;
		let headerData = this.props.headerInfoState;

		let headerBackColor = 'green';
		let headerTextColor = 'white';

		if (this.props.headerInfoState.brandingSettings) {
			headerBackColor = this.props.headerInfoState.brandingSettings.coverPageSetting.bgColorCode;
			headerTextColor = this.props.headerInfoState.brandingSettings.coverPageSetting.foreColorCode;
		}

		return (
			<>
				<Toaster />

				{clientData.loaderState === true ? (
					''
				) : (
					<>
						{this.state.showModal && (
							<WarningModal
								showModal={this.state.showModal}
								toggleModalVisibility={this.toggleModalVisibility}
								clientName={this.props.initialCoverState?.clientName}
								taxYear={this.props.initialCoverState?.taxYear}
							/>
						)}
						<HeaderComp
							companyLogo={headerData.companyWhiteLogoPath}
							companyName={headerData.companyName}
							headerTextColor={headerTextColor}
							headerBackColor={headerBackColor}
						/>
						<div className='welcome-page-mobile'>
							<div
								className='col-lg-12 col-md-12 col-sm-12 col-xs-12 page-parts-container1'
								style={{ height: '25%', minHeight: '150px', marginBottom: '20px' }}>
								<h2 data-test-auto='JY3NUDQEG0D471NH0XPU52468D'>
									<b>{clientData.taxYear !== null ? clientData.taxYear : ''} Tax Return</b>
								</h2>
								<br />
								<h5 data-test-auto='W4GRMP71REY4Z1DV1YG7L6XDLH'>
									{clientData.clientName ? clientData.clientName : ''}
								</h5>
							</div>

							<div
								className='col-lg-12 col-md-12 col-sm-12 col-xs-12 page-parts-container1'
								style={{ minHeight: '50%', marginBottom: '20px' }}>
								<div>
									<h6 data-test-auto='DKL160X9V0A45ATKDN4REGDFE0'>
										<b>Prepared by</b>
									</h6>
								</div>
								<div className='company-info-container'>
									{clientData.logoPath.length !== 0 ? (
										<img
											data-test-auto='VM1Y507X3KAMT985AC3UCPACMW'
											className='prepared-by-img'
											src={clientData.logoPath}
										/>
									) : (
										<h3 data-test-auto='R3YCTEA0QLM4T6Z3495P4NJ1DR'>
											<b>{clientData.companyName}</b>
										</h3>
									)}
								</div>

								<div className='company-address-container'>
									{clientData.logoPath.length !== 0 ? (
										<>
											<span data-test-auto='VM1Y507X3KAMT985AC3UCPACMW'>{clientData.companyName}</span>
											<br />
										</>
									) : (
										''
									)}
									<h6 data-test-auto='U0D2DQTWM3UMX3DEVJCLGXRREM'>
										<b>
											{headerData.companyName ? headerData.companyName?.concat(' ') : ' '}
											<br />
											{clientData.contactAddress ? clientData.contactAddress.streetAddress2?.concat(' ') : ' '}
											{clientData.contactAddress ? clientData.contactAddress.streetAddress3 : ' '}
										</b>
									</h6>
									<h6 data-test-auto='UX7U2AGQG054XAJ2KV54GX9H54'>
										<b>
											{clientData.contactAddress ? clientData.contactAddress.city?.concat(', ') : ' '}
											{clientData.contactAddress ? clientData.contactAddress.state?.concat(' ') : ' '}
											{clientData.contactAddress ? clientData.contactAddress.zip : ' '}
										</b>
									</h6>
								</div>
							</div>

							<button
								className='col-lg-12 col-md-12 col-sm-12 col-xs-12 welcome-page-content-box '
								onClick={this.checkPaymentStatus() ? this.closeTab : this.navigate}>
								<a
									data-test-auto='JY3NUDQEG0D471NH0XPU52468D'
									className='tax-year-header'>
									{this.checkPaymentStatus() ? 'Close' : 'Get Started'}
								</a>
							</button>
						</div>
					</>
				)}
			</>
		);
	}
}

export default CoverPage;
