import {
	ApplicationInsights,
	ITelemetryItem,
	DistributedTracingModes,
	SeverityLevel,
	IEventTelemetry,
	Util,
	ICustomProperties,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { initializeAxios } from '../core/services/dataAccess/DataService.Axios';
import { AxiosResponse } from 'axios';
import { ILogger } from './Logger';
import { KNOWN_EXCEPTION_MESSAGES } from '../common/constants/Constants';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
let profileData: any;

export class TelemetryLogger implements ILogger {
	private static instance: TelemetryLogger;
	private static appInsights: ApplicationInsights;
	private static pageViewRefTime: any;

	private constructor() {
		TelemetryLogger.appInsights = appInsights;
	}

	public static getInstance(): TelemetryLogger {
		if (!TelemetryLogger.instance) {
			this.instance = new TelemetryLogger();
		}
		return TelemetryLogger.instance;
	}

	public trackPageView = (pageName: string, customProperties?: ICustomProperties) => {
		TelemetryLogger.appInsights.context.telemetryTrace.name = pageName;
		TelemetryLogger.appInsights.context.telemetryTrace.traceID = Util.generateW3CId();
		TelemetryLogger.appInsights.trackPageView({
			name: pageName,
			properties: { duration: new Date().getTime() - TelemetryLogger.pageViewRefTime, ...customProperties },
		});
		TelemetryLogger.pageViewRefTime = new Date().getTime();
	};

	public trackTrace = (message: string, customProperties?: ICustomProperties) => {
		TelemetryLogger.appInsights.trackTrace(
			{
				message: message,
				severityLevel: SeverityLevel.Information,
			},
			customProperties,
		);
	};

	public trackWarning = (message: string, customProperties?: ICustomProperties) => {
		TelemetryLogger.appInsights.trackTrace(
			{
				message: message,
				severityLevel: SeverityLevel.Warning,
			},
			customProperties,
		);
	};

	public trackError = (message: string, customProperties?: ICustomProperties) => {
		TelemetryLogger.appInsights.trackException(
			{
				exception: new Error(message),
			},
			customProperties,
		);
	};

	public trackEvent = (traceEvent: IEventTelemetry, customProperties?: ICustomProperties) => {
		TelemetryLogger.appInsights.trackEvent(traceEvent, customProperties);
	};
}

const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: '',
		disableInstrumentationKeyValidation: true,
		extensions: [reactPlugin],
		disableFetchTracking: false,
		disableAjaxTracking: false,
		enableCorsCorrelation: true,
		distributedTracingMode: DistributedTracingModes.W3C,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true,
		disableCorrelationHeaders: false,
		enableAutoRouteTracking: true,
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory },
		},
	},
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
	envelope.tags = envelope.tags || [];
	envelope.tags.push({ 'ai.cloud.role': 'SSR-TaxpayerMobile-UI' });

	if (profileData !== undefined) {
		envelope.tags.push({ 'ai.user.accountId': profileData.companyId });
		envelope.tags.push({ 'ai.user.id': profileData.userId });
	}

	var telemetryItem = envelope.baseData;

	if (envelope.baseType === 'ExceptionData') {
		const hasExceptions = telemetryItem && telemetryItem.exceptions && telemetryItem.exceptions.length > 0;
		const exceptionMessage = hasExceptions ? telemetryItem?.exceptions[0].message : '';

		const isErrorExists = exceptionMessage && KNOWN_EXCEPTION_MESSAGES.some((msg) => exceptionMessage.includes(msg));

		if (isErrorExists) {
			return false;
		}
	}

	if (telemetryItem !== undefined && profileData !== undefined) {
		telemetryItem.properties = telemetryItem.properties || {};
		telemetryItem.properties['UserId'] = profileData.userId;
		telemetryItem.properties['CompanyId'] = profileData.companyId;
		telemetryItem.properties['CompanyName'] = profileData.companyName;
		telemetryItem.properties['DocumentGuid'] = profileData.documentGuid;
		telemetryItem.properties['DocumentId'] = profileData.documentId;
		telemetryItem.properties['Origin'] = 'SSR-TaxpayerMobile-UI';
	}
});

const initializeAppInsights = (clientId: string) => {
	if (clientId && (profileData === undefined || profileData.userId != clientId)) {
		initializeAxios()
			.get('api/ApplicationInsight/BasicDetails/' + clientId)
			.then(function (response: AxiosResponse<any>) {
				profileData = response.data;
				if (!profileData.userId) {
					profileData.userId = clientId;
				}
				appInsights.config.instrumentationKey = profileData.instrumentationKey;
			})
			.catch((error: any) => {
				console.log(error);
			});
	}
};

export { initializeAppInsights, reactPlugin, appInsights };
