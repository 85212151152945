import { injectable } from "inversify";
import * as bootbox from 'bootbox';

export interface IDialogBox {
    alert(title: string, message: string, icon?: string, callback?: () => void): void;
    confirm(title: string, message: string, cancel: string, confirm: string, callback?: (result: boolean) => void): void;
    confirmCustom(title: string, message: string, rightButtonText: string, leftButtonText: string, callback?: (result: boolean) => void): void;
}

@injectable()
export class DialogBox implements IDialogBox {
    
    public alert(title: string, message: string, icon?: string, callback?: () => void): void {

        if (title) {
            bootbox.confirm({
                title: `<i class="text-secondary"></i><span class="bootbox-title-icon" aria-hidden="true">${icon}</span><b>${title}</b>`,
                message: message,
                buttons: {
                    cancel: {
                        label: '<i class="glyphicon glyphicon-remove"></i> cancel',
                        className: 'btn-white btn-default rounded d-none'
                    },
                    confirm: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn-primary rounded'
                    }
                },
                callback: (result: boolean) => {
                    callback && callback();
                }
            });
        }
        else {
            bootbox.confirm({               
                message: message,
                buttons: {
                    cancel: {
                        label: '<i class="glyphicon glyphicon-remove"></i> cancel',
                        className: 'btn-white btn-default rounded d-none'
                    },
                    confirm: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn-primary rounded'
                    }
                },
                callback: (result: boolean) => {
                    callback && callback();
                }
            });
        }


    }

    public confirm(title: string, message: string, cancel: string, confirm: string, callback?: (result: boolean) => void): void {

        bootbox.confirm({
            title: '<i class="text-secondary"></i>' + title,
            message: message,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> ' + cancel,
                    className: 'btn-white btn-default rounded'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i>' + confirm,
                    className: 'btn-primary rounded'
                }
            },
            callback: (result: boolean) => {
                if (callback) {
                    callback(result);
                }
            }
        });
    }


    public confirmCustom(title: string, message: string, rightButtonText: string, leftButtonText: string, callback?: (result: boolean) => void): void {

        bootbox.confirm({
            title: '<strong><i class="text-secondary"></i>' + title + '</strong>',
            message: message,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> ' + leftButtonText,
                    className: 'btn-white btn-default btn-rounded cancel'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i>' + rightButtonText,
                    className: 'btn-primary btn-rounded done'
                }
            },
            callback: (result: boolean) => {
                if (callback) {
                    callback(result);
                }
            }
        });
    }
}