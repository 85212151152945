import React, { useEffect, useState } from 'react';
import { ITaxReturn } from '../../../model/TaxReturn/TaxReturn';
import { match } from 'react-router-dom';
import { IHeaderInfoModel } from '../../../model/IHeaderInfoModel';
import { SignCompleted } from './Parts/SignCompleted';
import { TelemetryLogger } from '../../../logger/AppInsights';
import { IDelegateeInfo, IDelegateeSignerDetails } from '../../../model/IDelegateeSignerModel';
import { History } from 'history';
import { getDownloadLink } from './Action/DownloadableDocumentActions';
import { IClientProcessModel, SignProcessSteps } from '../signflow/Helper/TaxpayerSignFlowHelper';
import TaxpayerHelper from '../signflow/Helper/TaxpayerHelper';

export const logger = TelemetryLogger.getInstance();

export interface SignCompletedFlowProps {
	taxReturn: ITaxReturn;
	fetchHeaderInfo(): void;
	fetchTaxDocument(callback?: (taxReturn: ITaxReturn) => void): void;
	headerInfo: IHeaderInfoModel;
	requestTaxClientDelegateeDetails(callback?: () => void): void;
	delegateeDetails: IDelegateeInfo;
	match: match;
	history: History;
	updateDelegateeSignerDetails(delegatee: IDelegateeSignerDetails, callback: () => void): void;
	cancelDelegation(remarks: string, taxYear: number, callback: () => void): void;
	resetLastVisitedSteps(callback: () => void): void;
	fetchCurrentStepAndUserRole(successCallback?: (clientProcess: IClientProcessModel) => void): void;
}

export const SignCompletedFlow: React.FC<SignCompletedFlowProps> = (props) => {
	const { taxReturn, headerInfo, delegateeDetails } = props;

	const param: any = props.match.params;
	window.Variables.clientId = param.id;

	const [downloadLink, setDownloadLink] = useState<string>('');
	const [isDelegateeAssigned, setIsDelegateeAssigned] = useState(false);
	const [isSignCompleted, setIsSignCompleted] = useState(false);

	const handleRedirect = (clientProcessState: IClientProcessModel) => {
		const clientId: string = TaxpayerHelper.getClientId();
		let index: number = -1;

		if (clientProcessState) {
			if (clientProcessState.currentstep.visitedSteps) {
				index = clientProcessState.currentstep.visitedSteps.findIndex(
					(x: SignProcessSteps) => x == SignProcessSteps.SignerProcessComplete,
				);
			}
			if (!clientProcessState.isSigned && index === -1 && !clientProcessState.isDelegated) {
				props.history.push(`/taxpayer/${clientId}`);
			}
			if (clientProcessState.isSigned) {
				setIsSignCompleted(true);
			}
			if (clientProcessState.isDelegated) {
				setIsDelegateeAssigned(true);
			}
		}
	};

	const getSignProcessStatus = () => {
		props.fetchCurrentStepAndUserRole(handleRedirect);
	};

	useEffect(() => {
		const { fetchTaxDocument, fetchHeaderInfo, requestTaxClientDelegateeDetails } = props;
		fetchHeaderInfo();
		fetchTaxDocument();
		getSignProcessStatus();
		requestTaxClientDelegateeDetails();
		getDownloadLink((data: any) => setDownloadLink(data));
		logger.trackPageView('SignFlow Completed Page View');
	}, []);

	return (
		<React.Fragment>
			<SignCompleted
				match={props.match}
				history={props.history}
				enagementType={taxReturn.engagementType.toString().substr(1)}
				headerInfo={headerInfo}
				downloadLink={downloadLink}
				delegateeDetails={delegateeDetails}
				taxReturn={props.taxReturn}
				updateDelegateeSignerDetails={props.updateDelegateeSignerDetails}
				cancelDelegation={props.cancelDelegation}
				resetLastVisitedSteps={props.resetLastVisitedSteps}
				isDelegateeAssigned={isDelegateeAssigned}
				isSignCompleted={isSignCompleted}
				fetchTaxDocument={props.fetchTaxDocument}
			/>
		</React.Fragment>
	);
};
