import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from '../../common/constants/actionTypes';
import axios from 'axios';
import { MessageBox } from '../common/Notification/MessageBox';
import { SSNPageConstants, DisplayError } from '../../common/constants/Constants';
import { container } from '../../core/startup/inversify/inversify.config';
import { TYPES } from '../../core/startup/inversify/types';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { TelemetryLogger } from '../../logger/AppInsights';

const logger = TelemetryLogger.getInstance();

export enum TaxpayerAuthenticationType {
	None = 0,
	Last4ofSSN = 1,
	First4ofSSN = 2,
}

export enum TaxpayerAuthenticationType1 {
	None = 'None',
	Last4ofSSN = 'Last4ofSSN',
	First4ofSSN = 'First4ofSSN',
}

export enum ClientTypes {
	Undefied = 0,
	Taxpayer = 1,
	Spouse = 2,
	Partner = 3,
	PartnerShip = 4,
	ShareHolder = 5,
	CcRecipient = 6,
	Delegatee = 7,
	Controller = 8,
}

export enum LinkValidationResult {
	None,
	Success,
	LockedBySSN,
	LockedByOTP,
}

export interface ISSNAuthModel {
	taxpayerAuthentication: TaxpayerAuthenticationType | null;
	clientType: ClientTypes | null;
	clientName: any;
	linkValidationResult: LinkValidationResult | null;
	SSN: any;
	data: any;
	ReturnUrl: string;
	RedirectUrl: string;
	loading: boolean;
}

export const SSNData: ISSNAuthModel = {
	taxpayerAuthentication: null,
	clientType: null,
	clientName: '',
	linkValidationResult: null,
	SSN: '',
	ReturnUrl: '',
	data: '',
	RedirectUrl: '',
	loading: false,
};

interface IClientResponse {
	IsSuccess: boolean;
	ErrorCode: string;
	ErrorDescription: string;
	Data: any;
}

export const actionCreators = {
	requestSSNData:
		(clientId?: string, errorCallBack?: (errorCode:string) => void): any =>
		(dispatch: any) => {
			dispatch({ type: actionTypes.FETCH_SSN_DETAILS });
			if (initializeAxios(clientId).getJsonValue !== undefined) {
				return initializeAxios(clientId)
					.getJsonValue('api/Coverpage/SSN/' + clientId)
					.then(function (response: AxiosResponse<any>) {
						let result = response.data;
						if (result.isSuccess) {
							dispatch({
								type: actionTypes.RECEIVE_SSN_DETAILS,
								data: result.data,
							});
						} else {
							let errorDesc = result.errorDescription;
							let errorCode = result.errorCode;
							logger.trackWarning(
								`Taxpayer Mobile requestSSNData failed with the error ${errorDesc} for client guid ${clientId}`,
								{ ClientId: clientId },
							);
							if (errorCode && DisplayError.includes(errorCode)) {
								dispatch({
									type: actionTypes.SET_ERROR_MSG,
									errorMessage: errorDesc,
									errorCode: errorCode,
								});
								localStorage.setItem(errorCode, errorDesc);
								errorCallBack && errorCallBack(errorCode);
							} else {
								if (typeof errorDesc === 'string') {
									MessageBox.Error(errorDesc);
								}
							}
						}
					})
					.catch((e: any) => {
						logger.trackError(
							`Taxpayer Mobile requestSSNData failed with the error ${e.message} for client guid ${clientId}`,
							{ ClientId: clientId },
						);
						MessageBox.Error(SSNPageConstants.ErrorMessage.serverError);
					});
			}
		},
	verifySSN:
		(SSN: string, clientId: string, navigatePath: (target: string) => void, errorCallBack: (errorCode:string) => void): any =>
		(dispatch: any, getState: any) => {
			let state = getState().SSNPageReducer;

			let tempData = {
				TaxpayerAuthentication: state.taxpayerAuthentication,
				ClientType: state.clientType,
				ClientName: state.clientName,
				LinkValidationResult: state.linkValidationResult,
				SSN: SSN,
				ReturnUrl: state.ReturnUrl,
				Data: state.data,
				RedirectUrl: state.RedirectUrl,
			};

			if (initializeAxios(clientId).postJsonValue !== undefined) {
				return initializeAxios(clientId)
					.postJsonValue('api/Coverpage/SSN/Validate/' + clientId, tempData)
					.then(function (response: any) {
						let result = response.data;
						if (result.isSuccess) {
							if (result.data === 'OTP') {
								navigatePath('OTP');
							} else {
								navigatePath('MobileOTP');
							}
						} else {
							let errorDesc = result.errorDescription;
							let errorCode = result.errorCode;
							logger.trackWarning(
								`Taxpayer Mobile verifySSN failed with the error ${errorDesc} for client guid ${clientId}`,
								{ ClientId: clientId },
							);
							if (errorCode && DisplayError.includes(errorCode)) {
								dispatch({
									type: actionTypes.SET_ERROR_MSG,
									errorMessage: errorDesc,
									errorCode: errorCode,
								});

								localStorage.setItem(errorCode, errorDesc);
								errorCallBack && errorCallBack(errorCode);
							} else {
								if (typeof errorDesc === 'string') {
									MessageBox.Error(errorDesc);
								}
							}
						}
					})
					.catch(function (error: any) {
						logger.trackError(
							`Taxpayer Mobile verifySSN failed with the error ${error.message} for client guid ${clientId}`,
							{ ClientId: clientId },
						);
						MessageBox.Error(SSNPageConstants.ErrorMessage.serverError);
					});
			}
		},
};

export const reducer: Reducer<typeof SSNData> = (state: typeof SSNData = SSNData, incomingAction: Action) => {
	const action = incomingAction as any;
	const currentState = Object.assign({}, state);
	console.log('in reducers', action);
	switch (action.type) {
		case actionTypes.FETCH_SSN_DETAILS:
			return { ...currentState, loading: true };
		case actionTypes.RECEIVE_SSN_DETAILS:
			let data = action.data;
			/*add the data getting back from the api*/
			currentState.taxpayerAuthentication = data.taxpayerAuthentication;
			currentState.clientType = data.clientType;
			currentState.clientName = data.clientName;
			currentState.linkValidationResult = data.linkValidationResult;
			currentState.SSN = data.SSN;
			currentState.data = data.data;
			currentState.RedirectUrl = data.RedirectUrl;
			currentState.loading = false;

			return { ...currentState };
		default:
			return currentState;
	}
};
