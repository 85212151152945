import { ClientType, DefaultSenderInfoType, DocumentStatus, EngagementTypeS, KBAMode, SignatureStatus, SignatureType, MailEvent, ClientTypesNumber } from "../Common/Enums";
import { DeliveryMode, IDocumentSettings, PaperReturnDeliveryType } from "../DocumentSettings";
import { IGroup } from "../IGroup";
import { ClientTypes, ITaxClient, ITaxpayer } from "../ITaxClient";
import { initialUserModel, IUserModel } from "../IUserModel";
import { IRefundInfo } from "../SummaryModel/SummaryModel";

export interface ITaxReturn {
    formGroups: IGroup[];
    documentSettings: IDocumentSettings;
    id: number;
    clientId: string;
    clientType: ClientType;
    documentStatus: DocumentStatus;
    uploadedOn: Date;
    partnerId: number;
    partner: IUserModel;
    assignToUserName: IUserModel;
    deliveredByUser: IUserModel;
    signatureStatus: SignatureStatus;
    createdBy: number;
    engagementType: EngagementTypeS;
    taxYear: number;
    assignTo: number;
    assignedUser: IUserModel;
    lockedBy: number;
    invoiceAmount: number;
    deliveredOn: Date;
    attachments: IAttachment[];
    documentGuid: string;
    isModified: boolean;
    isfullyLoaded: boolean;
    refundInfo: IRefundInfo[];
    documentAccess: IDocumentAccess;
    signedDetails: ISignerModel[];
    accessCode: IAccessCode[];
    downloadableEfileForms: IDownloadableDocuments[],
    downloadableDocuments: IDownloadableDocuments[],
    taxCaddyLookupResultModel: TaxCaddyLookupResultModel,
    taxSoftware: TaxSoftwareType,
    deliveredBy?: number
};

export interface IAttachment {
    fileExtension?: string,
    progressBar?: number,
    fileSize: string,
    status?: string,
    documentId: number,
    fileName: string,
    uploadedTime: Date,
    uploadedBy: number,
    instruction: string,
    id: number,
    uploadedUser: string,
    sasGuid?: string,
    url?: string,
    isDeleted: boolean,
    number?: number,
    selectedInstructionId: number,
}

export interface IDocumentAccess {
    documentId: number;
    userId: number[];
}

export interface ISignerModel {
    documentId: number;
    signer: string;
    isSigned: boolean;
    signedOn: Date;
    signerType: ClientTypesNumber;
    order: number;
    name: string;
    isDeceased: boolean;
    clientGuid: string;
}

export interface IAccessCode {
    name: string,
    clientGuid: string,
    documentId: number,
    clientType: ClientTypes,
    createdOn: Date,
    otp: string,
    retryCount: number
}

export interface IDownloadableDocuments {
    documentId: number;
    groupId: FormType;
    fileName: string;
    shareholderId: number;
    documentGuid: string;
    taxYear: number;
    createdOn: Date;
    signedDocumentId: number;
}

export interface IDocumentEventData {
    fileName: string;
    ipAddress: string;
    clientType: ClientTypes;
    assignedValue: string;
    mailEventId: MailEvent;
    recipientInfo: IDocumentTransactionUser;
    token: string;
    dob: Date;
}

export interface IDocumentTransactionUser {
    firstName: string;
    lastName: string;
    emailAddress: string;
}

export enum FormType {
    None = 0,
    Transmittals = 1,
    EFile,
    Vouchers,
    TaxReturns,
    Invoice,
    Deleted,
    K1
}

export interface TaxCaddyLookupResultModel {
    isSuccess: boolean;
    result: string;
    resultObjectModel: TaxCaddyLookupResultObjectModel;
}

export interface TaxCaddyLookupResultObjectModel {
    taxPayerUniqueId: number;
    taxpayerName: string;
    emailAddress: string;
    ssn: string;
    address: string;
    taxClientId: string;
    taxYear: number[];
}

export enum TaxSoftwareType {
    ProSystems = "ProSystems",
    UltraTax = "UltraTax",
    GoSystem = "GoSystem",
    UltraTaxM18 = "UltraTaxM18",
    Intuit = "Intuit",
    Drake = "Drake"
}

export interface IIndividualTaxReturn extends ITaxReturn {
    taxpayer: ITaxpayer;
}

export interface IMarriedJointTaxReturn extends IIndividualTaxReturn {
    spouse: ITaxpayer;
}

export interface ICorporateTaxReturn extends ITaxReturn {
    partnership: ITaxClient;
}

export function isIndividual(arg: any): arg is IIndividualTaxReturn {
    return arg.taxpayer !== undefined
        && (arg.spouse === undefined || arg.spouse === null)
        && arg.partnership === undefined;
}

export function isMutual(arg: any): arg is IMarriedJointTaxReturn {
    return arg.taxpayer !== undefined
        && arg.spouse !== undefined
        && arg.partnership === undefined;
}

export function isPartnership(arg: any): arg is ICorporateTaxReturn {
    return arg && arg.partnership !== undefined
        && arg.taxpayer === undefined
        && arg.spouse === undefined;
}

export function ToIndividual(arg: any) {
    return arg as IIndividualTaxReturn;
}

export function ToMutual(arg: any) {
    return arg as IMarriedJointTaxReturn;
}

export function ToPartnership(arg: any) {
    return arg as ICorporateTaxReturn;
}

export function dynamicCast(arg: ITaxReturn) {
    if (isIndividual(arg)) {
        return arg as IIndividualTaxReturn;
    }
    if (isPartnership(arg)) {
        return arg as ICorporateTaxReturn;
    }
    if (isMutual(arg)) {
        return arg as IMarriedJointTaxReturn;
    }
    return arg;
}

export const initialTaxReturn: Readonly<ITaxReturn> = {
    formGroups: [],
    documentSettings: {
        documentId: 0,
        deliverySettings: {
            deliverTo: ClientTypes.Undefined,
            contactPerson: 0,
            preparerMessage: {
                id: 0,
                name: "",
                body: "",
                isAllowToEdit: false
            },
            sender: {
                senderType: DefaultSenderInfoType.None,
                senderId: 0
            },
            notifyUser: 0,
            manualAddressId: 0,
            isDirectDeliveryToTaxCaddy: false,
            deliveryMode: DeliveryMode.ElectronicFiled,
            paperReturnSettings: {
                documentOrder: [],
                deliveryType: PaperReturnDeliveryType.MultipleFiles,
                isNotifyUserEnabled: false
            }
        },
        documentDisplaySetting: {
            isSendWithoutInvoice: false,
            isEnableInvoice: false,
            customColumn: {
                id: 0,
                value: "",
                customColumn: 0,
                inUseCount: 0
            }
        },
        documentSignatureSetting: {
            signatureFormSelectionType: SignatureType.None,
            knowledgeBasedAuthentication: false,
            signatureStampUser: initialUserModel,
            kbaMode: KBAMode.Test,
            enableSignerDeligation: false,
            enableDelegateeKbaAuthentication: false
        },
        documentNotificationSetting: {
            paymentVoucherNotificationSettingsModel: {
                enablePaymentVoucherReminder: false,
                noOfDaysForVoucherReminder: 0
            },
            signingReminderNotificationSettingsModel: {
                enableSigningReminder: false,
                noOfDaysForSigningReminder: 0
            },
            notifyUser: initialUserModel
        },
        documentRetentionSetting: {
            retentionPeriod: 2520
        },
        isModified: false,
    },
    id: 0,
    clientId: "",
    clientType: ClientType.Undefined,
    documentStatus: DocumentStatus.None,
    uploadedOn: new Date(2020, 7, 23),
    partnerId: 0,
    partner: initialUserModel,
    assignToUserName: initialUserModel,
    deliveredByUser: initialUserModel,
    signatureStatus: SignatureStatus.None,
    createdBy: 0,
    engagementType: EngagementTypeS.None,
    taxYear: 0,
    assignTo: 0,
    assignedUser: initialUserModel,
    lockedBy: 0,
    invoiceAmount: 0,
    deliveredOn: new Date(2020, 7, 26),
    attachments: [],
    documentGuid: "",
    isModified: false,
    isfullyLoaded: false,
    refundInfo: [],
    documentAccess: {
        documentId: 0,
        userId: []
    },
    signedDetails: [],
    accessCode: [],
    downloadableEfileForms: [],
    downloadableDocuments: [],
    taxCaddyLookupResultModel: {
        isSuccess: false,
        result: '',
        resultObjectModel: {
            taxPayerUniqueId: 0,
            taxpayerName: '',
            emailAddress: '',
            ssn: '',
            address: '',
            taxClientId: '',
            taxYear: []
        }
    },
    taxSoftware: TaxSoftwareType.ProSystems
}


export function getClientGuid(taxReturn: ITaxReturn) {
    if (isPartnership(taxReturn)) { return taxReturn.partnership.clientGuid }
    if (isMutual(taxReturn)) { return taxReturn.taxpayer.clientGuid }
    if (isIndividual(taxReturn)) { return taxReturn.taxpayer.clientGuid }
    return "";
}
