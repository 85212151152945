import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { fetchPreparerMessage } from '../PreparerMessage/Actions/PreparerMessageActions';
import { fetchTaxingAuthorities } from '../TaxSummary/Action/TaxingAuthorityActions';
import { fetchSummaryDetails } from '../TaxSummary/Action/TaxSummaryActions';
import { fetchDocumentReviewDetails } from '../Review/Actions/ReviewActions';
import { TaxpayerSignFlow } from './TaxpayerSignFlow';
import { ClientType } from '../../../model/Common/Enums';
import {
	authenticateClient,
	fetchControlData,
	sign,
	updateDOB,
	updateSignatureControlsData,
	updateSpouseMail,
	updateSpouseMobile,
	validateClient,
	updateDocumentSignatureSettingModel,
	fetchKBAStatus,
} from '../Sign/Esign/Action/EsignActions';
import {
	fetchCurrentStepAndUserRole,
	fetchHeaderInfo,
	fetchTaxDocument,
	updateLastVisitedStep,
	updateTaxDocument,
	fetchTaxDocumentClientType,
	resetLastVisitedSteps,
} from './Action/TaxpayerSignFlowActions';
import { IKBAAnswers } from '../../../model/KBA/KBA';
import { IDocument } from '../../../model/Esign/Document';
import { downloadAllDocuments } from '../SignCompleted/Action/DownloadableDocumentActions';
import { IClientProcessModel, SignProcessSteps } from './Helper/TaxpayerSignFlowHelper';
import { IDelegateeSignerDetails } from '../../../model/IDelegateeSignerModel';
import { assignToDelegatee } from '../Delegatee/Actions/DelegateeActions';
import { ITaxReturn } from 'src/model/TaxReturn/TaxReturn';

const mapStateToProps = (state: ApplicationState) => ({
	documentSettings: state.documentSettings,
	summaryModel: state.summaryModel,
	taxingAuthorities: state.taxingAuthorities,
	documentReviewModel: state.documentReviewModel,
	taxReturn: state.taxReturn,
	kbaData: state.kbaData,
	signatureControlsData: state.signatureControlsData,
	headerInfo: state.headerInfo,
	signProcessInfo: state.signProcessInfo,
	isKBAPassed: state.isKBAPassed,
	signerData: state.signerData,
});

const mapDispatchToProps = (dispatch: any) => ({
	fetchPreparerMessage: () => dispatch(fetchPreparerMessage()),
	fetchSummaryDetails: () => dispatch(fetchSummaryDetails()),
	fetchTaxingAuthorities: () => dispatch(fetchTaxingAuthorities()),
	fetchDocumentReviewDetails: () => dispatch(fetchDocumentReviewDetails()),

	fetchTaxDocument: (successCallback?: (taxReturn: ITaxReturn) => void) => dispatch(fetchTaxDocument(successCallback)),
	fetchTaxDocumentClientType: () => dispatch(fetchTaxDocumentClientType()),
	fetchHeaderInfo: (successCallback?: () => void) => dispatch(fetchHeaderInfo(successCallback)),

	updateSpouseMail: (id: string, newMail: string, type: ClientType, callback?: () => void) =>
		dispatch(updateSpouseMail(id, newMail, type, callback)),
	updateSpouseMobile: (
		id: string,
		mobile: string,
		type: ClientType,
		countryCode: string,
		ssn: string,
		callback?: () => void,
	) => dispatch(updateSpouseMobile(id, mobile, type, countryCode, ssn, callback)),

	updateDOB: (id: string, dob: string, step: boolean, callback?: (step: boolean) => void) =>
		dispatch(updateDOB(id, dob, step, callback)),

	authenticateClient: (failureCallback: () => void) => dispatch(authenticateClient(failureCallback)),
	validateClient: (
		data: IKBAAnswers,
		successCallback: (isNextQuestionSet?: boolean) => void,
		failureCallback: () => void,
	) => dispatch(validateClient(data, successCallback, failureCallback)),
	updateDocumentSignatureSettingModel: (signatureType: number, callback: any) =>
		dispatch(updateDocumentSignatureSettingModel(signatureType, callback)),

	fetchKBAStatus: (successCallback?: () => void) => dispatch(fetchKBAStatus(successCallback)),

	fetchControlData: () => dispatch(fetchControlData()),
	updateSignatureControlsData: (data: IDocument[]) => dispatch(updateSignatureControlsData(data)),
	sign: (documentData: IDocument[], callback: (status: boolean) => void) => dispatch(sign(documentData, callback)),

	downloadAllDocuments: (fileName: string) => dispatch(downloadAllDocuments(fileName)),

	fetchCurrentStepAndUserRole: (successCallback?: (clientProcess: IClientProcessModel) => void) =>
		dispatch(fetchCurrentStepAndUserRole(successCallback)),

	updateLastVisitedStep: (step: SignProcessSteps, successCallback?: () => void) =>
		dispatch(updateLastVisitedStep(step, successCallback)),

	assignToDelegatee: (delegatee: IDelegateeSignerDetails, callback: () => void) =>
		dispatch(assignToDelegatee(delegatee, callback)),

	resetLastVisitedSteps: (callback: () => void) => dispatch(resetLastVisitedSteps(callback)),
});

export const TaxpayerSignFlowContainer = connect(mapStateToProps, mapDispatchToProps)(TaxpayerSignFlow);
