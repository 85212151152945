import { TelemetryLogger } from '../../../logger/AppInsights';
import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { ITaxingAuthority } from '../../../model/Common/TaxingAuthority';
import { ISummaryModel } from '../../../model/SummaryModel/SummaryModel';
import { Body } from '../../common/StepLayout/Body';
import { StepLayout } from '../../common/StepLayout/StepLayout';
import { EstimatedVouchers } from './Parts/EstimatedVouchers';
import { PaymentAndRefund } from './Parts/PaymentAndRefund';
import TaxpayerHelper from '../signflow/Helper/TaxpayerHelper';

const logger = TelemetryLogger.getInstance();

interface TaxSummaryProps {
	summaryModel: ISummaryModel;
	taxingAuthorities: ITaxingAuthority[];
	isTaxreturnReady: boolean;
	onFetchSummaryDetails(): void;
	onFetchTaxingAuthorities(): void;
	onNext(): void;
	isPreviewMode: boolean;
}

export const TaxSummary: React.FunctionComponent<TaxSummaryProps> = (props) => {
	const [key, setKey] = React.useState('home');

	React.useEffect(() => {
		props.onFetchTaxingAuthorities();
		props.onFetchSummaryDetails();

		logger.trackTrace(`Tax Summary Page`, { PreviewMode: props.isPreviewMode, ClientId: TaxpayerHelper.getClientId() });
	}, []); // like componenentdidmount

	return (
		<React.Fragment>
			<StepLayout>
				<Body
					title={'Tax Summary'}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					height={99}
					automationTestId={'11BB28B5-42F3-4571-B567-20700B1740B6'}>
					<Tabs
						id='controlled-tab-example'
						activeKey={key}
						onSelect={(k: any) => setKey(k)}>
						<Tab
							eventKey='home'
							title={`${props.summaryModel.taxYear} Tax Return`}>
							<PaymentAndRefund
								taxYear={props.summaryModel.taxYear}
								addedRefunds={props.summaryModel.addedRefunds}
								vouchers={props.summaryModel.vouchers}
								refunds={props.summaryModel.refunds}
								taxingAuthorities={props.taxingAuthorities}
								data-test-auto='127CE518-D505-4AE9-BA47-E207BDA7FD47'
							/>
						</Tab>
						<Tab
							eventKey='profile'
							title='Est. Payments'
							className={'test'}>
							<EstimatedVouchers
								taxYear={props.summaryModel.taxYear}
								vouchers={props.summaryModel.vouchers}
								taxingAuthorities={props.taxingAuthorities}
								data-test-auto='8E209449-6AE0-4C69-8206-69A98972158B'
							/>
						</Tab>
					</Tabs>

					<div className={'summary-begin-button-container'}>
						<button
							className={'button-bg summary-begin-button'}
							onClick={props.onNext}
							disabled={!props.isTaxreturnReady}>
							Select Here to Begin
						</button>
					</div>
				</Body>
			</StepLayout>
		</React.Fragment>
	);
};
