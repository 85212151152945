import * as React from 'react';
import { Body } from '../../../../common/StepLayout/Body';
import { StepLayout } from '../../../../common/StepLayout/StepLayout';

export const KBAInstructions: React.FunctionComponent<{}> = (props) => {
	return (
		<React.Fragment>
			<StepLayout>
				<Body
					title={'Security Questions'}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					automationTestId={'11BB28B5-42F3-4571-B567-20700B1740B6'}>
					IRS required Disclosure. Please verify your identity by answering the following questions.
					<br /> <br /> These questions are generated from a third party's database.
					<br /> <br />
					This process does not access or impact your credit report and the third party does not have access to your
					taxpayer information.
					<br /> <br />
					<strong>Please select Continue to begin</strong>
				</Body>
			</StepLayout>
		</React.Fragment>
	);
};
