import { ToastContainer, toast } from 'react-toastify';
import * as React from 'react';
import 'react-toastify/dist/ReactToastify.css';

export class MessageBox extends React.Component<any, any> {
	static Error = (message: string) => toast.error(message, { position: toast.POSITION.BOTTOM_CENTER });

	static Warning = (message: string) => toast.warn(message, { position: toast.POSITION.BOTTOM_CENTER });

	static Success = (message: string) => toast.success(message, { position: toast.POSITION.BOTTOM_CENTER });

	static Info = (message: string) => toast.info(message, { position: toast.POSITION.BOTTOM_CENTER });
}

export class Toaster extends React.Component<any, any> {
	render() {
		return (
			<div>
				<ToastContainer
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick={true}
					rtl={false}
					pauseOnHover
				/>
			</div>
		);
	}
}
