import * as React from 'react';
import { API_BASE_URL, PathConstants } from '../../../../../common/constants/Constants';
import { Body } from '../../../../common/StepLayout/Body';
import { StepLayout } from '../../../../common/StepLayout/StepLayout';
import TaxpayerHelper from '../../../signflow/Helper/TaxpayerHelper';

interface ManualSignRestrictedProps {
	clientName: string;
	companyName: string;
}

export const ManualSignRestricted: React.FunctionComponent<ManualSignRestrictedProps> = (props) => {
	let handleLogout = () => {
		(window as any).location.href = API_BASE_URL + PathConstants.Logout + TaxpayerHelper.getClientId();
	};

	return (
		<React.Fragment>
			<StepLayout>
				<Body
					title={''}
					bodyClassName={'sign-completed-body'}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					height={92}
					automationTestId={'4903FAD2-DEA0-423F-82E5-22D85B6BD89B'}>
					<div style={{ height: '10vh' }}>
						<h5>Welcome {props.clientName} </h5>
						<h5>{props.companyName.toUpperCase()}</h5>
						<br />
					</div>

					<br />

					<div style={{ height: '40vh' }}>
						<i
							className='fa fa-laptop'
							style={{ fontSize: ' 64px', color: 'yellowgreen' }}></i>
						<br />
						<br />
						<p>
							Your tax return has been set for manual signature. To print, sign, and upload your forms please login
							using a PC or Tablet.
						</p>
					</div>

					<div>
						<button
							className={'button-bg'}
							onClick={handleLogout}>
							Proceed to Desktop Version
						</button>
					</div>

					<br />
				</Body>
			</StepLayout>
		</React.Fragment>
	);
};
