import * as React from 'react';
import { OverlayLoader } from '../../common/Loader/OverlayLoader';

declare global {
	interface Window {
		Variables: any;
	}
}

interface HeaderProps {
	companyName: string;
	companyLogo: string;
}

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
	return (
		<div className='row header-container'>
			<div
				className={'header-nav'}
				style={{ justifyContent: 'center' }}>
				{props.companyLogo ? (
					<img
						className='company-logo'
						src={props.companyLogo}
					/>
				) : (
					<h2
						className={'title'}
						style={{ fontSize: '15px', fontWeight: 'normal', marginTop: '8px' }}>
						{props.companyName}
					</h2>
				)}
				<OverlayLoader />
			</div>
		</div>
	);
};
