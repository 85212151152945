import { actionTypes } from '../../../../common/constants/actionTypes';
import { container } from '../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../core/startup/inversify/types';
import { ILoader } from '../../../../core/utilities/ui/Loader';
import { IHeaderInfoModel, initialHeaderInfoModel } from '../../../../model/IHeaderInfoModel';
import { ITaxReturn, initialTaxReturn, ISignerModel } from '../../../../model/TaxReturn/TaxReturn';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import {
	IClientProcessModel,
	initialSignProcessInfoModel,
	ISignProcessInfo,
	SignProcessSteps,
} from '../Helper/TaxpayerSignFlowHelper';
import { IUtilities } from '../../../../core/utilities/Utilities';
import { TelemetryLogger } from '../../../../logger/AppInsights';
import { IDelegateeInfo, initialDelegateeInfoState } from '../../../../model/IDelegateeSignerModel';
import { initializeAxios } from '../../../../core/services/dataAccess/DataService.Axios';
import { ClientType } from '../../../../model/Common/Enums';

const logger = TelemetryLogger.getInstance();
const loader = container.get<ILoader>(TYPES.ILoader);
const utilities = container.get<IUtilities>(TYPES.IUtilities);

export const fetchTaxDocument = (successCallback?: (taxReturn: ITaxReturn) => void) => (dispatch: any) => {
	const clientid: string = TaxpayerHelper.getClientId();
	loader.show();
	initializeAxios(clientid)
		.get('api/Esign/GetTaxDocument/' + clientid)
		.then(function (response: any) {
			dispatch({
				type: actionTypes.FETCH_TAXDOCUMENT,
				data: response.data,
			});

			successCallback && successCallback(response.data);

			loader.hide();
		})
		.catch(function (error: any) {
			console.log(error);
			loader.hide();
			logger.trackError(`taxpayer mobile fetchTaxDocument failed with error ${error.message}`, { ClientId: clientid });
		});
};

export const fetchTaxDocumentClientType = () => (dispatch: any) => {
	const clientid: string = TaxpayerHelper.getClientId();
	initializeAxios(clientid)
		.get('api/Esign/GetTaxDocumentClientType/' + clientid)
		.then(function (response: any) {
			dispatch({
				type: actionTypes.FETCH_TAXDOCUMENT_CLIENT_TYPE,
				data: response.data,
			});
		})
		.catch(function (error: any) {
			console.log(error);
			loader.hide();
			logger.trackError(`taxpayer mobile fetchTaxDocumentClientType failed with error ${error.message}`, {
				ClientId: clientid,
			});
		});
};

export const fetchSigners = () => (dispatch: any) => {
	const clientid: string = TaxpayerHelper.getClientId();
	initializeAxios(clientid)
		.get('api/Esign/GetAllSigners/' + clientid)
		.then(function (response: any) {
			dispatch({
				type: actionTypes.FETCH_SIGNER_DETAILS,
				data: response.data,
			});
		})
		.catch(function (error: any) {
			console.log(error);
			logger.trackError(`taxpayer mobile fetchSigners failed with error ${error.message}`, { ClientId: clientid });
		});
};

export const fetchHeaderInfo = (successCallback?: () => void) => (dispatch: any) => {
	const clientid: string = TaxpayerHelper.getClientId();
	loader.show();
	initializeAxios(clientid)
		.get('api/Helper/GetHeaderInfoAsync/' + clientid)
		.then(function (response: any) {
			response.data && utilities.applyBrandingSettings(response.data.brandingSettings);

			dispatch({
				type: actionTypes.FETCH_HEADER_INFO,
				data: response.data,
			});

			successCallback && successCallback();
			loader.hide();
		})
		.catch(function (error: any) {
			console.log(error);
			loader.hide();
			logger.trackError(`taxpayer mobile fetchHeaderInfo failed with error ${error.message}`, { ClientId: clientid });
		});
};

export const fetchCurrentStepAndUserRole =
	(successCallback?: (clientProcessState: IClientProcessModel) => void) => (dispatch: any) => {
		const clientid: string = TaxpayerHelper.getClientId();
		loader.show();
		initializeAxios(clientid)
			.get('api/SignProcess/GetCurrentStepAndRole/' + clientid)
			.then(function (response: any) {
				dispatch({
					type: actionTypes.FETCH_SIGN_PROCESS_STEP,
					data: response.data.currentstep,
				});

				successCallback && successCallback(response.data);

				loader.hide();
			})
			.catch(function (error: any) {
				console.log(error);
				loader.hide();
				logger.trackError(`taxpayer mobile fetchCurrentStepAndUserRole failed with error ${error.message}`, {
					ClientId: clientid,
				});
			});
	};

export const fetchLastVisitedStep =
	(successCallback?: (lastVisitedStep: SignProcessSteps) => void) => (dispatch: any) => {
		const clientid: string = TaxpayerHelper.getClientId();
		loader.show();
		initializeAxios(clientid)
			.get('api/SignProcess/GetLastVisitedStep/' + clientid)
			.then(function (response: any) {
				dispatch({
					type: actionTypes.FETCH_LAST_VISITED_STEP,
					data: response.data,
				});

				successCallback && successCallback(response.data);

				loader.hide();
			})
			.catch(function (error: any) {
				console.log(error);
				loader.hide();
				logger.trackError(`taxpayer mobile fetchLastVisitedStep failed with error ${error.message}`, {
					ClientId: clientid,
				});
			});
	};

export const updateLastVisitedStep = (step: SignProcessSteps, successCallback?: () => void) => (dispatch: any) => {
	const clientId: string = TaxpayerHelper.getClientId();
	const url: string = 'api/SignProcess/AddOrUpdateProcessInfoAsync/' + clientId + '?step=' + step;

	initializeAxios(clientId)
		.post(url)
		.then(function (response: any) {
			successCallback && successCallback();
		})
		.catch(function (error: any) {
			console.log(error);
			logger.trackError(`taxpayer mobile updateLastVisitedStep failed with error ${error.message}`, {
				ClientId: clientId,
			});
		});
};

export const resetLastVisitedSteps = (callback: () => void) => (dispatch: any) => {
	loader.show();
	const clientId: string = TaxpayerHelper.getClientId();

	initializeAxios(clientId)
		.get('api/SignProcess/DeleteLastVisitedSteps/' + clientId)
		.then(function (response: any) {
			callback();
			loader.hide();
		})
		.catch(function (error: any) {
			loader.hide();
			logger.trackError(
				`Taxpayer Mobile resetLastVisitedSteps failed with error ${error.message} for client: ${clientId}`,
				{ ClientId: clientId },
			);
		});
};

export const updateTaxDocument = (taxReturn: ITaxReturn, callback?: () => void) => (dispatch: any) => {
	dispatch({ type: actionTypes.FETCH_TAXDOCUMENT, data: taxReturn });
	if (callback) {
		callback();
	}
};

export const taxDocumentReducer = (state: ITaxReturn = initialTaxReturn, action: any) => {
	const currentState = Object.assign({}, state);

	switch (action.type) {
		case actionTypes.FETCH_TAXDOCUMENT:
			let receivedDocumentReviewModel: ITaxReturn = { ...state };
			receivedDocumentReviewModel = action.data;
			if (receivedDocumentReviewModel.clientType === ClientType.Undefined || !receivedDocumentReviewModel.clientType) {
				receivedDocumentReviewModel.clientType = state.clientType;
			}
			return receivedDocumentReviewModel;

		case actionTypes.FETCH_TAXDOCUMENT_CLIENT_TYPE:
			currentState.clientType = action.data;
			return currentState;
	}

	return state;
};

export const signerDataReducer = (state: ISignerModel[] = [], action: any) => {
	switch (action.type) {
		case actionTypes.FETCH_SIGNER_DETAILS:
			return action.data;
	}

	return state;
};

export const headerInfoReducer = (state: IHeaderInfoModel = initialHeaderInfoModel, action: any) => {
	switch (action.type) {
		case actionTypes.FETCH_HEADER_INFO:
			return action.data;
	}

	return state;
};

export const signProcessInfoReducer = (state: ISignProcessInfo = initialSignProcessInfoModel, action: any) => {
	switch (action.type) {
		case actionTypes.FETCH_SIGN_PROCESS_STEP:
			return action.data;
	}

	return state;
};

export const delegateeDetailsReducer = (state: IDelegateeInfo = initialDelegateeInfoState, action: any) => {
	switch (action.type) {
		case actionTypes.FETCH_DELEGATEE_DETAILS:
			return action.data;

		default:
			return state;
	}
};
