import { Role } from "./Common/Enums";
import { initialUserModel, IUserModel } from "./IUserModel";
import { ITaxReturn, initialTaxReturn } from './TaxReturn/TaxReturn';
 


export interface ILoggedInUserInfo{
	role: Role;
	permission: string
}

export const initialLoggedInUserInfo: ILoggedInUserInfo = {
	role: Role.None,
	permission: ""
}

export interface IBrandingSettings {
    coverPageSetting: IThemeSettings
}

export interface IThemeSettings {
    bgColorCode:string,
    foreColorCode:string,
}

export const initialThemeSettings: IThemeSettings = {
    bgColorCode: "",
    foreColorCode:"",
}

export const initialBrandingSettings: IBrandingSettings = {
    coverPageSetting: initialThemeSettings,
}


export interface IHeaderInfoModel {
	clientName: string,
	taxYear: number;
	companyName: string,
	companyLogoPath: string,
	companyWhiteLogoPath: string,
	contactPerson: IUserModel,
	brandingSettings: IBrandingSettings,
	data: ITaxReturn,
	loggedInUserInfo: ILoggedInUserInfo
}

export const initialHeaderInfoModel: IHeaderInfoModel = {
	clientName: "",
	taxYear: 0,
	companyName: "",
	companyLogoPath: "",
	companyWhiteLogoPath: "",
	contactPerson: initialUserModel,
	brandingSettings: initialBrandingSettings,
	data: initialTaxReturn,
	loggedInUserInfo: initialLoggedInUserInfo,
}

