import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from '../../common/constants/actionTypes';
import { MessageBox } from '../common/Notification/MessageBox';
import { OTPPageConstants, OTPMobilePageConstants, DisplayError } from '../../common/constants/Constants';
import axios from 'axios';
import { container } from '../../core/startup/inversify/inversify.config';
import { TYPES } from '../../core/startup/inversify/types';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { TelemetryLogger } from '../../logger/AppInsights';

const logger = TelemetryLogger.getInstance();

export interface IOTPPage {
	loading: boolean;
	error: any;
	mobileNo: string;
	countryCode: string;
}

const OTPPage: IOTPPage = {
	loading: false,
	error: null,
	mobileNo: '',
	countryCode: '',
};

interface IClientResponse {
	IsSuccess: boolean;
	ErrorCode: string;
	ErrorDescription: string;
	Data: any;
}

export const actionCreators = {
	pageValidity:
		(clientId: string, errorCallBack: (errorCode: string) => void): any =>
		(dispatch: any) => {
			dispatch({ type: actionTypes.SET_OTP_LOADING });
			if (initializeAxios(clientId).getJsonValue !== undefined) {
				return initializeAxios(clientId)
					.getJsonValue('api/OTP/' + clientId)
					.then(function (response: AxiosResponse<any>) {
						let result = response.data;

						if (result.isSuccess) {
							logger.trackTrace(`Taxpayer Mobile pageValidity success for client guid ${clientId}`, {
								ClientId: clientId,
							});
							dispatch({ type: actionTypes.SET_VALID_OTP_PAGE });
						} else {
							let errorDesc = result.errorDescription;
							let errorCode = result.errorCode;

							if (errorCode && DisplayError.includes(errorCode)) {
								logger.trackWarning(
									`Taxpayer Mobile pageValidity failed with the error ${errorDesc} for client guid ${clientId}`,
									{ ClientId: clientId },
								);
								dispatch({
									type: actionTypes.SET_ERROR_MSG,
									errorMessage: errorDesc,
									errorCode: errorCode,
								});

								localStorage.setItem(errorCode, errorDesc);
								errorCallBack && errorCallBack(errorCode);
							} else {
								logger.trackWarning(
									`Taxpayer Mobile pageValidity failed with the error ${errorDesc} for client guid ${clientId}`,
									{ ClientId: clientId },
								);
								if (typeof errorDesc === 'string') {
									MessageBox.Error(errorDesc);
								}
							}
						}
					})
					.catch((e: any) => {
						logger.trackError(
							`Taxpayer Mobile pageValidity failed with the error ${e.message} for client guid ${clientId}`,
							{ ClientId: clientId },
						);
						MessageBox.Error(OTPPageConstants.ErrorMessage.serverError);
					});
			}
		},
	generateMobileOTP:
		(clientId?: string): any =>
		(dispatch: any) => {
			if (initializeAxios(clientId).postJsonValue !== undefined) {
				return initializeAxios(clientId)
					.postJsonValue('api/OTP/Mobile/Generate/' + clientId)
					.then(function (response: any) {
						let result = response.data;
						if (result.isSuccess) {
							logger.trackTrace(`Taxpayer Mobile generateMobileOTP success with for client guid ${clientId}`, {
								ClientId: clientId,
							});
							MessageBox.Success(OTPMobilePageConstants.SuccessMessage.OTPGenerateSuccess);
						} else {
							let errorDesc = result.errorDescription;
							logger.trackWarning(
								`Taxpayer Mobile generateMobileOTP failed with the error ${errorDesc} for client guid ${clientId}`,
								{ ClientId: clientId },
							);
							if (typeof errorDesc === 'string') {
								MessageBox.Error(errorDesc);
							}
						}
					})
					.catch(function (error: any) {
						logger.trackError(
							`Taxpayer Mobile generateMobileOTP failed with the error ${error.message} for client guid ${clientId}`,
							{ ClientId: clientId },
						);
						MessageBox.Error(OTPMobilePageConstants.ErrorMessage.serverError);
					});
			}
		},
	generateOTP:
		(clientId: string): any =>
		(dispatch: any) => {
			if (initializeAxios(clientId).postJsonValue !== undefined) {
				return initializeAxios(clientId)
					.postJsonValue('api/OTP/Generate/' + clientId, null)
					.then(function (response: any) {
						let result = response.data;
						if (result) {
							logger.trackTrace(`Taxpayer Mobile generateOTP success with for client guid ${clientId}`, {
								ClientId: clientId,
							});
							MessageBox.Success(OTPPageConstants.SuccessMessage.OTPGenerateSuccess);
						} else {
							logger.trackWarning(
								`Taxpayer Mobile generateOTP failed with the error OTP genreation failed for client guid ${clientId}`,
								{ ClientId: clientId },
							);
							dispatch({ type: actionTypes.OTP_GENERATE_FAILED });
							MessageBox.Error(OTPPageConstants.ErrorMessage.OTPGenerateFailed);
						}
					})
					.catch(function (error: any) {
						logger.trackError(
							`Taxpayer Mobile generateOTP failed with the error ${error.message} for client guid ${clientId}`,
							{ ClientId: clientId },
						);
						MessageBox.Error(OTPPageConstants.ErrorMessage.serverError);
					});
			}
		},
	verifyOTP:
		(
			OTP: string,
			clientId: string,
			handleVerify?: (guid: string) => void,
			errorCallBack?: (errorCode: string) => void,
		): any =>
		(dispatch: any) => {
			const config = { headers: { 'Content-Type': 'application/json' } };
			if (initializeAxios(clientId).postJsonValue !== undefined) {
				return initializeAxios()
					.postJson('"' + OTP + '"', `api/OTP/Validate/${clientId}`)
					.then(function (response: any) {
						let result = response.data;

						if (result.isSuccess) {
							logger.trackTrace(`Taxpayer Mobile verifyOTP success for client guid ${clientId}`, {
								ClientId: clientId,
							});
							if (handleVerify !== undefined) {
								handleVerify(result.data);
								// handleVerify(result.data.clientId);
							}
						} else {
							let errorDesc = result.errorDescription;
							let errorCode = result.errorCode;
							logger.trackWarning(
								`Taxpayer Mobile verifyOTP failed with the error ${errorDesc} for client guid ${clientId}`,
								{ ClientId: clientId },
							);
							if (errorCode && DisplayError.includes(errorCode)) {
								dispatch({
									type: actionTypes.SET_ERROR_MSG,
									errorMessage: errorDesc,
									errorCode: errorCode,
								});

								localStorage.setItem(errorCode, errorDesc);
								errorCallBack && errorCallBack(errorCode);
							} else {
								if (typeof errorDesc === 'string') {
									MessageBox.Error(errorDesc);
								}
							}
						}
					})
					.catch(function (error: any) {
						logger.trackError(
							`Taxpayer Mobile verifyOTP failed with the error ${error.message} for client guid ${clientId}`,
							{ ClientId: clientId },
						);
						MessageBox.Error(OTPPageConstants.ErrorMessage.serverError);
					});
			}
		},
	setMobileNo:
		(mobileNo: string, countryCode: string): any =>
		(dispatch: any, getState: any) => {
			return dispatch({ type: actionTypes.SET_MOBILE_VAL, data: { mobileNo, countryCode } });
		},
};

export const reducer: Reducer<IOTPPage> = (state: IOTPPage = OTPPage, incomingAction: Action) => {
	const action = incomingAction as any;
	const currentState = Object.assign({}, state);
	console.log('in reducers', action);
	switch (action.type) {
		case actionTypes.SET_OTP_LOADING:
			return { ...currentState, loading: true };
		case actionTypes.SET_INVALID_OTP_PAGE:
			//set a error message stating this is a invalid page
			return { ...currentState, loading: true };
		case actionTypes.SET_VALID_OTP_PAGE:
			return { ...currentState, loading: false };
		case actionTypes.SET_OTP_ERROR:
			//set the exception error for not getting data.
			let errorMsg = action.error;
			return { ...currentState, loading: false, error: errorMsg };
		case actionTypes.OTP_GENERATE_FAILED:
			//set the alert for OTP generate failed.
			return { ...currentState };
		case actionTypes.SET_MOBILE_VAL:
			return { ...currentState, mobileNo: action.data.mobileNo, countryCode: action.data.countryCode };

		default:
			return currentState;
	}
};
