import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { MessageBox } from '../../common/Notification/MessageBox';

interface RemarksProps {
	show: boolean;
	onSaveRemarks(): void;
	reason: string;
	onCancel: () => void;
	onRemarksChange: (event: any) => void;
	title: string;
	warningMessage: string;
}

export const DelegateeCancelPopup: React.FC<RemarksProps> = (props) => {
	const onSubmit = () => {
		if (props.reason.length === 0) {
			return MessageBox.Warning(props.warningMessage);
		}

		props.onSaveRemarks();
	};

	const onCancel = () => {
		props.onCancel();
	};

	return (
		<Modal
			show={props.show}
			onHide={onCancel}>
			<Modal.Header closeButton>
				<Modal.Title>
					<span
						data-test-auto='A06940BB-170E-4F01-A114-7503FE07953E'
						style={{ color: '#0274bb' }}>
						<i
							className='fa fa-bullhorn'
							style={{ marginRight: 5 }}></i>
						{props.title}
					</span>
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div>
					<div style={{ padding: '10px' }}>
						<textarea
							data-test-auto='8903364C-FDB9-4CD0-B454-6CF4BE9E1C07'
							value={props.reason}
							onChange={(e: any) => {
								props.onRemarksChange(e);
							}}
							className='form-control remarksTextArea'
						/>
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button
					data-test-auto='11214587-336D-495F-A2AC-EFA4266152B0'
					onClick={props.onCancel}
					className='btn btn-white btn-default rounded'
					variant={'light'}>
					<i className='fa fa-times'></i>Cancel
				</Button>

				<Button
					data-test-auto='88A9ED0B-F350-4CF5-A165-F494BF52B46B'
					onClick={onSubmit}
					className='btn btn-primary rounded'
					variant={'primary'}>
					<i className='fa fa-save'></i>Submit
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
