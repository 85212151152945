import { injectable } from "inversify";
import { IBrandingSettings } from "../../model/IHeaderInfoModel";
// import { IBrandingSettings } from '../domain/models/IBrandingSettings';

export interface IUtilities {
    //    Animate(start: number, end: number, duration: number): void;
    formateFax(fax: string): string;
    isValidEmailAddress(emailAddress: string): boolean;
    isValidDOB(DOB: Date): boolean;
    getUrlPathLastComponent(path: string): string;
    increaseBrightness(hex: string, percent: number): string;
    applyBrandingSettings(setting: IBrandingSettings): void;
    formatCurrencyText(Value: number): string;
    phoneNumberDisplay(phoneNumber: string): string;
    validateTenDigitphoneNumber(value: string): boolean;
    formatPaymentDueText(Value: number): string;
    validateMobileLength(phoneNoId: any): boolean;
    formatPaymentDueCurreny(Value: number): string;
}

@injectable()
export class Utilities implements IUtilities {

    //    Animate(start: number, end: number, duration: number): void {

    //        let range: number = end - start;
    //        var current = start;
    //        var increment = end > start ? 1 : -1;
    //        var stepTime = Math.abs(Math.floor(duration / range));
    //        var timer = setInterval(function () {
    //            current += increment;
    //            obj.innerHTML = current;
    //            if (current == end) {
    //                clearInterval(timer);
    //            }
    //        }, stepTime);

    //    } 

    formateFax(fax: string): string {
        if (fax == undefined || (fax != undefined && fax.length != 10)) {
            console.log("Invalid Fax value");
            return "";
        }
        return "(" + fax.slice(0, 3) + ") " + fax.slice(3, 6) + "-" + fax.slice(6);
    }

    isValidEmailAddress(emailAddress: string): boolean {
        var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
        return pattern.test(emailAddress);
    }

    isValidDOB(DOB: Date): boolean {
        return DOB < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    }

    getUrlPathLastComponent(path: string): string {
        var pathComponents = path.split('/');
        return pathComponents[pathComponents.length - 1];
    }

    increaseBrightness(hex: string, percent: number): string {
        // strip the leading # if it's there
        hex = hex.replace(/^\s*#|\s*$/g, '');

        // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
        if (hex.length == 3) {
            hex = hex.replace(/(.)/g, '$1$1');
        }

        var r = parseInt(hex.substr(0, 2), 16),
            g = parseInt(hex.substr(2, 2), 16),
            b = parseInt(hex.substr(4, 2), 16);

        return '#' +
            ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
            ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
    }

    applyBrandingSettings(setting: IBrandingSettings): void {

        if (setting && setting.coverPageSetting) {
            var html = document.getElementsByTagName('html')[0];
            let style: string = '';
            style += "--headerBgColor:" + setting.coverPageSetting.bgColorCode + ";";
            style += "--headerForeColor:" + setting.coverPageSetting.foreColorCode + ";";
            style += "--bottonBgColor:" + setting.coverPageSetting.bgColorCode + ";";
            style += "--bottonBorderColor:" + this.increaseBrightness(setting.coverPageSetting.bgColorCode, 5) + ";";
            html.style.cssText = style;
        }
    }

    formatCurrencyText(Value: number): string {

        var InputVal = Math.floor(Value);
        var formatted = "";

        formatted = InputVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (Value % 1 !== 0) {
            formatted = formatted + "." + Value.toFixed(2).toString().split('.')[1];
        }

        return formatted;
    }
    formatPaymentDueText(Value: number): string {
        return Value.toFixed(2);
    }

    formatPaymentDueCurreny(value: number): string {
        var formattedText = this.formatPaymentDueText(value);
        var splitValue = formattedText.split('.');
        var formattedCurrency = this.formatCurrencyText(parseInt(splitValue[0])) + "." + splitValue[1];
        return formattedCurrency;
    }

    phoneNumberDisplay(phoneNumber: string): string {
        return phoneNumber && phoneNumber.length == 10 ? "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10) : phoneNumber;
    };

    validateTenDigitphoneNumber(value: string): boolean {
        var pattern = new RegExp(/^[0-9\-\s)\(]{0,14}$/);
        return pattern.test(value);
    };

    validateMobileLength(phoneNoId: any) {
        var phoneNo = phoneNoId.trim();
        if (phoneNo.length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
            return false;
        }
        return true;
    };

}