import { AxiosResponse } from "axios";
import { ISMSGETResponse, ISMSPUTRequest } from "./SMSService.model";
import { initializeAxios } from "../dataAccess/DataService.Axios";
import { TelemetryLogger } from "../../../logger/AppInsights";
const logger = TelemetryLogger.getInstance();

export const getConsentDetails = (
  clientGuid: string,
  clientId: string,
  clientType: number,
  mobileNumber: string,
  callback?: (responseData: ISMSGETResponse) => void
) => {
    mobileNumber = mobileNumber === '' || mobileNumber === undefined?'':mobileNumber;
  initializeAxios()
    .get(
      "api/ClientConsent/GetConsentPopup/" +
        clientGuid +
        "?clientId=" +
        clientId +
        "&clientType=" +
        clientType +
        "&mobileNumber=" +
        mobileNumber
    )
    .then(function (response: AxiosResponse<ISMSGETResponse>) {
      callback && callback(response.data);
    })
    .catch(function (error: any) {
      logger.trackWarning(
        `getConsentDetails failed with error ${error.message} for client: ${clientId}`,
        { ClientId: clientId }
      );
    });
};

export const updateConsentDetails = (
  consentDetails: ISMSPUTRequest,
  callback?: () => void
) => {
  initializeAxios(consentDetails.clientGuid)
    .putJson(
      consentDetails,
      "api/ClientConsent/UpdateConsentPopup/" + consentDetails.clientGuid
    )
    .then(function (response: AxiosResponse) {
      if (response.data) {
        callback && callback();
      } else logger.trackWarning(`updateConsentDetails failed with error for client: ${consentDetails.clientId}`, { ClientId: consentDetails.clientId });
    })
    .catch(function (error: any) {
      logger.trackWarning(
        `getConsentDetails failed with error ${error.message} for client: ${consentDetails.clientId}`,
        { ClientId: consentDetails.clientId }
      );
    });
};
