export enum actionTypes {
	FETCH_PREPARER_MESSAGE = 1,
	FETCH_SUMMARY_DETAIL = 2,
	FETCH_TAXING_AUTHORITY = 3,
	FETCH_REVIEW_DETAILS = 4,
	FETCH_TAXDOCUMENT = 5,
	FETCH_KBA_QUESTIONS = 6,
	FETCH_CONTROL_DATA = 7,
	FETCH_HEADER_INFO = 8,
	FETCH_SIGN_PROCESS_STEP = 9,
	FETCH_LAST_VISITED_STEP = 10,
	FETCH_KBA_STATUS = 11,
	FETCH_SIGNER_DETAILS = 12,
	FETCH_TAXDOCUMENT_CLIENT_TYPE = 13,

	RECEIVE_CLIENT_DETAILS,
	START_CLIENT_FETCH,

	RECEIVE_HEADER_DETAILS,

	SET_OTP_LOADING,
	SET_VALID_OTP_PAGE,
	OTP_GENERATE_FAILED,
	SET_INVALID_OTP_PAGE,
	SET_OTP_ERROR,
	SET_MOBILE_VAL,

	FETCH_SSN_DETAILS,
	RECEIVE_SSN_DETAILS,

	SET_ERROR_MSG,

	FETCH_DELEGATEE_DETAILS,
	NOTIFICATION,
}

export enum StatusType {
	None,
	Error,
	Success,
	Warning,
}
