import { actionTypes } from '../../../../common/constants/actionTypes';
import { IDataService } from '../../../../core/services/dataAccess/abstraction/IDataService';
import { container } from '../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../core/startup/inversify/types';
import { ITaxingAuthority } from '../../../../model/Common/TaxingAuthority';
import TaxpayerHelper from '../../signflow/Helper/TaxpayerHelper';
import { TelemetryLogger } from '../../../../logger/AppInsights';
import { initializeAxios } from '../../../../core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();

export const fetchTaxingAuthorities = () => (dispatch: any) => {
	const clientid: string = TaxpayerHelper.getClientId();

	initializeAxios(clientid)
		.get('api/Helper/GetAllTaxingAuthorityAsync/' + clientid)
		.then(function (response: any) {
			dispatch({
				type: actionTypes.FETCH_TAXING_AUTHORITY,
				data: response.data,
			});
		})
		.catch(function (error: any) {
			console.log(error);
			logger.trackError(`taxpayer mobile fetchTaxingAuthorities failed with error ${error.message}`, {
				ClientId: clientid,
			});
		});
};

export const taxingAuthoritiesReducer = (state: ITaxingAuthority[] = [], action: any) => {
	switch (action.type) {
		case actionTypes.FETCH_TAXING_AUTHORITY:
			return action.data;
	}

	return state;
};
