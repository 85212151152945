import { connect } from 'react-redux';
import CoverPage from './CoverPage';
import * as CoverPageStore from './clientCoverActions';
import * as HeaderStore from '../HeaderActions';
import * as OTPPageReducer from '../OTP/OTPActions';

export default connect(
	(state: any) => ({
		initialCoverState: state.coverState,
		headerInfoState: state.headerState,
	}),
	{
		...CoverPageStore.actionCreators,
		...HeaderStore.actionCreators,
		...OTPPageReducer.actionCreators,
	},
)(CoverPage as any);
