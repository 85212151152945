import React, { useEffect } from 'react';
import { TelemetryLogger } from '../../logger/AppInsights';

const logger = TelemetryLogger.getInstance();

interface WarningModalProps {
	showModal: boolean;
	toggleModalVisibility(x: boolean): void;
	clientName: string;
	taxYear: number;
}

export const WarningModal = (props: WarningModalProps) => {
	const { showModal, toggleModalVisibility, clientName, taxYear } = props;

	const hideModal = () => {
		toggleModalVisibility(false);
	};

	useEffect(() => {
		logger.trackEvent({ name: `Return is not accessible due to mandatory payment setting` });
	}, []);

	return (
		<div
			style={{ display: showModal ? 'flex' : 'none' }}
			className='warningModal'>
			<div className='warningModal-content modal-content'>
				<div className='warningModal-para1'>
					<strong>
						Hi, {clientName}, your {taxYear} returns are ready.
					</strong>
				</div>
				<div>
					Your firm requires the payment of the tax preparing fees to be paid prior to reviewing your tax returns.
				</div>
				<div className='warningModal-para3'>Please login by using a PC or tablet in order to make a payment.</div>
				<button
					className='warningModal-success'
					onClick={hideModal}>
					OK
				</button>
			</div>
		</div>
	);
};
