import PdfViewer from 'awesome-pdf-viewer';
import BookmarkPanel from 'awesome-pdf-viewer/dist/bookmark/BookmarkPanel';
import Header from 'awesome-pdf-viewer/dist/layout/Header';
import LeftPanel from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import Main from 'awesome-pdf-viewer/dist/layout/Main';
import RightPanel from 'awesome-pdf-viewer/dist/layout/RightPanel';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import Toolbar from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import * as React from 'react';
import { ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import Pagination from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { PdfJSSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';

export interface ViewProps {
	url: string;
	onViewModeChange?(viewerMode: ViewerMode): void;
	leftPanel?: any;
}

const DEFAULT_VIEWER_SCALE: number = 1;

export class Viewer extends React.Component<ViewProps, {}> {
	private _viewPanel: any;
	private _toolbar: any;
	private _bookmarkPanel: any;
	private _controlLayer: any;

	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.setReferences();
	}

	setReferences() {
		this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
		this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
		this._viewPanel && this._viewPanel.setBookmarkPanelReference(this._bookmarkPanel);
		this._viewPanel.setControlLayerReference(this._controlLayer);
		this._controlLayer && this._controlLayer.setViewerReference(this._viewPanel);
	}

	componentDidUpdate() {
		this.setReferences();
	}

	public render() {
		return (
			<PdfViewer>
				<Header>
					<Toolbar
						ref={(ref: any) => (this._toolbar = ref)}
						hideLeftPanel={true}
						hideRightPanel={true}
						onViewModeChange={this.props.onViewModeChange}>
						<Pagination />
						<Zoom />
					</Toolbar>
				</Header>

				<Main>
					<ControlLayer
						ref={(ref: any) => {
							this._controlLayer = ref;
						}}
					/>

					<LeftPanel>{this.props.leftPanel}</LeftPanel>

					<ViewPanel
						ref={(ref: any) => (this._viewPanel = ref)}
						pdfSource={PdfJSSource.createFromUrl(this.props.url)}
						disableTextLayer={true}></ViewPanel>

					<RightPanel>
						<BookmarkPanel
							ref={(ref: any) => {
								this._bookmarkPanel = ref;
							}}></BookmarkPanel>
					</RightPanel>
				</Main>
			</PdfViewer>
		);
	}
}
