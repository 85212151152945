import * as React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { DocumentType, IDocument, IPage } from '../../../../../model/Esign/Document';
import { SvgIconSignCompleted } from '../../../../common/Icons/SvgIcons';
import { PdfViewManager } from './EsignBase';

export interface FileListProps {
	selectedDocumentId: number;
	documents: IDocument[];
	onFileClick(document: IDocument, pageNo: number): void;
	selectedPageNo: number;
}

export interface FileListState {
	expanded: string[];
	nodes: any[];
}

export class FileList extends React.Component<FileListProps, FileListState> {
	constructor(props: FileListProps) {
		super(props);

		this.state = {
			expanded: ['-1'],
			nodes: [],
		};
	}

	static getDerivedStateFromProps(nextProps: FileListProps, nextState: FileListState) {
		if (nextProps.documents.length > 0) {
			const distinctDocumentTypes: DocumentType[] = FileList.getDistinctDoumentTyps(nextProps);
			return {
				expanded:
					nextState.expanded.indexOf('-1') > -1
						? FileList.createExpandedNodes(distinctDocumentTypes, nextProps)
						: nextState.expanded,
				nodes: FileList.createTreeNodes(distinctDocumentTypes, nextProps),
			};
		}
		return null;
	}

	onExpand = (expanded: any) => {
		this.setState({ expanded });
	};

	private static getDistinctDoumentTyps = (props: FileListProps): DocumentType[] => {
		let distinct: DocumentType[] = [];

		for (var i = 0; i <= props.documents.length - 1; i++) {
			if (distinct.findIndex((x) => x.id == props.documents[i].documentType.id) < 0) {
				distinct.push(props.documents[i].documentType);
			}
		}

		return distinct;
	};

	private static createTreeNodes = (documentTypes: DocumentType[], props: FileListProps): any[] => {
		let nodes: any[] = [];

		for (var i = 0; i <= documentTypes.length - 1; i++) {
			const tmpDocumentType: DocumentType = documentTypes[i];
			const tmpDocuments: IDocument[] = props.documents.filter((x) => x.documentType.id == tmpDocumentType.id);

			let tmpNode: any = {
				value: `/${tmpDocumentType.id}`,
				label: tmpDocumentType.name, //tmpDocumentType.name.length < FileListStrings.ENGAGEMENT_TYPE_TEXT_LIMIT ? tmpDocumentType.name : tmpDocumentType.name.slice(0, FileListStrings.ENGAGEMENT_TYPE_TEXT_LIMIT) + "...",
				showcheckbox: false,
				title: tmpDocumentType.name,
				children: [],
			};

			if (tmpDocumentType.id === 0) {
				for (var j = 0; j <= tmpDocuments.length - 1; j++) {
					for (var k = 0; k <= tmpDocuments[j].pages.length - 1; k++) {
						const tmpDocument: IDocument = tmpDocuments[j];
						const page: IPage = tmpDocuments[j].pages[k];
						const value = { id: tmpDocument.id, pageNo: page.page };
						tmpNode.children.push({
							value: JSON.stringify(value),
							label: FileList.getNodeLabel(page.formName, page.allControlSigned), // page.formName, //.length < FileListStrings.FILE_NAME_TEXT_LIMIT ? tmpDocument.name : tmpDocument.name.slice(0, FileListStrings.FILE_NAME_TEXT_LIMIT) + "...",
							showcheckbox: false,
							icon: page.allControlSigned
								? FileList.getSignCompletedIcon()
								: FileList.getPageIcon(page.allControlSigned),
							title: page.bookmark,
							className: 'file-node-' + tmpDocument.id + '_' + page.page,
							id: 'file-node-' + tmpDocument.id + '_' + page.page,
						});
					}
				}
			} else {
				for (var j = 0; j <= tmpDocuments.length - 1; j++) {
					const tmpDocument: IDocument = tmpDocuments[j];
					const value = { id: tmpDocument.id, pageNo: 1 };
					tmpNode.children.push({
						disabled: tmpDocument.disabled,
						value: JSON.stringify(value),
						label: FileList.getNodeLabel(tmpDocument.name, tmpDocument.allControlSigned), //tmpDocument.name, //.length < FileListStrings.FILE_NAME_TEXT_LIMIT ? tmpDocument.name : tmpDocument.name.slice(0, FileListStrings.FILE_NAME_TEXT_LIMIT) + "...",
						showcheckbox: false,
						icon: tmpDocument.allControlSigned
							? FileList.getSignCompletedIcon()
							: FileList.getPdfIcon(tmpDocument.allControlSigned),
						title: tmpDocument.name,
						className: 'file-node-' + tmpDocument.id + '_1',
						id: 'file-node-' + tmpDocument.id + '_1',
					});
				}
			}
			nodes.push(tmpNode);
		}

		return nodes;
	};

	static getNodeLabel = (lable: string, showCheck: boolean) => {
		return <span title={lable}> {lable} </span>;
	};

	static getPdfIcon(showCheck: boolean) {
		return (
			<i
				className='fa fa-file-pdf-o'
				style={{ color: showCheck ? 'green' : '#bbb' }}
			/>
		);
	}
	static getPageIcon(showCheck: boolean) {
		return (
			<i
				className='fa fa-file-o'
				style={{ color: showCheck ? 'green' : '#bbb' }}
			/>
		);
	}

	static getSignCompletedIcon() {
		return <SvgIconSignCompleted />;
	}

	static getTickIcon(showCheck: boolean) {
		return (
			<span
				className='rct-icon rct-icon-check'
				style={{ color: showCheck ? 'green' : '#bbb' }}
			/>
		);
	}

	private static createExpandedNodes = (documentTypes: DocumentType[], props: FileListProps): any[] => {
		let expandedNodes: string[] = [];

		for (var i = 0; i <= documentTypes.length - 1; i++) {
			expandedNodes.push(`/${documentTypes[i].id.toString()}`);
		}
		return expandedNodes;
	};

	private handleFileClick = (node: any) => {
		if (node.children == undefined) {
			const value = JSON.parse(node.value);
			const tmpDocument = this.props.documents.find((x) => x.id == parseInt(value.id) && x.disabled == false);
			tmpDocument && this.props.onFileClick(tmpDocument, parseInt(value.pageNo));
		}
	};

	public render() {
		const { nodes, expanded } = this.state;

		setTimeout(() => {
			PdfViewManager.highlightSelectedFile(this.props.selectedDocumentId, this.props.selectedPageNo);
		}, 0);

		return (
			<div className='file-list-tree-view-container'>
				<CheckboxTree
					showExpandAll={false}
					expanded={expanded}
					iconsClass='fa5'
					nodes={nodes}
					expandOnClick
					data-test-auto='BBF1C5F3-8CA3-4DE7-AA6E-6C4FF6CA46C4'
					onClick={this.handleFileClick}
					onExpand={this.onExpand}
				/>
			</div>
		);
	}
}
