import * as React from 'react';
import { MessageDetails } from '../../../model/DocumentSettings';
import { IHeaderInfoModel } from '../../../model/IHeaderInfoModel';
import { Shimmer } from '../../common/Shimmer/Shimmer';
import { Body } from '../../common/StepLayout/Body';
import { StepLayout } from '../../common/StepLayout/StepLayout';
import { IHtmlUtilities } from '../../../core/utilities/HtmlUtilities';
import { TYPES } from '../../../core/startup/inversify/types';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TelemetryLogger } from '../../../logger/AppInsights';
import TaxpayerHelper from '../signflow/Helper/TaxpayerHelper';

const logger = TelemetryLogger.getInstance();

interface PreparerMessageProps {
	message: MessageDetails;
	headerInfo: IHeaderInfoModel;
	isPreviewMode: boolean;
}

const htmlUtilities = container.get<IHtmlUtilities>(TYPES.IHtmlUtilities);

export const PreparerMessage: React.FunctionComponent<PreparerMessageProps> = (props) => {
	React.useEffect(() => {
		logger.trackTrace(`Preparer Message Page`, {
			PreviewMode: props.isPreviewMode,
			ClientId: TaxpayerHelper.getClientId(),
		});
	}, []);

	return (
		<React.Fragment>
			<StepLayout>
				<Body
					title={`A message from your accountant`}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					automationTestId={'11BB28B5-42F3-4571-B567-20700B1740B6'}>
					{props.message.body ? (
						<div
							dangerouslySetInnerHTML={{
								__html: htmlUtilities.decode(props.message.body.replace('&nbsp;', ' ')),
							}}></div>
					) : (
						<Shimmer height={560} />
					)}
				</Body>
			</StepLayout>
		</React.Fragment>
	);
};
