import React from 'react';
import { match } from 'react-router';
import { StepLayout } from '../../common/StepLayout/StepLayout';
import { AssignToDelegatee } from '../Delegatee/AssignToDelegatee';
import { SignatureType } from '../../../model/Common/Enums';
import * as TaxDocument from '../../../model/TaxReturn/TaxReturn';
import { IDelegateeSignerDetails } from '../../../model/IDelegateeSignerModel';
import { DelegateeSignerConstants } from '../../../common/constants/Constants';
import { History } from 'history';
import { logger } from '../SignCompleted/SignCompletedFlow';

interface SelectSignMethodProps {
	onSetSignMethod: (signatureType: SignatureType) => void;
	clearParentState: () => void;
	match?: match;
	history?: History;
	taxReturn?: TaxDocument.ITaxReturn;
	saveDelegateeDetails?: (delegatee: IDelegateeSignerDetails, callback: () => void) => void;
	isDelegateeAssigned?: boolean;
	goToStep?: (stepNumber: number) => void;
	saveDelegateeState?: () => void;
	isPreviewMode: boolean;
	checkIfBothSignerDeceased: () => void;
	onDelegationSuccess: (clientId: string) => void;
}

interface SelectSignMethodState {
	showModal: boolean;
}

export class SignMethod extends React.Component<SelectSignMethodProps, SelectSignMethodState> {
	private _params: any;

	constructor(props: any) {
		super(props);
		this.state = {
			showModal: false,
		};
		this.props.clearParentState();
	}

	componentDidMount() {
		this._params = this.props.match && this.props.match.params;
		logger.trackPageView('Select Sign Method Page', { PreviewMode: this.props.isPreviewMode });

		if (this._params.controllerId) {
			this.props.checkIfBothSignerDeceased && this.props.checkIfBothSignerDeceased();
		}
	}

	private setSignMethod = (signatureType: SignatureType) => {
		const { onSetSignMethod } = this.props;
		onSetSignMethod(signatureType);
	};

	private closeDelegationPopUp = () => {
		this.setState({ showModal: false });
	};

	render() {
		return (
			<React.Fragment>
				<StepLayout className='step-grey'>
					<div className='sign-method-container'>
						<div className='sign-method-content'>
							<h5 data-test-auto='6D6F86B7-2A15-445B-94F1-6012AAA439F7'>Thank you for reviewing your tax return.</h5>
							<h5 data-test-auto='0BA4E531-CC6F-4E78-869F-98D38FEFAFF9'>
								Please select a method you would like to use to sign your documents:
							</h5>
						</div>
						<div className='sign-method-buttons'>
							{!this.props.isDelegateeAssigned && (
								<>
									<div>
										<button
											data-test-auto='823D6D6A-9006-40D5-9E93-B39946D4BF24'
											className='btn btn-lg btn-rounded success sign'
											onClick={() => this.setSignMethod(SignatureType.ESign)}>
											Sign Documents
										</button>
									</div>
									{this.props.taxReturn?.documentSettings.documentSignatureSetting.enableSignerDeligation && (
										<div>
											<button
												data-test-auto='233079C9-8371-4631-BA13-610CDD323F1D'
												id='awesome-multi-steps-btn-previous'
												className='btn btn-lg btn-rounded success sign'
												onClick={() => {
													this.setState({ showModal: true });
													logger.trackPageView('"Delegate to someone else" button clicked', {
														PreviewMode: this.props.isPreviewMode,
													});
												}}>
												Delegate to Someone Else
											</button>
										</div>
									)}
								</>
							)}
						</div>
					</div>

					<AssignToDelegatee
						show={this.state.showModal}
						onCancel={this.closeDelegationPopUp}
						param={this._params}
						history={this.props.history}
						taxReturn={this.props.taxReturn}
						saveDelegateeSignerDetails={this.props.saveDelegateeDetails}
						isDelegateeAssigned={this.props.isDelegateeAssigned}
						goToStep={this.props.goToStep}
						saveDelegateeState={this.props.saveDelegateeState}
						successMsg={DelegateeSignerConstants.SuccessMessage.SavedDelegateeDetailsSuccess}
						isPreviewMode={this.props.isPreviewMode}
					/>
				</StepLayout>
			</React.Fragment>
		);
	}
}
