import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from '../../common/constants/actionTypes';

import axios from 'axios';
import { MessageBox } from '../common/Notification/MessageBox';
import { CoverPageConstants, DisplayError } from '../../common/constants/Constants';
import { container } from '../../core/startup/inversify/inversify.config';
import { TYPES } from '../../core/startup/inversify/types';
import { TelemetryLogger } from '../../logger/AppInsights';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();

const clientData = {
	Name: '',
	companyName: '',
};

export interface IClientCoverState {
	companyName: string;
	logoPath: string;
	taxYear: number | null;
	engagementType: EngagementTypes | null;
	clientName: string;
	contactAddress: any;
	mobileVerification: any;
	loaderState: boolean;
	mfaSettings: any;
	isPaymentRequired: boolean;
}

export enum EngagementTypes {
	None = 0,
	E1040 = 1,
	E1065 = 2,
	E1120 = 3,
	E1120S = 4,
	E1041 = 5,
	E1040NR = 6,
}

export const initialCoverState: IClientCoverState = {
	companyName: '',
	logoPath: '',
	taxYear: null,
	engagementType: null,
	clientName: '',
	contactAddress: {},
	mobileVerification: null,
	loaderState: false,
	mfaSettings: null,
	isPaymentRequired: false,
};

export const actionCreators = {
	requestCoverClientData:
		(clientId: string, successCallback: () => void, errorCallBack: (errorCode:string) => void): any =>
		(dispatch: any) => {
			dispatch({ type: actionTypes.START_CLIENT_FETCH });
			if (initializeAxios(clientId).getJsonValue !== undefined) {
				return initializeAxios(clientId)
					.getJsonValue('api/Coverpage/GetClientInfo/' + clientId)
					.then(function (response: any) {
						let result = response.data;
						if (result.isSuccess) {
							dispatch({
								type: actionTypes.RECEIVE_CLIENT_DETAILS,
								data: result.data,
							});

							const { isPaymentRequired } = result.data;

							if (isPaymentRequired) {
								successCallback();
							}
						} else {
							let errorDesc = result.errorDescription;
							let errorCode = result.errorCode;
							logger.trackWarning(
								`Taxpayer Mobile requestCoverClientData failed with the error ${errorDesc} for client guid ${clientId}`,
								{ ClientId: clientId },
							);
							if (errorCode && DisplayError.includes(errorCode)) {
								dispatch({
									type: actionTypes.SET_ERROR_MSG,
									errorMessage: errorDesc,
									errorCode: errorCode,
								});

								localStorage.setItem(errorCode, errorDesc);
								errorCallBack && errorCallBack(errorCode);
							} else {
								if (typeof errorDesc === 'string') {
									MessageBox.Error(errorDesc);
								}
							}
						}
					})
					.catch((e: any) => {
						logger.trackError(
							`Taxpayer Mobile requestCoverClientData failed with the error ${e.message} for client guid ${clientId}`,
							{ ClientId: clientId },
						);
						MessageBox.Error(CoverPageConstants.ErrorMessage.serverError);
					});
			}
		},
};

export const reducer: Reducer<typeof initialCoverState> = (
	state: typeof initialCoverState = initialCoverState,
	incomingAction: Action,
) => {
	const action = incomingAction as any;
	const currentState = Object.assign({}, state);
	console.log('in reducers', action);
	switch (action.type) {
		case actionTypes.RECEIVE_CLIENT_DETAILS:
			let data = action.data;

			currentState.companyName = data.companyName;
			currentState.logoPath = data.logoPath;
			currentState.taxYear = data.taxYear;
			currentState.engagementType = data.engagementType;
			currentState.clientName = data.clientName;
			currentState.contactAddress = data.contactAddress;
			currentState.loaderState = false;
			currentState.mfaSettings = data.mfaSettings;
			currentState.mobileVerification = data.mobileVerification;
			currentState.isPaymentRequired = data.isPaymentRequired;

			return { ...currentState };
		case actionTypes.START_CLIENT_FETCH:
			return { ...currentState, loaderState: true };
		default:
			return currentState;
	}
};
