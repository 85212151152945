import { SvgIconDownloadAll, SvgIconSendForSignature } from '../../components/common/Icons/SvgIcons';

export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const SiginingConstants = {
	StatusMessage: {
		ManualSignSelectConfirmationMessage: 'Please confirm whether you want to continue with manual sign?',
		EditSpouseEmail: 'Edit Spouse Mail',
	},
	WarningMessage: {
		InvalidEmailAddress: 'Please Enter valid email Address',
		InvalidDOB: 'Please select valid date of birth',
	},
	SelectSignMessage: 'Select the method you would like to use to sign your documents',
	SelectSignTitle: 'Sign Tax Documents',
	ESignImageCaption: 'e-Sign',
	EsignCaption: 'Sign electronically from this website',
	ManuallySignImageCaption: 'Manually Sign',
	ManuallySignCaption: 'Print, Sign and Return the forms',
};

export const ErrorMessages = {
	PreparerDocumentSettingError: 'Error occured while loading DocumentSettings',
	HeaderInfoError: 'Error occurred while loading Header Information',
	TaxingAuthorityError: 'Error occured while loading Taxing Authority',
	SignedDocumentError: 'Error occurred while loading signed documents model',
	AddSignedDocument: 'Error occcured while uploading Signed document',
	DeleteSignedDocument: 'Error occured while deleting Signed document',
	CompleteManualSign: 'Error occured while completing the singed document',
	SummaryDetails: 'Error occured while loading the Summary of the document',
	EsignModel: 'Error occured while loading Esign Page',
	Signers: 'Error occured while loading Signer information',
	ClientAuthentication: 'Error occurred while authenticating user',
	ClientValidation: 'Error occurred while validating client answer',
	UpadateSpouseMail: 'Error occurred while updating spouse email',
	UpdatingMobileNumber: 'Error occurred while updating mobile number',
	UpdatingDob: 'Error occured while updating Date of Birth',
	SingatureControl: 'Error occurred while requesting signature controls',
	DocumentSignatureSetting: 'Error occurred while updating document signature settings',
	CompanyDetails: 'Error occured while loading Company Details',
	DocumentReviewModel: 'Error occured while loading Review Documents',
	RequestDocumentError: 'Error occurred while requesting Tax Document model',
	RequestClientType: 'Error occurred while fetching the Client Type',
	RequestKBAStatus: 'Error occurred while loading Knowledge Based Authentication',
	RequestNextSigner: 'Error occured while loading Next Signer Details',
	UpdateProcessInfoError: 'Error occurred while updating process information',
	GetLastVisitedStepError: 'Error occurred while requesting GetLastVisitedStep',
	GetCurrentStepAndRoleError: 'Error occurred while requesting GetCurrentStepAndRole',
	ChangeMobileNumberError: 'Unable to update the Mobile Number',
	CodeValidationError: 'Error occured while validating screen share code',
	CodeValidationFailed: 'The code you entered is not valid',
	GetTutorialInfoError: 'Error occurred while requesting Tutorial Info',
	HideSummaryTutorialError: 'Error occurred while hiding Summary Tutorial',
	HideReviewTutorialError: 'Error occurred while hiding Review Tutorial',
	NoResponseMessage: 'Unable to get response from server',
	InvalidLink: 'This link is no longer valid. Please contact the company that prepared your tax return.',
	GetSessionTimeOutSecondsError: 'Error occurred while fetching Idle session timeout seconds',
};

export const MyAccountConstants = {
	StatusMessage: {
		EditSpouseDetails: 'Spouse details updated successfully',
		EditTaxpayerDetails: 'Taxpayer details updated successfully',
		EditMobileNumber: 'Mobile number updated successfully',
		UpdateInformation: 'Account details updated successfully',
	},
	WarningMessage: {
		InvalidEmailAddress: 'Please enter valid email address',
	},
};

export const MyDownloadsConstants = {
	StatusMessage: 'Download process may take some time, the downloaded <FILENAME> will be available in "Downloads"',
	DeleteMyDownload: 'Selected File has been deleted',
	ClearAllMyDownloads: 'All Downloaded files have been deleted',
	DeleteSingleFileWarning: 'File cannot be deleted, since Download is in progress.',
	DialogBox: {
		ConfirmDeletionSingle: 'Are you sure you want to delete this file?',
		ConfirmDeletionAll: 'Are you sure you want to clear all files?',
		Confirm: 'Yes',
		Cancel: 'Cancel',
		Title: 'Confirm Deletion',
	},
};

export const ValidationContants = {
	PhoneNumberWarning: 'Please enter the phone number.',
	PhoneNumberLengthWarning: 'Please enter a 10 digit phone number.',
	CountryCodeWarning: 'Please select country code',
};

export const Common = {
	Wizard: {
		PreviousButtonLabel: 'Back ',
		NextButtonLabel: 'Continue ',
		SendLater: 'Send Later ',
		ForwardDocuments: 'Forward a copy of my tax documents',
		SubmitButtonLabel: 'Done',
	},
	DialogBox: {
		ConfirmationTitle: 'Confirmation',
		WarningTitle: 'Warning',
		Cancel: 'Cancel',
		Confrim: 'Confirm',
		Yes: 'Yes',
		No: 'No',
		ConfrimMessage: 'Are you sure you want to logout ?',
	},
	Images: {
		Download: SvgIconDownloadAll,
		UpdateDelegatee: SvgIconSendForSignature,
	},
};

export const ManualSignConstants = {
	Title: 'Manual Signatures Required',
	DownloadDocumentTitle: 'Please download documents to continue',
	DownloadDocumentButtonText: 'Download documents requiring signature',
	UploadDocumentTitle: 'After manually signing documents please select a method to send them to your tax preparer',
	UploadDocumentSecondaryTitle: 'Upload Signed Documents',
	SendByMailText: 'Send by Mail',
	SendByFaxText: 'Send by Fax',
	FileDeleteConfirmation: 'Are you sure you want to delete the file ?',
	DownloadEfileLink: '/api/Download/GetManualSignDocument/',
	UploadAreaButtonText: 'Click to Upload',
	UploadAreaText: 'Drag documents here',
	UploadAreaMinimizedText: 'Drag documents here or click here to upload',
	UploadAreaTextSeperation: 'Or',
	UploadNotAllowedMessage: 'Upload not allowed in preview mode',
	SupportedFilesMessage: 'Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.',
	ConfirmMessage:
		'All document(s) have been successfully uploaded and will be forwarded to <COMPANYNAME>. Do you want to proceed?',
};

export const K1EngagementType = {
	E1065: 'Partners',
	E1120S: 'Shareholders',
	E1041: 'Beneficiaries',
};

export const K1ShareHolderConstants = {
	DownloadAllK1Link: '/api/Download/GetAllK1DocumentAsync/',
	WarningMessage: {
		EmailAdrressWarning: 'Please enter email address.',
		InvalidEmailAddress: 'Please enter valid email Address.',
		CheckBoxSelect: 'Please select atleast one',
		EmailAdrressEmpty: 'Email address can not be blank',
		EntityTypeWarning: 'Please select entity type',
		K1DistributionWarning: 'Are you sure you would like to continue without distributing your K-1’s',
		Cancel: 'No, Return',
		Confirm: "Yes, I'll do it later",
	},
	SuccessMessage: {
		emailUpdated: 'Email address updated successfully',
		detailsUpdated: 'K1 details updated successfully',
		emailSent: 'Email sent successfully',
	},
};

export const SignProcessConstants = {
	ProcessRecoveryConfirmation: 'Sign Process Recovery Confirmation',
	ProcessRecoveryMessage: 'Would like to resume where you left off or start over ?',
	ProcessRecoveryConfirmationDialogLeftButton: 'Resume',
	ProcessRecoveryConfirmationDialogRightButton: 'Start over',
	ProcessRecoveryFailedMessage: 'some settings has been changed, starting from first',
};

export const CompletedSummaryConstant = {
	SpouseReminderAlertMessage: 'Spouse electronic signature required to file taxes',
	TaxpayerReminderAlertMessage: 'Taxpayer electronic signature required to file taxes',
	InstructionText: 'You can access and manage all of your tax documents by selecting an option below',
	SecondSignerAlertMessage: 'You have completed all required steps, we are awaiting <SIGNERNAME> signature',
	CompletedMessage: 'All required steps for your <TAXYEAR> tax returns have been completed!',
	WelcomeMessage: 'Welcome <TAXPAYERNAME>',
	SendSigningReminderSuccess: 'Signing Reminder has been successfully sent',
	SendSigningReminderFailure: 'Failed to send the Signing Reminder',
	Images: {},
	ImageCaption: {
		ViewDownloadDocument: 'View or Download Tax Documents',
		ViewDownloadSignedDocument: 'View or Download Signed Documents',
		MakeTaxPayment: 'Make Tax Payments',
		PrintFiledReturn: 'Print & Mail Paper Filed Returns',
		PayTaxPrepFee: 'Pay Tax Preparation Fees',
		DistributeK1: 'Distribute K-1s',
	},
};

export const PayConstants = {
	VoucherSettingUpdated: 'Payment Voucher Notification Settings Updated Successfully.',
	ErrorVoucherSettingUpdated: 'Error occurred while updating payment Voucher document settings',
};

export const WelcomeSummaryConstants = {
	InstructionText: 'Please follow these easy steps so your taxes can be filed.',
	WelcomeMessage:
		'Using this mobile device you will be able to review and sign your tax documents.<br /><br /> To print or forward your tax documents or make payments please login using a PC or Tablet.<br /> ',
};

export const PathConstants = {
	Logout: '/WelcomeScreen/Index/',
	CompletionWizard: '/taxpayer/signcompleted/',
	ManualSignRestricted: '/taxpayer/manualsignrestricted/',
	SummaryPage: '/taxpayer/',
	SignFlow: '/signflow/',
};

export const Permissions = {
	Read: 'Read',
};

export const KBAConstants = {
	KBARetryExceedMessageTitle: 'Too many failed attempts',
	KBARetryExceedMessage:
		'As per IRS regulations after three failures at Identity Verification you must manually sign your electronic filling authorization forms. <br/> To print, sign and upload your forms please login using a PC or Tablet. ',
	KBAFailureMessage: 'KBA validation failed. Please enter correct answers.',
	KBAIdentificationFailure:
		'You do not qualify for the IRS required Knowledge Based Authentication.  As a result, you are unable to electronically sign your documents and you must manually sign Form(s) 8879.',
};

export const SummaryTurorialConstants = {
	TaxYearModal: {
		header: 'Multiple Tax Year Available',
		body: 'You may switch between different tax years by using the drop-down list located here.',
		doNotShowInstruction: 'Do not show me this instruction again',
	},
	ContactPersonModal: {
		header: 'Contact Person',
		body: 'Please contact this person if you are experiencing issues navigating this website or completing the process.',
		doNotShowInstruction: 'Do not show me this instruction again',
	},
	Buttons: {
		next: 'Next',
		done: 'Done',
	},
};

export const ReviewTurorialConstants = {
	ReviewModal: {
		header: 'Select Documents To Review',
		body: 'Please select all the Document Groups and review before proceeding to signing.',
		doNotShowInstruction: 'Do not show me this instruction again',
	},
	Buttons: {
		next: 'Next',
		done: 'Done',
	},
};

export const CCRecipientConstants = {
	ModalDescription:
		'Please enter the email addresses that you would like to share the tax documents with. ' +
		'Documents will be sent from <span class="ssr-default-email">noreply@safesendreturns.com</span>. Recipients will be required to enter an ' +
		'Access Code which will be sent in a secondary email or with text message authentication',
	Note: 'Note: Recipients will only be able to download copies of tax documents. They will not be able to sign forms or manage payments.',
	DocumentForwardText: 'Choose the document(s) to forward:',
	CcEmptyWarning: 'Please make sure you have entered recipient email address',
	DocumentsNotSelectedWarning: 'Please make sure you have selected atleast one document to forward',
	SuccessfulyMailedDocuments: 'Email Sent Successfully',
};

export const CCRecipientDownloadEndPoints = {
	TaxReturns: 'api/CCRecipient/DownloadTaxReturnDocumentSasAsync/',
	Transmittals: 'api/CCRecipient/DownloadTransmittalDocumentSasAsync/',
	Efile: 'api/CCRecipient/DownloadEfileDocumentSasAsync/',
	Invoice: 'api/CCRecipient/DownloadInvoiceDocumentSasAsync/',
	Voucher: 'api/CCRecipient/DownloadVoucherDocumentSasAsync/',
	AdditionalEsignDocument: 'api/CCRecipient/DownloadAdditionEsignDocumentSasAsync/',
	Attachments: 'api/CCRecipient/DownloadAttachmentSasAsync/',
};

export const SignCompletedConstants = {
	CheckMark: '../../../images/icon-complete.svg',
	DownloadAllText: 'Download all my tax documents',
};

export class EsignStrings {
	public static readonly SIGNATURE_VALIDATION_ERROR_MESSAGE = 'Signature not found !!';
	public static readonly COMPANY_VALIDATION_ERROR_MESSAGE = 'Company name not found !!';
	public static readonly DATE_VALIDATION_ERROR_MESSAGE = 'Date not found !!';
	public static readonly INITIAL_VALIDATION_ERROR_MESSAGE = 'Initial not found !!';
	public static readonly TITLE_VALIDATION_ERROR_MESSAGE = 'Title not found !!';
	public static readonly CUSTOM_TEXTBOX_VALIDATION_ERROR_MESSAGE = 'Some custom field data not found !!';
	public static readonly NAME_TEXTBOX_VALIDATION_ERROR_MESSAGE = 'Print name not found !!';
	public static readonly CHECKBOX_VALIDATION_ERROR_MESSAGE = 'Checkbox not found !!';
	public static readonly RADIOBUTTON_VALIDATION_ERROR_MESSAGE = 'Radio button not found !!';
}

export const CoverPageConstants = {
	SuccessMessage: {},
	ErrorMessage: {
		clientFetchFailed: 'Unable to fetch client Data, Please try again',
		serverError: 'Internal Server Error,Please try again',
	},
};

export const OTPPageConstants = {
	SuccessMessage: {
		OTPGenerateSuccess: 'A one-time access code was sent to your email',
	},
	ErrorMessage: {
		lockedByOTP: 'Maximum retry reached for one-time access code',
		serverError: 'Internal Server Error, Please try to refresh',
		OTPGenerateFailed: 'Unable to generate one-time access code, Please try again',
		OTPExpired: 'Entered one-time access code has Expired',
		OTPError: 'Provided one-time access code is invalid',
	},
};

export const OTPMobilePageConstants = {
	SuccessMessage: {
		OTPGenerateSuccess: 'A one-time access code was sent to you via a text message',
	},
	ErrorMessage: {
		lockedByOTP: 'Maximum retry reached for one-time access code',
		serverError: 'Internal Server Error, Please try to refresh',
		OTPGenerateFailed: 'Unable to Generate one-time access code, Please try Again.',
	},
};

export const SSNPageConstants = {
	SuccessMessage: {},
	ErrorMessage: {
		lockedBySSN: 'Maximum retry reached for SSN',
		OTPLocked: 'Maximum retry reached for one-time access code',
		InvalidSSN: 'Provided SSN is invalid',
		serverError: 'Internal Server Error, Please try to again',
	},
};

export const HeaderConstants = {
	ErrorMessage: {
		RetrieveFailed: 'Unable to fetch Details',
	},
};

export const DisplayError = [
	'ERROR_DELETED_RETURN',
	'ERROR_RECALLED_RETURN',
	'ERROR_INVALID_LINK',
	'OTP_LOCKED_MESSAGE',
	'SSN_LOCKED_MESSAGE',
	'SSN_LOCKED',
	'OTP_LOCKED',
	'RETURN_DECLINED_FOR_SIGN',
];

export const DelegateeSignerConstants = {
	WarningMessage: {
		EmptyEmailAdrress: 'Email address cannot be empty.',
		InvalidEmailAddress: 'Please enter valid email Address.',
		EmptyFirstName: 'Please enter delegatee first name.',
		EmptyLastName: 'Please enter delegatee last name.',
		EmptyDescription: 'Please enter the reason for delegation',
		EmptyCancelDelegationRemarks: 'Please enter the reason for canceling the delegation',
		EmptyDeclineSignRemarks: 'Please enter the reason for declining',
		InvalidConfirmEmail: 'Please enter valid confirm email Address.',
		EmailDontMatch: "Actual email address and confirm email address don't match.",
		InvalidMobileNumber: 'Please enter a valid 10 digit phone number.',
	},
	SuccessMessage: {
		SavedDelegateeDetailsSuccess: 'Delegatee details saved successfully',
		SavedCancelDelegationSuccess: 'Delegation canceled successfully',
		UpdateDelegateeSuccess: 'Updated delegatee details successfully',
	},
	ErrorMessage: {
		ErrorSavingDelegateeDetails: 'Error occured while saving delegatee details',
	},
	Info: {
		DelegateeSignerEmail: 'Delegated the signing to <DelegateeEmail>',
		DelegatedStatus: 'Delegated',
	},
};

export const getRoutePathUrl = (pathConstant: string, clientId: string, controllerId?: string) => {
	return `${pathConstant}${controllerId ? controllerId + '/' : ''}${clientId}`;
};

export const KNOWN_EXCEPTION_MESSAGES = [
	'ResizeObserver loop limit exceeded',
	'ResizeObserver loop completed with undelivered notifications.',
	'ErrorEvent:',
	'ErrorEvent: Script error.',
];
