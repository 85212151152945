import * as React from 'react';

export interface ProgressProps {
	status: number;
}

export default class Progress extends React.Component<ProgressProps, {}> {
	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<div className='progress awesome-pdf-viewer-progress'>
				<div
					className='progress-bar bg-primary'
					role='progressbar'
					style={{ width: this.props.status + '%' }}></div>
			</div>
		);
	}
}
