import { actionTypes } from '../../../../common/constants/actionTypes';
import { container } from '../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../core/startup/inversify/types';
import {
	IDocumentReviewModel,
	initialDocumentReviewModel,
} from '../../../../model/DocumentReviewModel/IDocumentReviewModel';
import TaxpayerHelper from '../../signflow/Helper/TaxpayerHelper';
import { TelemetryLogger } from '../../../../logger/AppInsights';
import { initializeAxios } from '../../../../core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();

export const fetchDocumentReviewDetails = () => (dispatch: any) => {
	const clientid: string = TaxpayerHelper.getClientId();

	initializeAxios(clientid)
		.get('api/Review/GetDocumentReviewModel/' + clientid)
		.then(function (response: any) {
			dispatch({
				type: actionTypes.FETCH_REVIEW_DETAILS,
				data: response.data,
			});
		})
		.catch(function (error: any) {
			console.log(error);
			logger.trackError(`taxpayer mobile fetchDocumentReviewDetails failed with error ${error.message}`, {
				ClientId: clientid,
			});
		});
};

export const documentReviewDetailsReducer = (
	state: IDocumentReviewModel[] = initialDocumentReviewModel,
	action: any,
) => {
	switch (action.type) {
		case actionTypes.FETCH_REVIEW_DETAILS:
			let receivedDocumentReviewModel: IDocumentReviewModel[] = { ...initialDocumentReviewModel };
			receivedDocumentReviewModel = action.data;
			return receivedDocumentReviewModel;
	}

	return state;
};
