import { PathConstants } from '../../../../common/constants/Constants';
import { ClientType, SignatureType, DocumentStatus } from '../../../../model/Common/Enums';
import { IMarriedJointTaxReturn, ISignerModel, isMutual, ITaxReturn } from '../../../../model/TaxReturn/TaxReturn';
import { IClientProcessModel, SignProcessSteps, ISignProcessInfo } from './TaxpayerSignFlowHelper';
import { ITaxpayer, initialTaxpayer } from '../../../../model/ITaxClient';

const NO_INDEX = -1;

export default class TaxpayerHelper {
	static isMarried = (taxReturn: ITaxReturn) => {
		return isMutual(taxReturn) &&
			taxReturn != null &&
			taxReturn != undefined &&
			taxReturn.spouse != null &&
			taxReturn.spouse != undefined
			? true
			: false;
	};

	static getFirstSignerId = (taxReturn: IMarriedJointTaxReturn, signerData: ISignerModel[]) => {
		return signerData && signerData.length > 0
			? signerData[0].clientGuid
			: taxReturn.taxpayer && taxReturn.taxpayer.clientGuid
			? taxReturn.taxpayer.clientGuid
			: '';
	};

	static isFirstSignerLoggedIn = (
		taxReturn: IMarriedJointTaxReturn,
		signerData: ISignerModel[],
		loggedInUserGuid: string,
	) => {
		const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
		return loggedInUserGuid === firstSignerId;
	};

	static getSecondSignerType(taxReturn: IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) {
		const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
		if (!TaxpayerHelper.isMarried(taxReturn)) {
			return ClientType.Undefined;
		} else {
			if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
				return ClientType.Spouse;
			} else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
				return ClientType.Taxpayer;
			} else {
				return ClientType.Undefined;
			}
		}
	}

	static getSecondSignerId = (
		taxReturn: IMarriedJointTaxReturn,
		signerData: ISignerModel[],
		loggedInUserGuid: string,
	) => {
		const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
		if (!TaxpayerHelper.isMarried(taxReturn)) {
			return 0;
		} else {
			if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
				return taxReturn.spouse.id;
			} else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
				return taxReturn.taxpayer.id;
			} else {
				return 0;
			}
		}
	};

	static getSecondSignerEmail(taxReturn: IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) {
		const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
		if (!TaxpayerHelper.isMarried(taxReturn)) {
			return '';
		} else {
			if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
				return taxReturn.spouse.email;
			} else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
				return taxReturn.taxpayer.email;
			} else {
				return '';
			}
		}
	}

	static getSecondSignerMobile(
		taxReturn: IMarriedJointTaxReturn,
		signerData: ISignerModel[],
		loggedInUserGuid: string,
	) {
		const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
		if (!TaxpayerHelper.isMarried(taxReturn)) {
			return '';
		} else {
			if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
				return taxReturn.spouse.mobileNumber;
			} else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
				return taxReturn.taxpayer.mobileNumber;
			} else {
				return '';
			}
		}
	}

	static getSecondSigner(taxReturn: IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) {
		const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
		if (!TaxpayerHelper.isMarried(taxReturn)) {
			return initialTaxpayer;
		} else {
			if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
				return taxReturn.spouse;
			} else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
				return taxReturn.taxpayer;
			} else {
				return initialTaxpayer;
			}
		}
	}

	static getSecondSignerName(taxReturn: IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) {
		const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
		if (!TaxpayerHelper.isMarried(taxReturn)) {
			return '';
		} else {
			if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
				return taxReturn.spouse.name;
			} else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
				return taxReturn.taxpayer.name;
			} else {
				return '';
			}
		}
	}

	static redirectToSignCompletedPage(clientId: string) {
		window.location.href = `${PathConstants.CompletionWizard}${clientId}`;
	}

	static redirectToSignSummaryPage(clientId: string) {
		window.location.href = `${PathConstants.SummaryPage}${clientId}`;
	}

	static redirectToManualSignrestrictedPage(clientId: string) {
		window.location.href = `${PathConstants.ManualSignRestricted}${clientId}`;
	}

	static getClientId(): string {
		return (window as any).Variables.clientId;
	}

	static getSecondSignerSSN(taxReturn: IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) {
		const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);

		if (!TaxpayerHelper.isMarried(taxReturn)) {
			return '';
		} else {
			if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
				return taxReturn.spouse.ssn;
			} else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
				return taxReturn.taxpayer.ssn;
			} else {
				return '';
			}
		}
	}

	static bothSignerDeceased(taxReturn: IMarriedJointTaxReturn, signerData: ISignerModel[]) {
		if (!TaxpayerHelper.isMarried(taxReturn)) {
			return false;
		}
		if (signerData && signerData.length > 0) {
			return (
				taxReturn &&
				taxReturn.spouse &&
				taxReturn.spouse.isDeceased &&
				taxReturn &&
				taxReturn.taxpayer &&
				taxReturn.taxpayer.isDeceased
			);
		}
		return false;
	}
}
