import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from './components/Pages/Layout/Layout';
import { TaxpayerSignFlowContainer } from './components/Pages/signflow/TaxpayerSignFlowContainer';
import { TaxpayerSignCompletedFlowContainer } from './components/Pages/SignCompleted/SignCompletedContainer';
import { ManualSignRestrictedPageContainer } from './components/Pages/Sign/ManualSign/ManualSignRestrictedPageContainer';
import CoverPage from './components/Cover/CoverPageContainer';
import EmailOTP from './components/OTP/EmailOTP/OTPPageContainer';
import MobileOTP from './components/OTP/MobileOTP/OTPPageContainer';
import SSNAuth from './components/SSN/SSNPageContainer';
import { initializeAppInsights, TelemetryLogger } from './logger/AppInsights';
import ErrorContainer from './components/common/ErrorContainer';
import InvalidLink from './components/Cover/InvalidLink';
import SessionTimeoutPage from './components/Account/SessionTimeout';

export const logger = TelemetryLogger.getInstance();

export const routes = (
	<Switch>
		<RouteWrapper
			exact
			path='/Invalid'
			component={InvalidLink}
		/>
		<RouteWrapper
			exact
			path='/Error/:errorCode'
			component={ErrorContainer}
		/>

		<RouteWrapper
			exact
			path='/CoverPage/:id'
			component={CoverPage}
		/>
		<RouteWrapper
			exact
			path='/Authentication/OTP/:id'
			component={EmailOTP}
		/>
		<RouteWrapper
			exact
			path='/Authentication/MobileOTP/:id'
			component={MobileOTP}
		/>
		<RouteWrapper
			exact
			path='/Authentication/SSN/:id'
			component={SSNAuth}
		/>

		<RouteWrapper
			exact
			path='/taxpayer/:id'
			component={TaxpayerSignFlowContainer}
			layout={Layout}
		/>
		<RouteWrapper
			exact
			path='/taxpayer/signcompleted/:id'
			component={TaxpayerSignCompletedFlowContainer}
			layout={Layout}
		/>
		<RouteWrapper
			exact
			path='/taxpayer/manualsignrestricted/:id'
			component={ManualSignRestrictedPageContainer}
			layout={Layout}
		/>
		<RouteWrapper
			exact
			path='/session-timeout'
			component={SessionTimeoutPage}
			layout={Layout}
		/>
	</Switch>
);

function RouteWrapper({ component: Component, layout: Layout, ...rest }: any) {
	initializeAppInsights(rest.computedMatch.params.id);
	return (
		<Route
			{...rest}
			render={(props) =>
				Layout ? (
					<Layout {...props}>
						<Component {...props} />
					</Layout>
				) : (
					<Component {...props} />
				)
			}
		/>
	);
}
