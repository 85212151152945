import * as React from 'react';
import { ErrorState } from '../../reducers/ErrorStore';

interface ErrorPageState {}

interface ErrorPageProps {
	ErrorMessage: ErrorState;
}

export class ErrorPage extends React.Component<ErrorPageProps, ErrorPageState> {
	constructor(props: any, states: ErrorPageState) {
		super(props, states);
	}

	public render() {
		let message: string = this.props.ErrorMessage.message;
		let errorCode: string = this.props.match.params.errorCode;
		message = message && message.length > 0 ? message : localStorage.getItem(errorCode);
		return (
			<>
				<div className='ErrorContainer'>
					<div className='Errorheader'></div>
					<div className='ErrorPageContent'>
						<div className='MessageContainer'>
							<p className='ErrorMsgPara'>{message}</p>
						</div>
					</div>
				</div>
			</>
		);
	}
}
