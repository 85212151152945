import { connect } from 'react-redux';
import { ApplicationState } from '../../../../reducers';
import { fetchHeaderInfo, updateLastVisitedStep } from '../../signflow/Action/TaxpayerSignFlowActions';
import { ManualSignRestrictedPage } from './ManualSignRestrictedPage';
import { fetchSummaryDetails } from '../../TaxSummary/Action/TaxSummaryActions';
import { SignProcessSteps } from '../../signflow/Helper/TaxpayerSignFlowHelper';

const mapStateToProps = (state: ApplicationState) => ({
	headerInfo: state.headerInfo,
	summaryModel: state.summaryModel,
});

const mapDispatchToProps = (dispatch: any) => ({
	fetchHeaderInfo: () => dispatch(fetchHeaderInfo()),
	fetchSummaryDetails: () => dispatch(fetchSummaryDetails()),
	updateLastVisitedStep: (step: SignProcessSteps, successCallback?: () => void) =>
		dispatch(updateLastVisitedStep(step, successCallback)),
});

export const ManualSignRestrictedPageContainer = connect(mapStateToProps, mapDispatchToProps)(ManualSignRestrictedPage);
