import { actionTypes } from '../../../../common/constants/actionTypes';
import { container } from '../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../core/startup/inversify/types';
import { IDocumentSettings } from '../../../../model/DocumentSettings';
import TaxpayerHelper from '../../signflow/Helper/TaxpayerHelper';
import { TelemetryLogger } from '../../../../logger/AppInsights';
import { initializeAxios } from '../../../../core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();

export const fetchPreparerMessage = () => (dispatch: any) => {
	const clientid: string = TaxpayerHelper.getClientId();

	initializeAxios(clientid)
		.get('api/PreparerMessage/GetDocumentSettings/' + clientid)
		.then(function (response: any) {
			dispatch({
				type: actionTypes.FETCH_PREPARER_MESSAGE,
				data: response.data,
			});
		})
		.catch(function (error: any) {
			console.log(error);
			logger.trackError(`taxpayer mobile fetchPreparerMessage failed with error ${error.message}`, {
				ClientId: clientid,
			});
		});
};

const initialDocumentSettings = {
	documentId: 0,
	deliverySettings: {
		preparerMessage: {
			id: 0,
			name: '',
			body: '',
			isAllowToEdit: false,
		},
	},
	documentDisplaySetting: {},
	documentSignatureSetting: {},
	documentNotificationSetting: {},
	documentRetentionSetting: {},
} as IDocumentSettings;

export const preparerMessageReducer = (state: IDocumentSettings = initialDocumentSettings, action: any) => {
	switch (action.type) {
		case actionTypes.FETCH_PREPARER_MESSAGE:
			let receivedDocumentSettings: IDocumentSettings = { ...initialDocumentSettings };
			receivedDocumentSettings.documentId = action.data.documentId;
			receivedDocumentSettings.deliverySettings = action.data.deliverySettings;
			receivedDocumentSettings.documentDisplaySetting = action.data.documentDisplaySetting;
			receivedDocumentSettings.documentSignatureSetting = action.data.documentSignatureSetting;
			receivedDocumentSettings.documentNotificationSetting = action.data.documentNotificationSetting;
			receivedDocumentSettings.documentRetentionSetting = action.data.documentRetentionSetting;
			return receivedDocumentSettings;
	}

	return state;
};
