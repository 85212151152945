import * as React from 'react';
import { ManualSignRestricted } from './Parts/ManualSignRestricted';
import { match } from 'react-router';
import { ISummaryModel } from '../../../../model/SummaryModel/SummaryModel';
import { IHeaderInfoModel } from '../../../../model/IHeaderInfoModel';
import { SignProcessSteps } from '../../signflow/Helper/TaxpayerSignFlowHelper';
import { TelemetryLogger } from '../../../../logger/AppInsights';

const logger = TelemetryLogger.getInstance();

export interface ManualSignRestrictedPageProps {
	fetchHeaderInfo(): void;
	fetchSummaryDetails(): void;
	updateLastVisitedStep(step: SignProcessSteps, successCallback?: () => void): void;
	summaryModel: ISummaryModel;
	headerInfo: IHeaderInfoModel;
	match: match;
}

export class ManualSignRestrictedPage extends React.Component<ManualSignRestrictedPageProps, {}> {
	constructor(props: ManualSignRestrictedPageProps) {
		super(props);

		let param: any = this.props.match.params;
		(window as any).Variables.clientId = param.id;
	}

	componentDidMount() {
		const { fetchHeaderInfo, fetchSummaryDetails, updateLastVisitedStep } = this.props;
		fetchHeaderInfo();
		fetchSummaryDetails();
		updateLastVisitedStep(SignProcessSteps.ManualSign);
		logger.trackPageView('ManualSignRestricted Page View');
	}

	public render() {
		const {} = this.props;

		return (
			<React.Fragment>
				<ManualSignRestricted
					clientName={this.props.summaryModel.clientName}
					companyName={this.props.headerInfo.companyName}
				/>
			</React.Fragment>
		);
	}
}
