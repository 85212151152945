import { connect } from 'react-redux';
import OTPRequest from './OTPPage';
import * as OTPPageReducer from '../OTPActions';
import * as HeaderStore from '../../HeaderActions';
import * as CoverPageStore from '../../Cover/clientCoverActions';

export default connect(
	(state: any) => ({
		headerInfoState: state.headerState,
		OTPPageState: state.OTPState,
	}),
	{
		...HeaderStore.actionCreators,
		...OTPPageReducer.actionCreators,
		...CoverPageStore.actionCreators,
	},
)(OTPRequest as any);
