import * as React from 'react';

interface OverlayLoaderProps {
	text?: string;
}

const LOADER_DEAFULT_TEXT: string = '';
export const OverlayLoader: React.FunctionComponent<OverlayLoaderProps> = ({ text }) => {
	return (
		<div
			id='overlay-loader'
			className={'d-none'}>
			<div className='spinner'></div>
			<p>{text ? text : LOADER_DEAFULT_TEXT}</p>
		</div>
	);
};
