import * as React from 'react';
import { ClientType } from '../../../model/Common/Enums';
import PhoneNumberComponent from '../../common/PhoneNumberComponent/PhoneNumberComponent';
import { EditableDropdown } from '../../common/Select/EditableDropdown';
import { CoutryCodes } from '../../../common/constants/CoutryCodes';
import { ITaxClient, ITaxpayer } from '../../../model/ITaxClient';
import { Button, Modal } from 'react-bootstrap';

export interface MyAccountProps {
	showState: boolean;
	onHide: () => void;
	onChangeNumberClick: () => void;
	onSaveButtonClick: (email: string, number: string, countryCode: string) => void;
	taxDocument: any;
	partnerShip?: ITaxClient;
	taxPayer?: ITaxpayer;
	spouse?: ITaxpayer;
}

interface MyAccountState {
	EmailAddress: string | undefined;
	phoneNumber: string | undefined;
	countryCode: string | undefined;
}

export class MyAccount extends React.Component<MyAccountProps, MyAccountState> {
	constructor(props: any) {
		super(props);
		this.state = this.initalzeState();
	}

	private initalzeState(): MyAccountState {
		return {
			EmailAddress: this.props.spouse?.email,
			phoneNumber: this.props.spouse?.mobileNumber,
			countryCode: this.props.spouse?.countryCode,
		};
	}

	private onSpouseMailChange = (e: any) => {
		this.setState({ EmailAddress: e.target.value });
	};

	private onChangePhoneNumber = (value: string) => {
		this.setState({
			phoneNumber: value,
		});
	};

	private onChangeCountryCodeForSpouse = (value: string) => {
		this.setState({
			countryCode: value,
		});
	};

	onHide = () => {
		this.setState(this.initalzeState(), () => this.props.onHide());
	};

	phoneNumbermasking() {
		let number = this.props.partnerShip ? this.props.partnerShip.mobileNumber : this.props.taxPayer?.mobileNumber;
		let n1, n2, n3, maskedNumber;
		if (number && number.length == 10) {
			n1 = number.substring(0, 3);
			n2 = number.substring(3, 6);
			n3 = number.substring(6, 10);
			maskedNumber = '(' + n1 + ')' + ' ' + +n2 + '-' + n3;
		} else {
			maskedNumber = number;
		}
		return maskedNumber;
	}

	componentDidUpdate(prevProps: MyAccountProps) {
		if (
			prevProps.taxPayer !== this.props.taxPayer ||
			prevProps.spouse !== this.props.spouse ||
			prevProps.partnerShip !== this.props.partnerShip
		) {
			this.setState(this.initalzeState());
		}
	}

	public render() {
		const maskedPhoneNumber: string | undefined = this.phoneNumbermasking();
		const mobileNumberString: string =
			maskedPhoneNumber && maskedPhoneNumber.length > 5 ? 'Change Number' : ' Enter Mobile Number';
		return (
			<div>
				<Modal
					show={this.props.showState}
					className='myaccount'
					onHide={this.onHide}
					data-test-auto='689A5D12-FAFD-44B8-8C11-373C29A7C1BF'>
					<Modal.Header closeButton>
						<Modal.Title style={{ fontSize: '16px' }}>
							<span
								className='fa fa-user ddl-icon'
								style={{ padding: '6px' }}></span>
							My Account
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='row'>
							<div
								className='col-lg-12'
								style={{ display: 'inline' }}>
								<div>
									<span className='myaccount modal-title'>Mobile Number</span>
								</div>
								<div className='myaccount-text'>
									<span style={{ fontSize: '14px' }}>
										An access code will be sent to the following mobile device number every time you login
									</span>
								</div>

								<div
									className='myaccount-text'
									style={{ display: 'inline-flex' }}
									data-test-auto='FB4FBF72-5EA4-45F9-A6EA-F3608FDEE04B'>
									<span style={{ paddingRight: '10px' }}>
										<b>Mobile Device Number: </b>
									</span>
									{maskedPhoneNumber && maskedPhoneNumber.length > 5 && (
										<span className='myaccount-countrycode'>
											{' '}
											{this.props.partnerShip
												? this.props.partnerShip.countryCode
												: this.props.taxPayer?.countryCode}{' '}
										</span>
									)}
									{maskedPhoneNumber && maskedPhoneNumber.length > 5 && (
										<span className='myaccount-changenumber'>{maskedPhoneNumber}</span>
									)}

									<a
										href='javascript:void(0);'
										className='tab'
										style={{ color: '#00cc00' }}
										data-test-auto='D0A438D7-E156-4621-840A-29B0A0D22745'
										onClick={() => {
											this.props.onChangeNumberClick();
										}}>
										{' '}
										{mobileNumberString}
									</a>
								</div>

								<br />
								{this.props.spouse && (
									<div>
										<div data-test-auto='8F714F79-9673-44C4-9DAB-084BEC4566AC'>
											<span className='myaccount modal-title'>
												{this.props.taxDocument.clientType == ClientType.Taxpayer ? 'Spouse Info' : 'Taxpayer Info'}
											</span>
										</div>

										<div
											className='row myaccount-text'
											data-test-auto='B305427D-D94A-47D6-AE22-5B71ACA21532'>
											<div className='col-sm-12 col-md-12 col-xl-12'>
												<span style={{ paddingRight: '5px' }}>
													<b>
														{this.props.taxDocument.clientType == ClientType.Taxpayer
															? 'Spouse Name:'
															: 'Taxpayer Name:'}
													</b>
												</span>
												<span style={{ fontSize: '12px' }}>{this.props.spouse.name}</span>
											</div>
										</div>

										<div className='second-signerinfo'>
											<div className='emailSection'>
												<div
													className='myaccount-text'
													style={{ paddingBottom: '0px' }}
													data-test-auto='DA080EA9-D508-423E-9E48-99F15673059C'>
													<span>
														<b>
															{this.props.taxDocument.clientType == ClientType.Taxpayer
																? 'Spouse Email Address'
																: 'Taxpayer Email Address'}
														</b>
													</span>
												</div>
												<div className='myaccount-text'>
													<input
														type='text'
														className='form-control custom-input'
														data-test-auto='CDAB9814-31A5-4543-8B0B-DE8606825993'
														value={this.state.EmailAddress}
														onChange={(e) => this.onSpouseMailChange(e)}
														style={{ fontSize: '15px', height: '38px' }}
													/>
												</div>
											</div>
											<div className='mobileSection'>
												<div
													className='myaccount-text'
													style={{ paddingBottom: '0px' }}
													data-test-auto='DA080EA9-D508-423E-9E48-99F15673059C'>
													<span>
														<b>
															{this.props.taxDocument.clientType == ClientType.Taxpayer
																? 'Spouse Mobile Number'
																: 'Taxpayer Mobile Number'}
														</b>
													</span>
												</div>

												<div className='myaccount-text phonenumber'>
													<EditableDropdown
														id='ddlCountryCode'
														options={CoutryCodes()}
														onChange={this.onChangeCountryCodeForSpouse}
														selectedValue={this.state.countryCode}
														clearable={true}
														data-test-auto='271C2A00-2C22-4E8A-9EEF-966E58AAE121'
													/>

													<PhoneNumberComponent
														phoneNumber={this.state.phoneNumber ?? ''}
														handleChangePhoneNumber={this.onChangePhoneNumber}
														data-test-auto='8243250E-2983-4267-B864-8EFA449BDAAB'
														className='my-account-phonenumber'></PhoneNumberComponent>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</Modal.Body>
					{this.props.spouse ? (
						<Modal.Footer>
							<Button
								id='btnHideMyaccountPopUp'
								onClick={this.onHide}
								data-test-auto='CEC8781D-ED44-4B24-AF4A-8005A00B59FC'
								className='btn btn-white btn-default rounded bootbox-cancel'
								variant={'light'}>
								<i className='fa fa-times'></i> Cancel
							</Button>
							<Button
								onClick={() => {
									this.props.onSaveButtonClick(
										this.state.EmailAddress ?? '',
										this.state.phoneNumber ?? '',
										this.state.countryCode ?? '',
									);
								}}
								data-test-auto='56A7B8B9-02D6-498E-8953-F7F56C4478AE'
								className='btn btn-primary rounded bootbox-accept'
								variant={'primary'}>
								<i className='fa fa-save'></i> Save
							</Button>
						</Modal.Footer>
					) : (
						<Modal.Footer>
							<Button
								id='btnHideMyaccountPopUp'
								onClick={this.onHide}
								data-test-auto='B0C636D5-9829-42E6-97C6-A721AC76F635'
								variant={'primary'}
								className='btn btn-primary rounded bootbox-accept'>
								<span>Done</span>
							</Button>
						</Modal.Footer>
					)}
				</Modal>
			</div>
		);
	}
}
export default MyAccount;
