import { TelemetryLogger } from '../../../../logger/AppInsights';
import { IDataService } from '../../../../core/services/dataAccess/abstraction/IDataService';
import { container } from '../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../core/startup/inversify/types';
import TaxpayerHelper from '../../signflow/Helper/TaxpayerHelper';
import { IDelegateeSignerDetails } from '../../../../model/IDelegateeSignerModel';
import { ILoader } from '../../../../core/utilities/ui/Loader';
import { actionTypes } from '../../../../common/constants/actionTypes';
import { initializeAxios } from '../../../../core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();

const loader = container.get<ILoader>(TYPES.ILoader);

export const assignToDelegatee = (delegatee: IDelegateeSignerDetails, callback: () => void) => (dispatch: any) => {
	loader.show();
	const clientid: string = TaxpayerHelper.getClientId();

	initializeAxios()
		.postJson(delegatee, 'api/DelegateeSigner/AssignToDelegatee/' + clientid)
		.then(function (response: any) {
			callback && callback();
			loader.hide();
		})
		.catch(function (error: any) {
			loader.hide();
			console.log(error);
			logger.trackError(
				`Taxpayer Mobile assignToDelegatee failed to with error ${error.message} for client: ${clientid}`,
				{ ClientId: clientid },
			);
		});
};

export const requestTaxClientDelegateeDetails = (callback?: () => void) => (dispatch: any) => {
	loader.show();
	const clientId: string = TaxpayerHelper.getClientId();

	initializeAxios(clientId)
		.get('api/DelegateeSigner/GetDelegateeSignerDetailsForTaxClient/' + clientId)
		.then(function (response: any) {
			if (response.data) {
				dispatch({
					type: actionTypes.FETCH_DELEGATEE_DETAILS,
					data: response.data,
				});
			} else {
				callback && callback();
			}
			loader.hide();
		})
		.catch(function (error: any) {
			loader.hide();
			console.log(error);
			logger.trackError(
				`Taxpayer Mobile requestDelegateeDetails failed to with error ${error.message} for client: ${clientId}`,
				{ ClientId: clientId },
			);
		});
};

export const updateDelegateeSignerDetails =
	(delegatee: IDelegateeSignerDetails, callback: () => void) => (dispatch: any) => {
		loader.show();
		const clientid: string = TaxpayerHelper.getClientId();

		initializeAxios()
			.postJson(delegatee, 'api/DelegateeSigner/UpdateDelegatee/' + clientid)
			.then(function (response: any) {
				if (response.data) {
					callback();
				}
				loader.hide();
			})
			.catch(function (error: any) {
				loader.hide();
				logger.trackError(
					`Taxpayer Mobile updateDelegatee failed to with error ${error.message} for client: ${clientid}`,
					{ ClientId: clientid },
				);
			});
	};

export const cancelDelegation = (remarks: string, taxYear: number, callback: () => void) => (dispatch: any) => {
	loader.show();
	const clientid: string = TaxpayerHelper.getClientId();

	initializeAxios(clientid)
		.post('api/DelegateeSigner/CancelDelegation/' + clientid + '?remarks=' + remarks + '&taxYear=' + taxYear)
		.then(function (response: any) {
			if (response.data) {
				callback();
			}
			loader.hide();
		})
		.catch(function (error: any) {
			loader.hide();
			logger.trackError(
				`Taxpayer Mobile cancelDelegation failed to with error ${error.message} for client: ${clientid}`,
				{ ClientId: clientid },
			);
		});
};

export const resetLastVisitedSteps = (callback: () => void) => (dispatch: any) => {
	loader.show();
	const clientId: string = TaxpayerHelper.getClientId();

	initializeAxios(clientId)
		.get('api/SignProcess/DeleteLastVisitedSteps/' + clientId)
		.then(function (response: any) {
			callback();
			loader.hide();
		})
		.catch(function (error: any) {
			loader.hide();
			logger.trackError(
				`Taxpayer Mobile resetLastVisitedSteps failed with error ${error.message} for client: ${clientId}`,
				{ ClientId: clientId },
			);
		});
};
