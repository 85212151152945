import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { ClientType } from '../../model/Common/Enums';
import { ClientTypes, ITaxClient, ITaxpayer } from '../../model/ITaxClient';
import { ITaxReturn, isIndividual, isMutual, isPartnership } from '../../model/TaxReturn/TaxReturn';

export const injectPendoScript = (
	companyId: number,
	companyName: string,
	userId: number,
	name: string,
	email: string,
	isPendoInjected: boolean,
	setIsPendoInjected: (isPendoInjected: boolean) => void,
) => {
	if (!companyId || !companyName || !userId || !name || !email || isPendoInjected) {
		return;
	}

	setIsPendoInjected(true);
	const apiKey = process.env.REACT_APP_CLIENT_PENDO_API_KEY;
	const scriptId = 'taxpayer-pendo-script';

	const existingScript = document.getElementById(scriptId);
	if (existingScript) {
		existingScript.remove();
	}

	(function (p: any, e: any, n: any, d: any, o: any) {
		var v: any, w: any, x: any, y: any, z: any;
		o = p[d] = p[d] || {};
		o._q = o._q || [];
		v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
		for (w = 0, x = v.length; w < x; ++w)
			(function (m) {
				o[m] =
					o[m] ||
					function () {
						o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
					};
			})(v[w]);
		y = e.createElement(n);
		y.async = !0;
		y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
		y.id = scriptId;
		z = e.getElementsByTagName(n)[0];
		z.parentNode.insertBefore(y, z);
	})(window, document, 'script', 'pendo', '');

	window.pendo?.initialize({
		visitor: {
			id: `${companyId}-${userId}-${email}`,
			email: email,
			name: name,
		},
		account: {
			id: companyId,
			accountName: companyName,
			payingStatus: 'trial',
		},
	});
};

export const getCompanyInfo = (clientId: string, callback: (data: any) => void) => {
	initializeAxios()
		.get('api/ApplicationInsight/BasicDetails/' + clientId)
		.then((response: any) => {
			callback(response.data);
		})
		.catch((error: any) => console.log(error));
};

export const getLoggedInUserInfo = (taxDocument: ITaxReturn): ITaxpayer | ITaxClient | undefined => {
	if (taxDocument && isIndividual(taxDocument)) {
		return taxDocument.taxpayer;
	} else if (taxDocument && isMutual(taxDocument)) {
		if (
			taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer &&
			taxDocument.clientType == ClientType.Taxpayer
		) {
			return taxDocument.taxpayer;
		} else if (
			taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer &&
			taxDocument.clientType == ClientType.Spouse
		) {
			return taxDocument.spouse;
		} else if (
			taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse &&
			taxDocument.clientType == ClientType.Spouse
		) {
			return taxDocument.spouse;
		} else if (
			taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse &&
			taxDocument.clientType == ClientType.Taxpayer
		) {
			return taxDocument.taxpayer;
		}
	} else if (taxDocument && isPartnership(taxDocument)) {
		return taxDocument.partnership;
	}
	return undefined;
};
