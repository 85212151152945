import * as React from 'react';
import { OverlayLoader } from './common/Loader/OverlayLoader';

declare global {
	interface Window {
		Variables: any;
	}
}

interface HeaderProps {
	companyName: string;
	companyLogo: string;
	headerBackColor: string;
	headerTextColor: string;
}

const HeaderComp: React.FunctionComponent<HeaderProps> = (props) => {
	return (
		<>
			<div
				className='row header-container'
				style={{ backgroundColor: props.headerBackColor }}>
				<div
					className={'header-nav'}
					style={{ width: '100%', justifyContent: 'center' }}>
					{props.companyLogo ? (
						<img
							className='company-logo'
							src={props.companyLogo}
						/>
					) : (
						<h2
							className={'title'}
							style={{ color: props.headerTextColor, fontSize: '15px', fontWeight: 'normal' }}>
							{props.companyName}
						</h2>
					)}
				</div>
			</div>
		</>
	);
};

export default HeaderComp;
