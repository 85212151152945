import * as React from 'react';
import { container } from '../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../core/startup/inversify/types';
import { IUtilities } from '../../../../core/utilities/Utilities';
import { ITaxingAuthority } from '../../../../model/Common/TaxingAuthority';
import { IRefund, IVoucher, VoucherTypes } from '../../../../model/IGroup';
import {
	getOverPaymentApplied,
	getPaymentDue,
	getRefunds,
	IRefundInfo,
} from '../../../../model/SummaryModel/SummaryModel';
import { Shimmer } from '../../../common/Shimmer/Shimmer';

interface PaymentAndRefundProps {
	taxYear: number;
	vouchers: IVoucher[];
	refunds: IRefund[];
	addedRefunds: IRefundInfo[];
	taxingAuthorities: ITaxingAuthority[];
}

const utilities = container.get<IUtilities>(TYPES.IUtilities);

export const PaymentAndRefund: React.FunctionComponent<PaymentAndRefundProps> = (props) => {
	const paymentDue = getPaymentDue(props.vouchers, props.taxingAuthorities);
	const refunds = getRefunds(props.refunds, props.addedRefunds, props.taxingAuthorities);
	const overPaymentApplied = getOverPaymentApplied(props.refunds, props.addedRefunds, props.taxingAuthorities);

	const estimatedVoucher = props.vouchers.filter((x) => x.paymentType === VoucherTypes.EstimatedVoucher);
	const heightStyle = estimatedVoucher && estimatedVoucher.length > 0 ? {} : { height: '98%' };

	return (
		<div
			className='payment-refund-container'
			style={heightStyle}>
			<div
				className='body'
				style={{ overflowY: 'auto' }}
				data-test-auto='EC77E277-245B-4CFB-8316-C41BEDC38708'>
				{refunds.Items && refunds.Items.length > 0 && (
					<section
						className='preSelect'
						key={refunds.GroupName}>
						<div
							className='section-title'
							data-test-auto='3721C0B0-177B-45E5-87F2-EEA08D0B63BC'>
							{refunds.GroupName}
						</div>
						{refunds.Items.map((item, i) => {
							return (
								<div
									key={refunds.GroupName + item.name + i}
									className='sub-section'>
									<div className='authority fllft ellipsis'>{item.name}</div>
									<div className='amount flrt green currencytext'>${utilities.formatCurrencyText(item.value)}</div>
								</div>
							);
						})}
						{
							<div
								className='sub-section'
								data-test-auto='196ECDFA-277D-4127-B234-1B6BEEE72BD9'>
								<div className='green fllft ellipsis'>
									<span className='flrt'>Total</span>
								</div>
								<div className='flrt currencytext green total-amount-green'>
									${utilities.formatCurrencyText(refunds.Total)}
								</div>
							</div>
						}
					</section>
				)}
				{overPaymentApplied.Items && overPaymentApplied.Items.length > 0 && (
					<section
						className='preSelect'
						key={overPaymentApplied.GroupName}>
						<div
							className='section-title'
							data-test-auto='B527F1A4-C6DC-46FC-A3FA-7538335DAC9A'>
							{overPaymentApplied.GroupName}
						</div>
						{overPaymentApplied.Items.map((item, i) => {
							return (
								<div
									key={overPaymentApplied.GroupName + item.name + i}
									className='sub-section'>
									<div className='authority fllft ellipsis'>{item.name}</div>
									<div className='amount flrt green currencytext'>${utilities.formatCurrencyText(item.value)}</div>
								</div>
							);
						})}
						{
							<div
								className='sub-section'
								data-test-auto='408C067A-6212-4D6D-BEC2-7E04F7F3064A'>
								<div className='green fllft ellipsis'>
									<span className='flrt'>Total</span>
								</div>
								<div className='flrt currencytext green total-amount-green'>
									${utilities.formatCurrencyText(overPaymentApplied.Total)}
								</div>
							</div>
						}
					</section>
				)}
				{paymentDue.Items && paymentDue.Items.length > 0 && (
					<section
						className='preSelect'
						key={paymentDue.GroupName}>
						<div
							className='section-title'
							data-test-auto='DFC7A2FB-CE86-4006-94E3-9CDB00D8E1CF'>
							{paymentDue.GroupName}
						</div>
						{paymentDue.Items.map((item, i) => {
							return (
								<div
									key={paymentDue.GroupName + item.name + i}
									className='sub-section'>
									<div className='authority fllft ellipsis'>{item.name}</div>
									<div className='amount flrt red currencytext'>${utilities.formatCurrencyText(item.value)}</div>
								</div>
							);
						})}
						{
							<div
								className='sub-section'
								data-test-auto='E651FB56-E074-44F5-87CB-2EA5003E4EEF'>
								<div className='red fllft ellipsis'>
									<span className='flrt'>Total</span>
								</div>
								<div className='flrt currencytext red total-amount-red'>
									${utilities.formatPaymentDueCurreny(paymentDue.Total)}
								</div>
							</div>
						}
					</section>
				)}
				{props.taxYear > 0 &&
					refunds.Items &&
					refunds.Items.length === 0 &&
					overPaymentApplied.Items &&
					overPaymentApplied.Items.length === 0 &&
					paymentDue.Items &&
					paymentDue.Items.length === 0 && <div>No Content</div>}
			</div>
		</div>
	);
};
